import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/bg-2.png'
import bgone from '../../assets/images/background/bg-1.png'
import five from '../../assets/images/resource/cocktail.png'
import Swipe from '../../swipte';

const CLASSICS = [
    {
        name: 'Sex on the beach',
        description: 'Vodka, Peach liquor, cranberry, orange',
        base: 'Vodka',
        blog: 'sex-on-the-beach-cocktail'
    },
    {
        name: 'Cosmopolitan',
        description: 'Vodka, Cranberry juice, Cointreau, Lime juice',
        base: 'Vodka',
        blog: 'mojito-coctail'
    },
    {
        name: 'Espresso Martini',
        description: 'Vodka, Cafe liquor, Epresso',
        base: 'Vodka',
        blog: 'espresso-martini-cocktail'
    },
    {
        name: 'Culture',
        description: 'Vodka, Yogurt, Passionfruit Puree, Vanilla',
        base: 'Vodka'
    },
    {
        name: 'Moscow mule',
        description: 'Vodka, Lime juice, Ginger beer',
        base: 'Vodka'
    },
    {
        name: 'Wasabi martini',
        description: 'Vodka, Wasabi, Lime, Sugar',
        base: 'Vodka'
    },
    {
        name: 'Sea breeze',
        description: 'Vodka, Cranberry juice, Grapefruit juice',
        base: 'Vodka'
    },
    {
        name: 'Vodkatini',
        description: 'Vodka, Dry vermouth',
        base: 'Vodka'
    },
    {
        name: 'Vesper',
        description: 'Vodka, Gin, Bianco Vermouth',
        base: 'Vodka'
    },
    {
        name: 'Black / White russian',
        description: 'Vodka, Coffee liqueur, Heavy cream or Milk (optional)',
        base: 'Vodka'
    },
    {
        name: 'Leapfrog	',
        description: 'Gin, Lemon Juice, Apricot liquor, Mint, Sugar, Orange bitter',
        base: 'Gin',
        selected: true,
        blog: 'leapfrog-cocktail'
    },
    {
        name: 'Bee’s knees',
        description: 'Gin, Lemon juice, Honey syrup',
        base: 'Gin'
    },
    {
        name: 'Corpse reviver #2',
        description: 'Gin, Lemon juice, Cointreau, Lillet Blanc, Absinthe',
        base: 'Gin'
    },
    {
        name: 'Bramble',
        description: 'Gin, Blackberry liqueur, Simple syrup, Lemon juice',
        base: 'Gin'
    },
    {
        name: 'Southside',
        description: 'Gin, Lime juice, Simple syrup, Mint leaves, Angostura bitter',
        base: 'Gin'
    },
    {
        name: 'Clover club	',
        description: 'Gin, Raspberry syrup, Lemon juice, White foam, Dry vermouth (optional)',
        base: 'Gin'
    },
    {
        name: 'Gimlet',
        description: 'Gin, Lime juice, Simple syrup',
        base: 'Gin'
    },
    {
        name: 'Vieux mot',
        description: 'Gin, Elderflower liquor, Lime, Sugar',
        base: 'Gin'
    },
    {
        name: 'Tuxedo',
        description: 'Gin, Dry vermouth, Maraschino liqueur, Orange bitters, Absinthe rinse',
        base: 'Gin'
    },
    {
        name: 'Martinez	',
        description: 'Gin, Sweet vermouth, Maraschino liqueur, Orange bitters',
        base: 'Gin'
    },
    {
        name: 'Gin Martini',
        description: 'Gin, Dry vermouth',
        base: 'Gin'
    },
    {
        name: 'Negroni',
        description: 'Gin, Campari, Sweet vermouth',
        base: 'Gin',
        selected: true,
        blog: 'negroni-cocktail'
    },
    {
        name: 'Ramos gin fizz',
        description: 'Gin, Lemon juice, Lime juice, Simple syrup, Cream, White foam, Orange flower water, Vanilla',
        base: 'Gin',
        blog: 'ramos-gin-fizz-cocktail'
    },
    {
        name: 'Water Lily',
        description: 'Gin, Creme de Violette, Cointreau, Lemon juice',
        base: 'Gin',
        selected: true,
        blog: 'water-lily-cocktail'
    },
    {
        name: 'Mojito',
        description: 'White rum, Lime juice, Simple syrup, Mint leaves, Club soda',
        base: 'Rum',
        blog: 'mojito-coctail'
    },
    {
        name: 'Caipirinha',
        description: 'Cachaça, Lime, Sugar',
        base: 'Rum'
    },
    {
        name: 'Daiquiri',
        description: 'White rum, Lime juice, Simple syrup',
        base: 'Rum',
        selected: true,
        blog: 'daiquiri-cocktail'
    },
    {
        name: 'Dark and stormy',
        description: 'Dark rum, Ginger beer, Lime',
        base: 'Rum'
    },
    {
        name: 'Hemingway daiquiri	',
        description: 'White rum, Grapefruit juice, Maraschino liqueur, Lime juice',
        base: 'Rum'
    },
    {
        name: 'Maitai',
        description: 'Dark rum, White rum, Cointreau, Orgeat syrup, Lime juice',
        base: 'Rum'
    },
    {
        name: 'Pina colada',
        description: 'White rum, Pineapple juice, Coconut cream',
        base: 'Rum',
        blog: 'pina-colada-cocktail'
    },
    {
        name: 'Zombie',
        description: 'Dark rum, Light rum, Lime juice, Pineapple juice, Pink Grapefruit, Absinthe',
        base: 'Rum'
    },
    {
        name: 'Paloma',
        description: 'Tequila, Grapefruit soda, Lime juice, Raspberry, Salt (optional)',
        base: 'Tequila'
    },
    {
        name: 'Tequila sour',
        description: 'Tequila, Lemon juice, Simple syrup, White foam (optional)',
        base: 'Tequila'
    },
    {
        name: 'Bermondsey Minute',
        description: 'Tequila, Sweet vermouth, Luxardo Maraschino, Lavender bitter',
        base: 'Tequila'
    },
    {
        name: 'El Diablo',
        description: 'Tequila, Ginger beer, Lime juice, Crème de cassis',
        base: 'Tequila'
    },
    {
        name: 'Last chance',
        description: 'Tequila, Apricot brandy, Honey, Lime juice',
        base: 'Tequila',
        selected: true,
        blog: 'last-chance-cocktail'
    },

    {
        name: 'Margarita',
        description: 'Tequila, Lime juice, Cointreau',
        base: 'Tequila'
    },
    {
        name: 'Siesta',
        description: 'Tequila, Campari, Grapefruit juice, Lime juice, Simple syrup',
        base: 'Tequila'
    },
    {
        name: 'Whiskey smash',
        description: 'Bourbon, Lemon juice, Simple syrup, Mint leaves',
        base: 'Whiskey'
    },

    {
        name: 'Boulevardier',
        description: ' Bourbon, Sweet vermouth, Campari',
        base: 'Whiskey'
    },
    {
        name: 'God father',
        description: 'Scotch, Amaretto',
        base: 'Whiskey'
    },
    {
        name: 'Mint julep',
        description: 'Bourbon, Simple syrup, Mint leaves',
        base: 'Whiskey'
    },
    {
        name: 'Manhattan',
        description: 'Rye whiskey, Sweet vermouth, Angostura bitters',
        base: 'Whiskey'
    },


    {
        name: 'New York sour',
        description: 'Bourbon, Lime juice, Simple syrup, Red wine float, White foam (optional)',
        base: 'Whiskey'
    },
    {
        name: 'Old fashioned',
        description: 'Bourbon, Sweetener, Angostura bitters',
        base: 'Whiskey',
        selected: true,
        blog: 'old-fashioned-cocktail'
    },
    {
        name: 'Old Pal',
        description: 'Bourbon, Dry vermouth, Campari',
        base: 'Whiskey'
    },
    {
        name: 'Penicillin',
        description: 'Scotch, Lime juice, Honey syrup, Ginger syrup, Smoky Scotch',
        base: 'Whiskey'
    },
    {
        name: 'Rob roy',
        description: 'Scotch, Sweet vermouth, Angostura bitters,',
        base: 'Whiskey'
    },
    {
        name: 'Churchill',
        description: 'Scotch, Sweet vermouth, Lime juice, Cointreau',
        base: 'Whiskey'
    },
    {
        name: 'Sazerac',
        description: 'Rye whiskey, Sugar, Peychaud\'s bitters, Absinthe rinse, ',
        base: 'Whiskey'
    },
    {
        name: 'Whisky sour',
        description: 'Bourbon, Lime juice, Simple syrup, White foam (optional)',
        base: 'Whiskey',
        selected: true,
        blog: 'whiskey-sour-cocktail'
    },
    {
        name: 'Absinthe sour',
        description: 'Absinthe, Vodka, lemon juice, Sugar, White foam (optional)',
        base: 'Others'
    },
    {
        name: 'Basilinia',
        description: 'Bianco vermouth, Basil leaves, Lime juice, Sugar',
        base: 'Others',
        selected: true,
        blog: 'basilinia-cocktail'

    },
    {
        name: 'Arago',
        description: 'Cognac, Banana liquor, Cream',
        base: 'Others'
    },
    {
        name: 'Sidecar',
        description: 'Cognac, Cointreau, Lemon juice',
        base: 'Others'
    },
    {
        name: 'Stinger',
        description: 'Brandy, Crème de menthe',
        base: 'Others'
    },
    {
        name: 'Grasshopper',
        description: 'Crème de menthe, Crème de cacao, Cream',
        base: 'Others'
    },
]

const bases = [...(new Set(CLASSICS.map(it => it.base)))];

function Menusection() {
    const [tabMenu, tabActive] = useState('');
    const [display, setDisplay] = useState('block');
    const filterdMenu = CLASSICS.filter(it => tabMenu ? it.base === tabMenu : it.selected)

    useEffect(() => {
        setDisplay('none');
    }, [])

    const handleLeft = () => {
        const index = [...bases].findIndex(it => it === tabMenu);
        if (bases[index - 1]) {
            tabActive(bases[index - 1])
        } else {
            tabActive('');
        }
    }

    const handleRight = () => {
        const index = bases.findIndex(it => it === tabMenu);
        if (bases[index + 1]) {
            tabActive(bases[index + 1])
        }
    }

    const swipeHandlers = Swipe({
        onSwipedLeft: handleRight,
        onSwipedRight: handleLeft
    });

    return (
        <>
            <section className="menu-section">

                <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar, Classic Cocktail, sexonthebeach, sex on the beach" title="" /></div>

                <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar, sexonthebeach, sex on the beach, negroni" title="" /></div>

                <div className="auto-container">
                    <div className="title-box centered">
                        {/* <div className="subtitle"><span>Special selection</span></div> */}

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar, classic cocktail, margarita' />
                        </div>

                        <h1>Classic Cocktails</h1>
                    </div>

                    <div className="tabs-box menu-tabs" {...swipeHandlers}>
                        <div className="buttons">
                            <ul className="tab-buttons clearfix">
                                <li
                                    className={`tab-btn plausible-event-name=Classic-top-click ${tabMenu === '' && "active-btn"}`}
                                    onClick={() => tabActive('')}
                                    key={'selected-classic'}
                                    data-tab="#tab-4"><span>Top Sellers</span>
                                </li>
                                {bases.map(it => {
                                    return (
                                        <li
                                            className={`tab-btn plausible-event-name=Classic-${it}-click ${tabMenu === it && "active-btn"}`}
                                            onClick={() => tabActive(it)}
                                            key={it}
                                            data-tab="#tab-4"><span>{it}</span>
                                        </li>
                                    )
                                })

                                }
                            </ul>
                        </div>
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 !== 0) return null;
                                                return (
                                                    <div className="dish-block" key={item.name}>
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div>
                                                            <div className="title clearfix">
                                                                <div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div>
                                                                <div className="price"><span>189.000</span></div></div>
                                                            <div className="text desc"><Link to={item.blog ? `/blogs/${item.blog}` : "#"}>{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 === 0) return null;
                                                return (
                                                    <div className="dish-block" key={item.name}>
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}`} /></Link></div>
                                                            <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div><div className="price"><span>189.000</span></div></div>
                                                            <div className="text desc"><Link to={item.blog ? `/blogs/${item.blog}` : "#"}>{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="menu-col col-lg-6 col-md-12 col-sm-12" style={{display}}>
                    <div className="inner">
                        {CLASSICS.map((item) => {
                            return (
                                <div className="dish-block" key={item.name + 'listing'}>
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div></div>
                                        <div className="text desc"><Link to="#">{item.description}</Link></div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div> */}
            </section>
            {
                false &&
                (
                    <div className='footer-menu'>
                        Sex on the beach
                        Vodka, Peach liquor, cranberry, orange
                        Cosmopolitan
                        Vodka, Cranberry juice, Cointreau, Lime juice
                        Espresso Martini
                        Vodka, Cafe liquor, epresso
                        Culture
                        Vodka, Yoghurt, Passionfruit Puree, Vanilla
                        Moscow mule
                        Vodka, Lime juice, Ginger beer
                        Wasabi martini
                        Vodka, Wasabi, Lime, Sugar
                        Sea breeze
                        Vodka, Cranberry juice, Grapefruit juice
                        Vodkatini
                        Vodka, Dry vermouth
                        Vesper
                        Vodka, Gin, Bianco Vermouth
                        Black / White russian
                        Vodka, Coffee liqueur, Heavy cream or Milk (optional)
                        Leapfrog
                        Gin, Lemon Juice, Apricot liquor, Mint, Sugar, Orange bitter
                        Bee’s knees
                        Gin, Lemon juice, Honey syrup
                        Corpse reviver #2
                        Gin, Lemon juice, Cointreau, Lillet Blanc, Absinthe
                        Bramble
                        Gin, Blackberry liqueur, Simple syrup, Lemon juice
                        Southside
                        Gin, Lime juice, Simple syrup, Mint leaves, Angostura bitter
                        Clover club
                        Gin, Raspberry syrup, Lemon juice, White foam, Dry vermouth (optional)
                        Gimlet
                        Gin, Lime juice, Simple syrup
                        Vieux mot
                        Gin, Elderflower liquor, Lime, Sugar
                        Tuxedo
                        Gin, Dry vermouth, Maraschino liqueur, Orange bitters, Absinthe rinse
                        Martinez
                        Gin, Sweet vermouth, Maraschino liqueur, Orange bitters
                        Gin Martini
                        Gin, Dry vermouth
                        Negroni
                        Gin, Campari, Sweet vermouth
                        Ramos gin fizz
                        Gin, Lemon juice, Lime juice, Simple syrup, Cream, White foam, Orange flower water, Vanilla
                        Water Lily
                        Gin, Creme de Violette, Cointreau, Lemon juice
                        Mojito
                        White rum, Lime juice, Simple syrup, Mint leaves, Club soda
                        Caipirinha
                        Cachaça, Lime, Sugar
                        Daiquiri
                        White rum, Lime juice, Simple syrup
                        Dark and stormy
                        Dark rum, Ginger beer, Lime
                        Hemingway daiquiri
                        White rum, Grapefruit juice, Maraschino liqueur, Lime juice
                        Maitai
                        Dark rum, White rum, Cointreau, Orgeat syrup, Lime juice
                        Pina colada
                        White rum, Pineapple juice, Coconut cream
                        Zombie
                        Dark rum, Light rum, Lime juice, Pineapple juice, Pink Grapefruit, Absinthe
                        Paloma
                        Tequila, Grapefruit soda, Lime juice, Raspberry, Salt (optional)
                        Tequila sour
                        Tequila, Lemon juice, Simple syrup, White foam (optional)
                        Bermondsey Minute
                        Tequila, Sweet vermouth, Luxardo Maraschino, Lavender bitter
                        El Diablo
                        Tequila, Ginger beer, Lime juice, Crème de cassis
                        Last chance
                        Tequila, Apricot brandy, Honey, Lime juice
                        Margarita
                        Tequila, Lime juice, Cointreau
                        Siesta
                        Tequila, Campari, Grapefruit juice, Lime juice, Simple syrup
                        Whiskey smash
                        Bourbon, Lemon juice, Simple syrup, Mint leaves
                        Boulevardier
                        Bourbon, Sweet vermouth, Campari
                        God father
                        Scotch, Amaretto
                        Mint julep
                        Bourbon, Simple syrup, Mint leaves
                        Manhattan
                        Rye whiskey, Sweet vermouth, Angostura bitters
                        New York sour
                        Bourbon, Lime juice, Simple syrup, Red wine float, White foam (optional)
                        Old fashioned
                        Bourbon, Sweetener, Angostura bitters
                        Old Pal
                        Bourbon, Dry vermouth, Campari
                        Penicillin
                        Scotch, Lime juice, Honey syrup, Ginger syrup, Smoky Scotch
                        Rob roy
                        Scotch, Sweet vermouth, Angostura bitters,
                        Churchill
                        Scotch, Sweet vermouth, Lime juice, Cointreau
                        Sazerac
                        Rye whiskey, Sugar, Peychaud's bitters, Absinthe rinse,
                        Whisky sour
                        Bourbon, Lime juice, Simple syrup, White foam (optional)
                        Absinthe sour
                        Absinthe, Vodka, lemon juice, Sugar, White foam (optional)
                        Basilinia
                        Bianco vermouth, Basil leaves, Lime juice, Sugar
                        Arago
                        Cognac, Banana liquor, Cream
                        Sidecar
                        Cognac, Cointreau, Lemon juice
                        Stinger
                        Brandy, Crème de menthe
                        Grasshopper
                        Crème de menthe, Crème de cacao, Cream

                        Evan William Black
                        Evan William White
                        Bulleit Rye
                        Maker's Mark
                        Jack Daniel
                        Jameson
                        Talisker 10yrs
                        Johnnie Walker Black Label
                        Monkey Shoulder
                        Sipsmith Sloe
                        Hendrick
                        Tanqueray
                        Bombay
                        Gordon's
                        Jose Cuervo Gold
                        400 Conejos
                        Jose Cuervo Silver
                        Lunazul Anejo
                        Flor De Cana 4 (White) (LH)
                        Captain Morgan Black
                        Havana 7 years old
                        Havana 3 years old
                        Cachaca Lanius
                        Hennessy VS (MH)
                        Dolin Rosso Vermouth (RA)
                        Dolin Blanc Vermouth (RA)
                        Dolin Dry Vermouth (RA)
                        Choya Kishu
                        Absente Fairy Green Tree 70*
                        Angostura Bitter Aromatic (LH)
                        Peychauds Bitters
                        Giffard Creme de Cassis (LH)
                        Giffard Peach (LH)
                        Giffard Limoncello (LH)
                        Giffard Violette (LH)
                        Giffard Creme de Cacao White (LH)
                        Giffard Amaretto (LH)
                        Giffard Abricot Classic (LH)
                        Campari
                        Malibu
                        Kahlua
                        Jagermeister
                        Cointreau
                        Aperol
                        Sambuca Vaccari
                        Luxardo Maraschino
                        Bailey's
                    </div>
                )
            }
        </>
    )
}

export default Menusection