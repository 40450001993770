import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/bg-2.png'
import bgone from '../../assets/images/background/bg-1.png'
import five from '../../assets/images/resource/spirit.png'
import Swipe from '../../swipte';

const CLASSICS = [
    {
        name: 'Evan William Black',
        // description: 'American whisky',
        base: 'American whisky',
        price: '120.000 / 1.200.000'
    },

    {
        name: 'Evan William White',
        // description: 'American whisky',
        base: 'American whisky',
        price: '140.000 / 1.600.000'
    },
    {
        name: 'Bulleit Rye',
        // description: 'American whisky',
        base: 'American whisky',
        price: '180.000 / 2.100.000'
    },

    {
        name: 'Maker\'s Mark',
        // description: 'American whisky',
        base: 'American whisky',
        price: '170.000 / 2.000.000'
    },

    {
        name: 'Jack Daniel',
        // description: 'American whisky',
        base: 'American whisky',
        price: '150.000 / 1.600.000'
    },

    {
        name: 'Jameson',
        // description: 'American whisky',
        base: 'Irish whisky',
        price: '150.000 / 1.500.000'
    },
    {
        name: 'Talisker 10yrs',
        // description: 'American whisky',
        base: 'Scotch',
        price: '200.000 / 3.900.000'
    },

    {
        name: 'Johnnie Walker Black Label',
        // description: 'American whisky',
        base: 'Scotch',
        price: '160.000 / 1.800.000'
    },

    {
        name: 'Monkey Shoulder',
        // description: 'American whisky',
        base: 'Scotch',
        price: '200.000 / 2.700.000'
    },

    {
        name: 'Nordes Gin',
        // description: 'American whisky',
        base: 'Gin',
        price: '200.000 / 3.000.000'
    },

    {
        name: 'Sipsmith Sloe',
        // description: 'American whisky',
        base: 'Gin',
        price: '240.000 / 3.000.000'
    },

    {
        name: 'Hendrick',
        // description: 'American whisky',
        base: 'Gin',
        price: '190.000 / 3.200.000'
    },

    {
        name: 'Tanqueray',
        // description: 'American whisky',
        base: 'Gin',
        price: '130.000 / 1.400.000'
    },

    {
        name: 'Bombay',
        // description: 'American whisky',
        base: 'Gin',
        price: '140.000 / 1.400.000'
    },

    {
        name: 'Gordon\'s',
        // description: 'American whisky',
        base: 'Gin',
        price: '90.000 / 700.000'
    },


    {
        name: 'Jose Cuervo Gold',
        // description: 'American whisky',
        base: 'Agave',
        price: '100.000 / 1.400.000'
    },


    {
        name: '400 Conejos',
        // description: 'American whisky',
        base: 'Agave',
        price: '240.000 / 3.500.000'
    },

    {
        name: 'Jose Cuervo Silver',
        // description: 'American whisky',
        base: 'Agave',
        price: '120.000 / 1.500.000'
    },

    {
        name: 'Lunazul Anejo',
        // description: 'American whisky',
        base: 'Agave',
        price: '200.000 / 2.500.000'
    },

    {
        name: 'Flor De Cana 4 (White) (LH)',
        // description: 'American whisky',
        base: 'Rum',
        price: '120.000 / 1.200.000'
    },

    {
        name: 'Captain Morgan Black',
        // description: 'American whisky',
        base: 'Rum',
        price: '120.000 / 1.000.000'
    },

    {
        name: 'Havana 7 years old',
        // description: 'American whisky',
        base: 'Rum',
        price: '190.000 / 2.100.000'
    },

    {
        name: 'Havana 3 years old',
        // description: 'American whisky',
        base: 'Rum',
        price: '120.000 / 1.200.000'
    },

    {
        name: 'Cachaca Lanius',
        // description: 'American whisky',
        base: 'Rum',
        price: '160.000 / l.800.000'
    },

    {
        name: 'Hennessy VS (MH)',
        // description: 'American whisky',
        base: 'Cognac',
        price: '210.000 / 2.800.000'
    },



    {
        name: 'Dolin Rosso Vermouth (RA)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '150.000 / 2.000.000'
    },

    {
        name: 'Dolin Blanc Vermouth (RA)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '150.000 / 2.000.000'
    },
    {
        name: 'Dolin Dry Vermouth (RA)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '150.000 / 2.000.000'
    },

    {
        name: 'Choya Kishu',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },

    {
        name: 'Absente Fairy Green Tree 70*',
        // description: 'American whisky',
        base: 'Liquor',
        price: '170.000 / 2.500.000'
    },

    {
        name: 'Angostura Bitter Aromatic (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },

    {
        name: 'Peychauds Bitters',
        // description: 'American whisky',
        base: 'Liquor',
        price: '190.000 / 2.100.000'
    },

    {
        name: 'Giffard Creme de Cassis (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },

    {
        name: 'Giffard Peach (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '140.000 / 1.400.000'
    },

    {
        name: 'Giffard Limoncello (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },

    {
        name: 'Giffard Violette (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },
    {
        name: 'Giffard Creme de Cacao White (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },
    {
        name: 'Giffard Amaretto (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },



    {
        name: 'Giffard Abricot Classic (LH)',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },

    {
        name: 'Campari',
        // description: 'American whisky',
        base: 'Liquor',
        price: '140.000 / 1.400.000'
    },
    {
        name: 'Malibu',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.200.000'
    },

    {
        name: 'Kahlua',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },

    {
        name: 'Jagermeister',
        // description: 'American whisky',
        base: 'Liquor',
        price: '120.000 / 1.100.000'
    },

    {
        name: 'Cointreau',
        // description: 'American whisky',
        base: 'Liquor',
        price: '140.000 / 1.400.000'
    },

    {
        name: 'Aperol',
        // description: 'American whisky',
        base: 'Liquor',
        price: '160.000 / 2.300.000'
    },

    {
        name: 'Sambuca Vaccari',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.900.000'
    },

    {
        name: 'Luxardo Maraschino',
        // description: 'American whisky',
        base: 'Liquor',
        price: '150.000 / 2.200.000'
    },

    {
        name: 'Bailey\'s',
        // description: 'American whisky',
        base: 'Liquor',
        price: '130.000 / 1.300.000'
    },

]

const bases = [...(new Set(CLASSICS.map(it => it.base)))];

function Spirits() {
    const [tabMenu, tabActive] = useState('American whisky');
    const [display, setDisplay] = useState('block');
    const filterdMenu = CLASSICS.filter(it => it.base === tabMenu)

    useEffect(() => {
        setDisplay('none');
    }, [])



    const handleLeft = () => {
        const index = [...bases].findIndex(it => it === tabMenu);
        if (bases[index - 1]) {
            tabActive(bases[index-1])
        }
    }

    const handleRight = () => {
        const index = bases.findIndex(it => it === tabMenu);
        if (bases[index + 1]) {
            tabActive(bases[index+1])
        }
    }

    const swipeHandlers = Swipe({
        onSwipedLeft: handleRight,
        onSwipedRight: handleLeft
    });

    return (
        <>
            <section className="menu-section">

                <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>

                <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>

                <div className="auto-container">
                    <div className="title-box centered">
                        {/* <div className="subtitle"><span>Special selection</span></div> */}

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                        </div>

                        <h2>Spirits</h2>
                    </div>

                    <div className="tabs-box menu-tabs" {...swipeHandlers}>
                        <div className="buttons">
                            <ul className="tab-buttons clearfix">

                            {bases.map(it => {
                                    return (
                                        <li 
                                            className={`tab-btn plausible-event-name=Spirit-click  ${tabMenu === it && "active-btn"}`} 
                                            onClick={() => tabActive(it)} 
                                            key={it}
                                            data-tab="#tab-4"><span>{it}</span>
                                        </li>
                                    )
                                })

                                }
                            </ul>
                        </div>
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 !== 0) return null;
                                                return (
                                                    <div className="dish-block" key={item.name}>
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}` }/></Link></div>
                                                            <div className="title clearfix">
                                                                <div className="ttl clearfix"><h5><Link to="#">{item.name}</Link></h5></div>
                                                                <div className="price"><span>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index % 2 === 0) return null;
                                                return (
                                                    <div className="dish-block" key={item.name}>
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}` }/></Link></div>
                                                            <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div><div className="price"><span>{item.price}</span></div></div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="menu-col col-lg-6 col-md-12 col-sm-12" style={{display}}>
                    <div className="inner">
                        {CLASSICS.map((item) => {
                            return (
                                <div className="dish-block" key={item.name + 'listing'}>
                                    <div className="inner-box">
                                        <div className="title clearfix"><div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div></div>
                                        <div className="text desc"><Link to="#">{item.description}</Link></div>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                </div> */}

            </section>
        </>
    )
}

export default Spirits