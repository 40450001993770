import React from 'react';
import Iframe from 'react-iframe'
// import Backgroundimage from '../../assets/images/background/image-4.jpg';
import { Link } from 'react-router-dom'
// import logonew from '../../assets/images/logo.png'
import logonew from '../../assets/images/resource/logo3.png'


function Main() {
    return (
        <>

            <footer className="main-footer">
                {/* <div className="image-layer" style={{ backgroundImage: `url(${Backgroundimage})` }}> </div> */}
                <div className="upper-section">
                    <div className="auto-container">
                        <div className="row clearfix">

                            <div className="footer-col info-col col-lg-3 col-md-12 col-sm-12">
                                <div className="inner wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="content">
                                        <div className="logo"><Link to="/" title="Nectar Hideaway"><img src={logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Nectar Hideaway" /></Link></div>
                                        <div className="info">
                                            <ul>
                                                <li><strong>Location</strong><br />4th Floor, 20 Trang Tien, Hoan Kiem, Ha Noi </li>
                                                <li><Link to="tel:+84869452656">Booking Request : +84869452656</Link></li>
                                                <li>Open : 05:00 pm - 00:30 am</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="footer-col links-col col-lg-3 col-md-6 col-sm-12">
                                <div className="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <Link to="/">Home</Link></li>
                                        <li> <Link to="/menu">Menus</Link></li>
                                        <li> <Link to="/about">About us</Link></li>
                                        <li> <Link to="/contact">Contact</Link></li>
                                    </ul>
                                </div>
                            </div> */}

                            <div className="footer-col links-col last col-lg-3 col-md-3 col-sm-12">
                                <div className="inner wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <ul className="links">
                                        <li> <a href="https://www.facebook.com/nectar.hideaway">facebook</a></li>
                                        <li> <a href="https://www.instagram.com/nectar.hideaway/">instagram</a></li>
                                        <li> <a href="https://www.tiktok.com/@nectar_hideaway">Tiktok</a></li>
                                        <li><Link to="/blogs"> Blogs </Link> </li>
                                        <li> <a href="https://goo.gl/maps/C9pqBKUDBcbhWJKn8">Google map</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-col links-col last col-lg-3 col-md-6 col-sm-12">
                                <div className="contact-map" id="map">
                                    <Iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.1969886339393!2d105.85348427618682!3d21.024802587912678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ab86d8b030b3%3A0xb3aa63de34b71d51!2sNectar%20Hideaway!5e0!3m2!1svi!2s!4v1687840283789!5m2!1svi!2s"
                                        width="600" height="569" style="border:0;" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade">
                                    </Iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <iframe src="" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}


                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="copyright">&copy; 2023 Nectar Hideaway Speakeasy. All Rights Reserved </div>
                    </div>
                </div>

            </footer>
            <div className="scroll-to-top scroll-to-target" data-target="html"><span className="icon fa fa-angle-up"></span></div>
        </>

    )
}

export default Main