import React, { useEffect } from "react";
import Bredcrumb from '../../Bredcrumb/Main';
import { Link } from 'react-router-dom'
import bookicon1 from '../../../assets/images/resource/favicon.png'

import Bgtwo from '../../../assets/images/background/contact-background.jpg'
import { useParams } from "react-router-dom";
import {
    NavButton,
    PostDetailCard,
    PostDetailContainer,
    PostDetailContent,
} from "./styles";
import posts, { Signatures } from '../data';

const IMG_REPO = 'https://blogs.inter.ducphuclee.tech/wp-content/uploads'

export function PostDetail() {
    //   const [post, setPost] = useState({});
    const { id } = useParams();
    const post = [...Signatures, ...posts].find(it => it.number == id);

    useEffect(() => {
        if (post && post.title) {
            document.title = post.title;
        }
    }, []);


    //   async function fetchPost() {
    //     const response = await api.get(
    //       `/repos/pedr0d1as/Github-blog-issues/issues/${id}`
    //     );
    //     const { title, comments, created_at, user, html_url, body } = response.data;
    //     const newPostObj = {
    //       title,
    //       githubUsername: user.login,
    //       comments,
    //       createdAt: formatDistanceToNow(new Date(created_at), {
    //         locale: enUS,
    //         addSuffix: true,
    //       }),
    //       url: html_url,
    //       body,
    //     };
    //     setPost(newPostObj);
    //   }

    //   useEffect(() => {
    //     fetchPost();
    //   }, []);

    return (
        <>
            <Bredcrumb
                subtitle="Blogs"
                title="OUR STORY"
                Img={Bgtwo}
            />
            <div className="auto-container">
                <PostDetailContainer>
                    <PostDetailCard>
                        <header>
                            <NavButton to="/blogs" type="button">
                                {/* <i className="fa-solid fa-chevron-left"></i> */}
                                Back to Blogs
                            </NavButton>
                        </header>
                        <div>
                            <h1>{post?.title}</h1>
                        </div>
                    </PostDetailCard>
                    <PostDetailContent>

                        {post?.image ?
                            <div className="pattern-image">
                                <img src={IMG_REPO + post.image} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${post?.title}`} />
                                <div dangerouslySetInnerHTML={{ __html: post.imageCredits }} />
                            </div>
                            :
                            null
                        }

                        <div>
                            <div dangerouslySetInnerHTML={{ __html: post?.body }} />
                        </div>
                    </PostDetailContent>
                    {
                        post?.video ? (
                            <PostDetailContent>
                                <iframe
                                    width="560"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${post.video}`}
                                    title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen>
                                </iframe>
                            </PostDetailContent>
                        ) : null
                    }

                    <PostDetailCard>
                        <header>
                            <NavButton to="/blogs" type="button">
                                {/* <i className="fa-solid fa-chevron-left"></i> */}
                                Back to Blogs
                            </NavButton>
                        </header>
                    </PostDetailCard>
                </PostDetailContainer>

            </div>
            <div className="books-btn"
                style={{ position: 'fixed' }}
            >
                <Link to="/" className="theme-btn plausible-event-name=Menu-top-click">
                    <span className="icon">
                        <img src={bookicon1} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Menu" />
                    </span>
                    <span className="txt">Menu</span>
                </Link>
            </div>
        </>
    );
}
