import React from 'react'
import Bredcrumb from '../Bredcrumb/Main'
import Bgtwo from '../../assets/images/background/contact-background.jpg'
import bookicon1 from '../../assets/images/resource/favicon.png'
import { Link } from 'react-router-dom'




function Main() {
  return (

    <>
      <Bredcrumb
        subtitle="Not Found"
        title="Not Found"
        Img={Bgtwo}
      />

      <div className="books-btn" 
        style={{position: 'fixed'}}
      >
        <Link to="/" className="theme-btn plausible-event-name=Menu-top-click">
          <span className="icon">
            <img src={bookicon1} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="Menu" />
          </span>
          <span className="txt">Menu</span>
        </Link>
      </div>
      {/* <Aboutwhyus /> */}
    </>

  )
}

export default Main