const BlogData = [
    {
        title: `Mojito - The Refreshing Cuban Classic`,
        video: '76Imy4n9pxc',
        taste: `Mojitos have a refreshing, minty, slightly sweet and tangy flavor, that is perfect for sipping on a hot day`,
        ingredients: 'White rum, Lime juice, Syrup, Mint leaves, Sparkling soda',
        image: '/2023/07/fresh-drink-with-green-lime-1-1.jpg',
        imageCredits: '<a href="https://www.freepik.com/free-photo/fresh-drink-with-green-lime_6543373.htm#query=mojito%20cocktail&position=9&from_view=search&track=ais">Image by Racool_studio</a> on Freepik',
        number: 'mojito-coctail',
        description: 'Originating in the 16th century, the Mojito carries a rich history that adds to its allure...',
        body: `
        Embark on a journey to the vibrant streets of Havana with a timeless cocktail - the Mojito. This Cuban classic has captivated taste buds across the world with its invigorating blend of fresh mint, zesty lime, and crisp rum.

        Originating in the 16th century, the Mojito carries a rich history that adds to its allure. Traditionally made with white rum, lime juice, sugar, sparkling water, and muddled mint leaves, this concoction is the epitome of a revitalizing summer drink.

        At our speakeasy bar, we pride ourselves on crafting the perfect Mojito, ensuring each element is in harmonious balance. The aroma of crushed mint leaves combined with the tangy zest of lime creates an enticing bouquet that awakens the senses.

        The Mojito's signature blend of sweet and sour flavors dances on the palate, invigorating your taste buds with every sip. The addition of sparkling water gives the drink a gentle effervescence, further enhancing its refreshing nature.

        As you raise your glass to your lips, you'll experience the essence of Cuba - a lively spirit and a passion for life. The Mojito's enchanting taste is best enjoyed on a balmy evening, where it transports you to a tropical paradise filled with relaxation and laughter.

        While the Mojito's classic recipe remains timeless, variations have emerged over the years, offering a twist to this beloved libation. From fruit-infused Mojitos to creative herb combinations, our bartenders take pride in presenting you with unique interpretations.

        Join us at our rooftop bar and bask in the nostalgic charm of the Mojito. Let the cool breeze of the evening transport you to the heart of Havana, where you can savor this refreshing elixir and unwind in the company of good friends.

        Allow the Mojito to be your passport to a moment of pure bliss. At our speakeasy bar, we celebrate the spirit of Cuba and invite you to embark on a taste adventure with the classic Mojito. Cheers to the vibrant flavors and the timeless allure of this iconic cocktail!
        `
    },
    {
        title: 'Mocktail Magic - Explore the World of Non-Alcoholic Delights',
        taste: '',
        ingredients: '',
        number: 'mocktail-magic',
        description: `A mocktail, short for "mock cocktail," is a non-alcoholic beverage crafted with the same care and attention`,
        body: `
        Step into a realm of exquisite flavors and creative concoctions with our enticing collection of mocktails. Whether you're abstaining from alcohol or simply seeking a refreshing alternative, our mocktails are designed to delight your taste buds and elevate your experience.

        A mocktail, short for "mock cocktail," is a non-alcoholic beverage crafted with the same care and attention to detail as its alcoholic counterparts. Bursting with vibrant colors, fresh fruits, and aromatic herbs, these alcohol-free elixirs are a delightful fusion of taste and artistry.

        At our speakeasy bar, our mixologists take great pride in crafting mocktails that cater to diverse palates. From zesty citrus blends to luscious fruit medleys, each mocktail is a symphony of flavors carefully curated to surprise and satisfy.

        Indulge in the refreshing tang of a Virgin Mojito, where muddled mint, lime, and soda create a captivating melody of taste. Sip on the Sunrise Sparkler, an invigorating blend of tropical fruits that transport you to an island paradise with every sip.

        Our mocktails aren't just a substitute for alcoholic beverages; they are a celebration of taste and creativity. Watch as our skilled bartenders artfully mix fresh ingredients, transforming them into masterpieces that tantalize the senses.

        Designed to complement our cocktail offerings, our mocktails cater to every occasion and preference. Whether you're lounging on our rooftop, savoring a meal at our elegant bar, or celebrating a special moment, our mocktails promise to elevate your experience.

        Embrace the joy of flavorful libations without the influence of alcohol. At our speakeasy bar, we invite you to explore the world of mocktail magic and discover a new realm of taste sensations. Raise your glass and join us on a journey of indulgence and inspiration with our delightful mocktails. Cheers to a vibrant, alcohol-free experience!
        `
    },
    {
        title: 'Sex on the Beach Cocktail - A Tropical Delight',
        video: 'aQu8LxzQFPM',
        taste: 'A fruity and sweet taste blend of peach schnapps, vodka, and cranberry juice, with a hint of citrus',
        ingredients: 'Vodka, Peach liquor, cranberry, orange',
        number: 'sex-on-the-beach-cocktail',
        image: '/2023/07/Sex_On_The_Beach_Recipe.jpg',
        imageCredits: '',
        description: 'The Sex on the Beach cocktail is a classic favorite among cocktail enthusiasts worldwide. Its vibrant colors and refreshing taste...',
        body: `
            <h3>Introduction:</h3>
            Embark on a journey of classic elegance with the renowned cocktail, "Sex on the Beach." Crafted to entice the senses, this timeless libation features a blend of vodka, peach liqueur, cranberry juice, and orange juice. Join us at our bar to savor the harmonious medley of flavors that "Sex on the Beach" has to offer.

            <h3>1. The Distinctive Flavors of Sex on the Beach:</h3>
            Indulge in the iconic "Sex on the Beach" cocktail, a delightful balance of flavors. The sweetness of peach liqueur harmonizes with the tangy notes of cranberry juice, creating a perfect marriage of taste. The citrusy essence of orange juice adds a refreshing twist, while the vodka provides a subtle kick. The result is a concoction that's both fruity and invigorating.

            <h3>2. Exploring the Sex on the Beach Classic Cocktail:</h3>
            History and Origin:
            The "Sex on the Beach" cocktail emerged during the late 20th century as part of the cocktail renaissance. Its exact origins are disputed, but it gained popularity as a playful and approachable drink that captures the essence of a seaside getaway. The cocktail's name itself is a nod to its alluring and vibrant flavors.

            <h4>The Allure of Sex on the Beach:</h4>
            The cocktail's allure lies in its ability to transport you to a tropical paradise with each sip. The combination of fruity and citrusy flavors evokes feelings of relaxation and indulgence. It's a drink that carries a sense of carefree escapism, making it a beloved choice for those seeking a touch of nostalgia and a vibrant taste experience.

            <h4>Serving and Enjoying:</h4>
            Served in a highball glass, the "Sex on the Beach" cocktail boasts a vibrant hue that mirrors its beach-inspired name. The garnish of an orange slice adds a visual touch of freshness. Sip leisurely to fully enjoy the layers of taste, letting the sweet and tangy notes dance on your palate. This cocktail is best enjoyed in a laid-back setting, where you can unwind and savor the flavors.

            <h4>For Whom is Sex on the Beach:</h4>
            The "Sex on the Beach" cocktail is a timeless choice for those seeking a classic and approachable cocktail experience. It's perfect for individuals who appreciate a well-balanced drink that's both easy-going and flavorful. Whether you're a cocktail connoisseur, a lover of fruity blends, or someone seeking a taste of nostalgia, this cocktail invites you to relish in a timeless sip of elegance.

            <h3>Conclusion:</h3>
            Indulge in the classic elegance of the "Sex on the Beach" cocktail, a drink that captures the essence of carefree coastal vibes. Join us at our bar to experience the harmony of flavors that this iconic cocktail brings. Whether you're drawn by the allure of classic cocktails, the desire for a tropical taste journey, or simply looking for a drink that encapsulates the spirit of relaxation, the "Sex on the Beach" cocktail invites you to savor a sip of timeless charm and flavor.
        `,

    },
    {
        title: 'The Alluring Negroni - Unraveling the Secrets of a Timeless Italian Classic',
        video: 'jUVo4IiL_xY',
        taste: 'Bold and bittersweet taste - a perfect harmony of gin, Campari, and vermouth, creating a sophisticated and well-balanced flavor.',
        ingredients: 'Gin, Campari, Sweet vermouth',
        number: 'negroni-cocktail',
        image: '/2023/07/blend-cocktails-glasses-with-orange-fruit.jpg',
        imageCredits: 'Image by <a href="https://www.freepik.com/free-photo/blend-cocktails-glasses-with-orange-fruit_25633943.htm#query=negroni%20cocktail&position=7&from_view=search&track=ais">Freepik</a>',
        description: `The Negroni's origin story is as intriguing as its taste. Legend has it that it was first concocted in Florence, Italy`,
        body: `
        Step into the world of Italian elegance and sophistication with the mesmerizing Negroni cocktail. This iconic drink has captured the hearts of cocktail enthusiasts for over a century, and its timeless charm continues to make it a staple in bars around the globe.

        The Negroni's origin story is as intriguing as its taste. Legend has it that it was first concocted in Florence, Italy, during the early 20th century. Count Camillo Negroni, a daring nobleman, sought a bolder version of his favorite Americano cocktail, so he requested gin to replace the soda water. The bartender's artistry blended gin, Campari, and sweet vermouth to create the Negroni we know and love today.

        A true classic, the Negroni features equal parts gin, Campari, and sweet vermouth, creating a harmonious balance of flavors. Its striking ruby-red color and bittersweet taste make it an enticing choice for those with a discerning palate.

        The Negroni's appeal lies in its versatility. Despite its simplicity, it can be tailored to suit various preferences. Adventurous bartenders often add their twists by infusing the gin or experimenting with different types of vermouth. Some even introduce subtle changes, like swapping Campari with Aperol for a gentler bitterness.

        At our speakeasy bar, our expert mixologists take pride in crafting the perfect Negroni. With precision and passion, they combine the finest ingredients to create an unforgettable experience for every guest. Whether you're sipping it neat or over ice, the Negroni's bold character and sophisticated allure will transport you to the romantic ambiance of an Italian piazza.

        Join us at our bar and immerse yourself in the legacy of the Negroni. Experience the allure of this Italian masterpiece, which continues to captivate cocktail enthusiasts around the world. Cheers to the Negroni, a timeless classic that leaves an indelible mark on every sip. Salute!
        `
    },
    {
        title: `Pina Colada - Sip Your Way to Tropical Paradise`,
        video: 'oisqUoOPXWc',
        taste: 'Tropical and sweet taste - creamy and coconutty, with a refreshing blend of pineapple and a hint of tanginess',
        ingredients: 'White rum, Pineapple juice, Coconut cream',
        number: 'pina-colada-cocktail',
        image: '/2023/07/delicious-pina-colada-cocktails.jpg',
        imageCredits: '<a href="https://www.freepik.com/free-photo/delicious-pina-colada-cocktails_37578484.htm#page=2&query=pina%20colada&position=12&from_view=search&track=ais">Image by pikisuperstar</a> on Freepik',
        description: `The origins of the Pina Colada can be traced back to Puerto Rico, where it was created in the 1950s. The story goes that a bartender named Ramón "Monchito"`,
        body: `
        Indulge in the luscious flavors of the Pina Colada and let your taste buds be whisked away to a sun-kissed tropical paradise. This iconic cocktail, known for its creamy coconut and pineapple blend, is the epitome of vacation in a glass.

        The origins of the Pina Colada can be traced back to Puerto Rico, where it was created in the 1950s. The story goes that a bartender named Ramón "Monchito" Marrero Perez was tasked with concocting a new signature drink for the Caribe Hilton's Beachcomber Bar. After months of experimenting, he finally crafted the perfect blend of fresh pineapple, coconut cream, and white rum, giving birth to the Pina Colada we know today.

        One sip of this velvety cocktail instantly transports you to a world of swaying palm trees, pristine beaches, and gentle ocean waves. The creamy coconut intertwines with the sweet tang of fresh pineapple, while the rum adds a subtle warmth that perfectly complements the tropical medley.

        At our cocktails bar, we take great pride in serving an authentic Pina Colada that will leave you yearning for more. Our skilled mixologists artfully blend the finest ingredients, ensuring each sip is a delightful escape to an exotic island paradise.

        Whether you're lounging by the pool or unwinding with friends, the Pina Colada is the ideal companion for those seeking a taste of summer all year round. Its smooth, refreshing taste makes it a beloved choice for both seasoned cocktail enthusiasts and those new to the world of mixology.

        Next time you visit our speakeasy bar, let our Pina Colada be your passport to an unforgettable tropical journey. Savor the flavors, close your eyes, and be transported to a dreamy oasis where the worries of the world melt away. Cheers to the Pina Colada - a timeless classic that brings a touch of paradise to every sip.
        `
    },
    {
        title: `Old Fashioned - Relish the Timeless Elegance of a Classic Cocktail`,
        video: '2xrXt1-kjZE',
        taste: 'Slightly sweet with a hint of bitterness, delivering a smooth and sophisticated flavor profile.',
        ingredients: 'Bourbon, Sweetener, Angostura bitters',
        number: 'old-fashioned-cocktail',
        image: '/2023/07/viski-glass-with-orange-cocktail-with-oranges-zest.jpg',
        imageCredits: '<a href="https://www.freepik.com/free-photo/viski-glass-with-orange-cocktail-with-oranges-zest_7735220.htm#page=2&query=old%20fashioned%20cocktail&position=0&from_view=search&track=ais">Image by KamranAydinov</a> on Freepik',
        description: `Legend has it that the Old Fashioned was born in the bustling city of Louisville, Kentucky, at the Pendennis Club`,
        body: `
        Step into a world of timeless elegance with the Old Fashioned, a cocktail that has stood the test of time and continues to captivate the hearts of cocktail connoisseurs around the globe. This sophisticated drink, with its rich history dating back to the early 19th century, is a true embodiment of the art of mixology.

        Legend has it that the Old Fashioned was born in the bustling city of Louisville, Kentucky, at the Pendennis Club. A bartender crafted this iconic concoction by muddling sugar with bitters, adding a splash of water, and then pouring in quality bourbon. The combination of flavors creates a symphony of tastes that dance gracefully on your palate.

        At our speakeasy bar, we pay homage to the Old Fashioned by staying true to its roots while adding our own touch of finesse. Our mixologists expertly stir together the finest bourbon, a hint of simple syrup, and a dash of aromatic bitters, resulting in a drink that boasts a perfect balance of sweet and bitter notes.

        The Old Fashioned is an experience in itself. As you hold the glass, the aroma of the aged bourbon greets your senses, and with each sip, you uncover layers of complexity that evolve on your tongue. It's a drink that encourages you to slow down, savor the moment, and appreciate the artistry of a well-crafted cocktail.

        The beauty of the Old Fashioned lies in its versatility. While bourbon remains the classic choice, we also offer variations using rye whiskey or even aged rum for those seeking a different twist. Whether you're a seasoned bourbon enthusiast or a newcomer to the world of spirits, the Old Fashioned welcomes all to its indulgent embrace.

        Join us at our cocktails bar and allow the Old Fashioned to transport you back in time, to an era when cocktails were a true reflection of craftsmanship and elegance. Rediscover the allure of this timeless classic and experience the sophistication of a bygone era, one sip at a time. Cheers to the Old Fashioned - where tradition meets innovation, and every moment becomes a celebration.
        `
    },
    {
        title: `Long Island Iced Tea - A Potent Elixir of Surprising Flavors`,
        video: 'YgH6hFUZDU8',
        taste: 'Bold and potent blend of flavors - sweet and citrusy with a hint of tartness, delivering a refreshing and invigorating taste',
        ingredients: '',
        number: 'long-island-tea-cocktail',
        description: `The Long Island Iced Tea originated in the 1970s, where its creation is often attributed to a creative bartender`,
        body: `
        Enter the realm of the unexpected with the Long Island Iced Tea, a deceptive elixir that conceals its potent kick beneath a delightful medley of flavors. Despite its name, this iconic cocktail contains no tea but instead combines a daring blend of spirits that will take your taste buds on a thrilling journey.
        The Long Island Iced Tea originated in the 1970s, where its creation is often attributed to a creative bartender in Long Island, New York. This seemingly innocent cocktail packs a powerful punch by blending equal parts of vodka, rum, tequila, gin, and triple sec, balanced with a splash of sour mix and cola. The result? A harmonious concoction that magically mimics the appearance of a classic iced tea.

        At our speakeasy bar, we take pride in crafting Long Island Iced Tea that is nothing short of extraordinary. Our skilled mixologists artfully measure each spirit, ensuring a perfect equilibrium of flavors. With each sip, you'll experience a delightful fusion of sweet and sour notes, with the subtle essence of cola adding an intriguing depth to the drink.

        The Long Island Iced Tea is not for the faint of heart, but for those seeking an adventure in taste, it promises to be a memorable journey. Its vibrant blend of spirits dances on your palate, making it a favorite choice among those who enjoy bold, daring cocktails.

        As you indulge in this deceivingly innocuous-looking drink, you'll feel its smooth, refreshing quality instantly take hold. But don't be fooled - the Long Island Iced Tea packs a punch that will sneak up on you with each sip, so sip wisely.

        Whether you're lounging on our rooftop enjoying the panoramic view or unwinding inside our cozy speakeasy, the Long Island Iced Tea promises to elevate your cocktail experience to new heights. Come and savor this remarkable concoction, and embrace the thrill of the unexpected in every sip. Let the Long Island Iced Tea be your passport to a world of exhilarating flavors and captivating surprises. Cheers to the bold and daring journey that awaits!
        `
    },
    // {
    //     title: `Sangria - A Festive Symphony of Fruity Delights`,
    //     taste: 'Blend of flavors - sweet and fruity with a hint of tanginess, creating a refreshing and delightful taste experience',
    //     ingredients: '',
    //     number: 'sangia-cocktail',
    //     description: 'Originating in Spain, Sangria has become a global sensation...',
    //     body: `
    //     Step into a world of vibrant flavors and celebrate life with Sangria, the beloved Spanish-inspired cocktail that embodies the essence of festivity. Bursting with a colorful assortment of fruits, this delightful drink is a symphony of taste that captures the spirit of joy and togetherness.

    //     Originating in Spain, Sangria has become a global sensation, known for its refreshing and sociable nature. At our speakeasy bar, we take pride in crafting Sangria with meticulous care, ensuring a harmonious blend of wine and fruit that tantalizes your senses.

    //     The heart of Sangria lies in the wine - typically red or white - which is artfully infused with an array of fruits such as oranges, apples, berries, and luscious peaches. As the flavors mingle and marry, they create a rich, fruity base that is further enhanced by a splash of brandy or other spirits.

    //     With every sip of Sangria, you'll be transported to sun-kissed vineyards and lively fiestas. The combination of wine and fresh fruits delivers a delightful sweetness, while the hint of alcohol adds a touch of sophistication to this approachable cocktail.

    //     Served over ice and garnished with additional fruit slices, each glass of Sangria becomes a work of art, enticing you to relish the medley of flavors. The drink's versatility allows for various interpretations, making it a fantastic choice for both summer days and cozy evenings.

    //     At our rooftop bar, indulge in the joy of Sangria and let its refreshing taste infuse your gatherings with laughter and camaraderie. Whether you're celebrating a special occasion or simply savoring the moment, Sangria will transport you to a place of happiness and merriment.

    //     Join us as we raise a glass to the magical allure of Sangria - a celebration of life's simple pleasures, encapsulated in every sip. Embrace the spirit of Spain and let Sangria be the essence of your next delightful experience at our speakeasy bar. Cheers to the symphony of fruity delights that await you!
    //     `
    // },
    {
        title: `Paloma Perfection - Embrace the Zest of Mexico's Classic Mocktail`,
        video: 'Liir5F53HD0',
        taste: 'Balance of flavors - tangy, slightly sweet, and refreshingly citrusy, with a hint of bitterness',
        ingredients: 'Tequila, Grapefruit soda, Lime juice, Raspberry, Salt (optional)',
        number: 'paloma-cocktail',
        description: 'Originating from Mexico, the Paloma has become a timeless favorite, loved for its invigorating blend of flavors...',
        body: `
        Transport yourself to the sun-kissed beaches of Mexico with the Paloma, a classic mocktail that captures the essence of this vibrant country. Refreshing and citrusy, the Paloma is a beloved non-alcoholic concoction that perfectly balances the tang of grapefruit with the sweetness of agave.

        Originating from Mexico, the Paloma has become a timeless favorite, loved for its invigorating blend of flavors. At our speakeasy bar, we take pride in crafting an authentic Paloma that pays homage to its traditional roots while infusing it with a touch of modern creativity.

        The heart of this mocktail lies in its simplicity – a harmonious marriage of fresh grapefruit juice, zesty lime, and a hint of agave syrup. Our mixologists carefully muddle the ingredients to release their vibrant essences, creating a symphony of taste that dances on your palate.

        The Paloma's allure lies in its versatility. It pairs wonderfully with a variety of cuisines, making it the ideal companion to your dining experience. Whether you're indulging in Mexican delights or savoring international dishes, the Paloma adds a burst of flavor that elevates any meal.

        Sit back, relax, and let the Paloma transport you to the sun-soaked beaches of Mexico. With every sip, you'll feel the warmth of the sun, the cool breeze, and the spirit of celebration that defines this beloved mocktail.

        Join us at our speakeasy bar to savor the Paloma's authentic taste and embrace the essence of Mexico's rich culture. As you sip on this delightful concoction, let it ignite your senses and leave you with a zestful spirit. Cheers to the Paloma perfection – a mocktail that celebrates the joy of life with every sip!
        `
    },
    {
        title: `Espresso Martini - The Elixir of Elegance and Energy`,
        video: 'pL5aEZOGxp0',
        taste: 'Rich and bold combination of flavors - slightly sweet, with a bold coffee kick and a hint of bitterness.',
        ingredients: 'Vodka, Cafe liquor, Epresso',
        number: 'espresso-martini-cocktail',
        description: 'Originating from the 1980s, the Espresso Martini has stood the test of time, evolving into a timeless classic...',
        body: `
        Step into the world of sophistication and energy with the iconic Espresso Martini – a captivating cocktail that seamlessly blends the boldness of coffee with the smoothness of vodka. As the night unfolds, indulge in this irresistible elixir that delivers a perfect balance of richness and vigor.

        Originating from the 1980s, the Espresso Martini has stood the test of time, evolving into a timeless classic. At our speakeasy bar, we take pride in serving the most exquisite Espresso Martini that pays tribute to its roots while infusing it with our own creative twist.
        
        The heart of this cocktail lies in the velvety espresso shot, carefully brewed to perfection. The bold coffee flavor harmonizes flawlessly with the notes of premium vodka, resulting in a luscious concoction that will awaken your senses and leave you craving for more.
        
        The Espresso Martini is not just a drink – it's an experience. Whether you're starting your evening or seeking a late-night pick-me-up, this cocktail promises an indulgent journey that will leave you feeling both sophisticated and invigorated.
        
        Sip on the Espresso Martini and let its enchanting flavors dance on your palate. Feel the subtle warmth of the vodka complemented by the energizing essence of coffee, creating a symphony of taste that delights the senses.
        
        Join us at our speakeasy bar to savor the artistry of the Espresso Martini, meticulously crafted to perfection by our skilled mixologists. Raise your glass, and let the Espresso Martini ignite your spirit, elevating your night to new heights of elegance and energy. Cheers to the elixir of indulgence and vitality!
        `
    },
    {
        title: 'Ramos Gin Fizz - The Velvet Dream of a Classic New Orleans Delight',
        video: 'WK7_t-AUxko',
        taste: 'A harmonious blend of flavors - sweet, citrusy, and slightly creamy with a touch of bitterness',
        ingredients: 'Gin, Lemon juice, Lime juice, Simple syrup, Cream, White foam, Orange flower water, Vanilla',
        number: 'ramos-gin-fizz-cocktail',
        description: `Invented in the late 19th century by bartender Henry C. Ramos, this iconic libation quickly gained popularity and became a signature drink of New Orleans...`,
        body: `
        Step back in time to the jazz-filled streets of New Orleans and savor the velvety allure of the Ramos Gin Fizz, a classic cocktail that has enchanted cocktail enthusiasts for over a century. Known for its unique preparation and rich history, the Ramos Gin Fizz is a delightful concoction that perfectly balances creamy texture with zesty flavors.

        Invented in the late 19th century by bartender Henry C. Ramos, this iconic libation quickly gained popularity and became a signature drink of New Orleans. Its preparation, a meticulous process that involves vigorous shaking with egg whites and cream, is a labor of love that results in a frothy masterpiece.

        At our speakeasy bar, we take pride in crafting Ramos Gin Fizz with the utmost care and attention to detail. The blending of gin, freshly squeezed lemon and lime juice, cream, egg whites, simple syrup, and orange flower water creates a symphony of tastes that dance harmoniously on your palate.

        As you take your first sip, you'll be transported to a bygone era, where jazz melodies fill the air and the spirit of celebration reigns. The Ramos Gin Fizz's smooth, frothy texture and invigorating citrus notes make it a perfect choice for leisurely evenings and social gatherings.

        One of the delights of the Ramos Gin Fizz is the time-honored tradition of "passing the shake." To achieve the perfect consistency, bartenders would shake the cocktail for an extended period, sometimes passing it to another bartender to continue the shaking. This ritual not only ensures a frothy texture but also infuses the drink with a sense of camaraderie.

        At our rooftop bar, we invite you to experience the charm and elegance of the Ramos Gin Fizz. Sip on this timeless classic and immerse yourself in the enchanting ambiance of New Orleans, where every glass is a tribute to the artistry of mixology.

        Join us for a taste of history and let the Ramos Gin Fizz be your gateway to a world of elegance and indulgence. Cheers to the timeless allure of this classic cocktail - where tradition meets innovation, and every sip is a toast to the golden age of mixology.
        `
    },
    {
        title: `Leapfrog - A Daring Leap into Flavors and Creativity`,
        video: 'WK7_t-AUxko',
        taste: 'Tangy, sweet, and slightly bitter with a hint of spiciness, creating a captivating taste adventure.',
        ingredients: 'Gin, Lemon Juice, Apricot liquor, Mint, Sugar, Orange bitter',
        number: 'leapfrog-cocktail',
        image: '/2023/07/citrus-lemonade-lemon-sparkling-water-lime-ice-side-view.jpg',
        imageCredits: '<a href="https://www.freepik.com/free-photo/citrus-lemonade-lemon-sparkling-water-lime-ice-side-view_9407608.htm#query=leapfrog%20cocktail&position=47&from_view=search&track=ais">Image by stockking</a> on Freepik',
        description: `The heart of the Leapfrog lies in its unexpected blend of botanical gin...`,
        body: `
        Prepare to embark on a thrilling journey of taste with the Leapfrog, a daring and imaginative cocktail that will challenge your perception of flavors. This innovative libation is a testament to the artistry of mixology, pushing the boundaries of traditional cocktails with its unexpected combination of ingredients.

        The Leapfrog, crafted with meticulous care and ingenuity, was born out of a desire to elevate the cocktail experience to new heights. At our speakeasy bar, we take great pride in offering this unique and refreshing concoction that exemplifies the spirit of adventure in mixology.

        The heart of the Leapfrog lies in its unexpected blend of botanical gin, herbaceous Chartreuse liqueur, and tangy lime juice. This bold combination is then elevated with a splash of invigorating ginger beer and garnished with a sprig of fragrant mint.

        With every sip, the Leapfrog surprises the palate with a symphony of flavors. The initial herbaceous notes of Chartreuse are balanced by the refreshing zest of lime, while the botanicals in the gin add depth and complexity. The ginger beer adds a lively effervescence that dances on the tongue, leaving you with an unforgettable taste experience.

        The Leapfrog is not just a cocktail; it is an invitation to embrace the unexpected and savor the artistry of mixology. This extraordinary libation challenges conventions and encourages you to take a leap of faith into the realm of creative exploration.

        Join us at our speakeasy bar and immerse yourself in the thrill of the Leapfrog. As you sip on this inventive cocktail, let it inspire you to embrace bold flavors and push the boundaries of your taste adventure.

        Cheers to the Leapfrog - a daring leap into the world of creativity and innovation in mixology. Embrace the artistry and let this unique cocktail be your guide on a journey of bold flavors and delightful surprises.
        `
    },
    {
        title: `Water Lily - An Enchanting Floral Fusion in a Glass`,
        video: 'qp99BfQAfRc',
        taste: 'bright citrus aromas and tastes of gin\'s botanicals and tart orange',
        ingredients: 'Gin, Creme de Violette, Cointreau, Lemon juice',
        number: 'water-lily-cocktail',
        description: `The Water Lily cocktail is a testament to the artistry of mixology, where delicate botanicals and spirits...`,
        body: `
        Embark on a journey to a serene water lily pond with the enchanting Water Lily cocktail, a delightful libation that combines the essence of spring with the allure of a secret garden. This exquisite drink is a celebration of floral flavors, crafted to transport your senses to a world of tranquility and beauty.

        The Water Lily cocktail is a testament to the artistry of mixology, where delicate botanicals and spirits are skillfully combined to create a symphony of taste. At our speakeasy bar, we take great pride in serving this exquisite concoction, ensuring every sip is a delightful escapade.

        The heart of the Water Lily lies in its unique blend of floral components. Crème de violette adds a mesmerizing violet hue and subtle floral notes, while gin provides a botanical base that harmonizes effortlessly. A splash of fresh lemon juice adds a zesty brightness, balancing the sweetness of the crème de violette.

        As you savor the Water Lily, you'll feel a sense of serenity washing over you. The delicate floral aroma combined with the smoothness of gin creates an enchanting experience that transports you to a blooming water lily pond, where nature's beauty takes center stage.

        The Water Lily cocktail is a perfect choice for those seeking a refined and elegant libation. Its floral allure makes it a delightful companion for afternoon gatherings, bridal showers, or any occasion where a touch of floral sophistication is desired.

        Join us at our rooftop bar and immerse yourself in the captivating charm of the Water Lily. As you sip on this enchanting floral fusion, let it transport you to a world of timeless beauty and tranquility.

        Cheers to the Water Lily - a cocktail that celebrates the delicate beauty of nature and the art of mixology. Embrace the floral enchantment, and let this delightful drink be your ticket to a serene water lily sanctuary in a glass.
        `
    },
    {
        title: `Last Chance - Embrace the Boldness of a Final Libation`,
        taste: 'Last Chance is enjoyable very strong cocktail a delightful balance of fruity sweetness and zesty citrus notes ',
        ingredients: 'Tequila, Apricot brandy, Honey, Lime juice',
        image: '/2023/08/intro-1677078589.webp',
        imageCredits: 'Alp Aksoy/Shutterstock',
        number: 'last-chance-cocktail',
        description: `The Last Chance cocktail, aptly named, is a celebration of boldness and creativity...`,
        body: `
            <h3>Introduction:</h3>
            Step into a world of unparalleled flavors with our signature cocktail, Last Chance. Crafted to perfection, this exquisite concoction combines the vibrant notes of Tequila, the aromatic touch of apricot brandy, the natural sweetness of honey, and the zing of fresh lime juice. Let's delve deeper into the essence of Last Chance.

            <h3>History and Origin:</h3>
            Last Chance cocktail traces its roots back to the golden era of mixology, where innovation and creativity led to the birth of some of the most iconic drinks. This cocktail, however, is a modern interpretation that pays homage to the classics while offering a contemporary twist. The combination of Tequila, apricot brandy, honey, and lime juice creates a harmonious symphony of flavors that dances on the palate.

            <h3>Allure and Appeal:</h3>
            The allure of Last Chance lies in its seamless blending of contrasting flavors. The robust character of Tequila is softened by the delicate sweetness of apricot brandy and honey, while the tangy lime juice adds a refreshing kick. This unique medley presents a drink that is both sophisticated and approachable, making it a perfect choice for those seeking an adventure in taste.

            <h3>Serving and Savoring:</h3>
            To fully relish the complexity of Last Chance, it's recommended to savor it slowly, allowing the flavors to unfold with each sip. Served in a chilled cocktail glass, the drink's inviting aroma enhances the overall experience. The interplay of Tequila and apricot brandy, complemented by the honey and lime, creates a layered profile that evolves as the drink is enjoyed.

            <h3>For the Adventurous Palate:</h3>
            Last Chance is an invitation to those who seek the extraordinary. It's the ideal choice for cocktail enthusiasts looking to explore the intricate nuances of well-balanced flavors. Whether you're new to cocktails or a seasoned aficionado, this drink promises to captivate your senses and leave a lasting impression.

            <h3>Visit Us and Experience Last Chance:</h3>
            At Nectar Hideaway, we take pride in curating memorable experiences through our diverse selection of expertly crafted cocktails. Indulge in the art of mixology and discover the magic of Last Chance at our bar. Let our skilled bartenders guide you through a journey of flavors that will tantalize your taste buds and make your visit truly exceptional. Join us and raise your glass to the captivating world of Last Chance!
        `
    },
    {
        title: `Whisky Sour - The Timeless Elegance of a Classic Cocktail`,
        video: 'OxkF7NIFyX0',
        taste: 'Whiskey Sour will have a sour taste and  you’ll notice hints of vanilla and caramel in harmony with the sourness',
        image: '/2023/08/intro-1643303265.webp',
        imageCredits: 'Andrei Iakhniuk/Shutterstock',
        ingredients: 'Bourbon, Lime juice, Simple syrup, White foam (optional)',
        number: 'whiskey-sour-cocktail',
        description: 'Its smooth, velvety texture combined with the invigorating tang of citrus creates a symphony of taste that dances on your palate...',
        body: `
            <h3>Introduction:</h3>
            Introducing the classic cocktail that has stood the test of time - the Whiskey Sours. Crafted with the finest Bourbon, zesty lime juice, and a touch of simple syrup, this cocktail exemplifies the art of balance and flavor. Join us in exploring the rich history and exquisite taste of the Whiskey Sours.

            <h3>History and Origin:</h3>
            The origins of the Whiskey Sours can be traced back to the 19th century, where it emerged as a refined concoction of whiskey, citrus, and sweetness. Its popularity soared as it graced the menus of bars and lounges, offering a refreshing yet robust drink for patrons to enjoy. Over the years, variations have evolved, but the core components of Bourbon, lime juice, and simple syrup have remained unchanged.

            <h3>Allure and Appeal:</h3>
            The allure of the Whiskey Sours lies in its timeless appeal and exquisite flavor profile. The robust notes of Bourbon are beautifully complemented by the tangy brightness of fresh lime juice. The addition of simple syrup balances the drink, creating a harmonious interplay of sweet and sour that dances on the palate. For those seeking an extra touch of elegance, the optional white foam adds a delightful visual and textural element.

            <h3>Serving and Savoring:</h3>
            Serving a Whiskey Sours is an art that enhances the entire experience. It is traditionally served in a chilled glass, allowing the complex aromas to tantalize the senses. The first sip unveils the layers of flavor - the warmth of Bourbon, the zing of lime, and the sweetness of syrup. Enjoy it over ice to maintain its refreshing nature, or strain it for a sleeker presentation.

            <h3>For the Enthusiasts and Novices:</h3>
            The Whiskey Sours holds a special place for both seasoned whiskey enthusiasts and those new to the world of cocktails. Its approachable profile makes it an excellent choice for those looking to explore the world of Bourbon-based drinks. Its versatility also appeals to those who appreciate the finer nuances of a well-crafted cocktail.

            <h3>Visit Us and Experience Whiskey Sours:</h3>
            At Nectar Hideaway, we invite you to journey through the heritage and flavors of the iconic Whiskey Sours.  Indulge in this time-honored concoction meticulously prepared by our skilled mixologists.  Whether you're an avid whiskey aficionado or someone seeking a classic cocktail, Whiskey Sours promises to elevate your senses and offer a taste of history in every sip.
            Join us and raise your glass to the timeless allure of Whiskey Sours!
        `
    },
    {
        title: 'Daiquiri - A Refreshing Cuban Delight for Every Occasion',
        video: '6wau5si-RVI',
        taste: 'Nice balance of sweet and sour',
        ingredients: 'White rum, Lime juice, Syrup',
        image: '/2023/08/daiquiri-1643303265.webp',
        imageCredits: 'etorres/Shutterstock',
        number: 'daiquiri-cocktail',
        description: 'The simplicity of the Daiquiri is its allure. Its three core ingredients - rum, lime, and sugar ...',
        body: `
            <h3>Introduction:</h3>
            Crafted with the finest white rum, vibrant lime juice, and a touch of syrup, this concoction is a masterpiece of balance and taste.

            <h3>History and Origin:</h3>
            The Daiquiri cocktail takes its name from a beach in Cuba, where this refreshing libation first gained popularity.  It emerged during the early 20th century as a result of resourceful innovation.  Combining the sweetness of sugar, the tang of lime, and the smoothness of white rum, this cocktail quickly captured the hearts of enthusiasts and bartenders alike.  Over time, it has evolved into an enduring classic.

            <h3>Allure and Appeal:</h3>
            The allure of the Daiquiri lies in its simplicity and ability to showcase the quality of its ingredients.  The bright and zesty lime juice is expertly balanced with the clean and crisp notes of white rum.  The addition of syrup adds a gentle sweetness that harmonizes the flavors, resulting in a drink that is both revitalizing and indulgent.

            <h3>Serving and Savoring:</h3>
            Serving a Daiquiri is a celebration of its essence.  Chilled and elegantly poured into a cocktail glass, this libation invites you to experience a moment of pure refreshment.  The first sip unveils the invigorating combination of citrusy lime and the subtly complex character of white rum.  The balanced composition ensures a delightful and memorable experience with every sip.

            <h3>For the Enthusiasts and Adventurers:</h3>
            The Daiquiri is a testament to the beauty of simplicity and craftsmanship.  Its light and refreshing nature appeals to both those who appreciate classic cocktails and those seeking an introduction to the world of mixology.  For rum enthusiasts, it offers a chance to enjoy the pure essence of this beloved spirit in a pristine form.

            <h3>Visit Us and Experience Daiquiri:</h3>
            At Nectar Hideaway, we invite you to embark on a journey of flavor and sophistication through the world of Daiquiri.  Indulge in the harmonious marriage of zesty lime juice and exquisite white rum, expertly prepared by our skilled mixologists.  Whether you're a seasoned cocktail connoisseur or a curious explorer, the Daiquiri promises an experience that transcends time and tastes.
            Join us in raising a glass to the timeless allure of the "Daiquiri"!
        `
    },
    {
        title: 'Basilinia - A Fusion of Freshness and Herbal Delights in a Glass',
        taste: 'Sweet, lighter flavor from its floral components (like elderflower)',
        ingredients: 'Bianco vermouth, Basil leaves, Lime juice, Sugar',
        number: 'basilinia-cocktail',
        description: 'The heart of the Basilinia lies in the harmonious blend of botanical gin, zesty lime juice, and the fragrant essence...',
        body: `
            <h3>Introduction:</h3>
            Step into the world of exquisite flavors with our signature cocktail, the "Basilinia." Crafted to perfection, this captivating concoction combines the richness of Bianco vermouth, the freshness of basil leaves, the zing of lime juice, and just the right touch of sugar. Join us at our bar to savor this enchanting blend of tastes and aromas.

            <h3>1. The Distinctive Flavors of Basilinia:</h3>
            Indulge in the symphony of flavors that Basilinia offers. This exquisite cocktail strikes a harmonious balance between its key components, resulting in a delightful experience for your taste buds. With a touch of sweetness from the Bianco vermouth and sugar, it's perfectly complemented by the herbal notes of fresh basil leaves. The tangy kick of lime juice adds a refreshing twist, making Basilinia an expertly crafted cocktail that dances between sweet, herbal, and citrusy flavors.

            <h3>2. Embark on a Journey with Basilinia:</h3>
            <h4>History and Origin:</h4>
            Basilinia, a contemporary classic, finds its roots in the art of mixology. Inspired by traditional vermouth-based cocktails, this innovative blend was born from the desire to create a drink that captures the essence of both Italian heritage and modern sophistication. The marriage of vermouth with basil, lime, and sugar resulted in the unique Basilinia, which pays homage to the past while embracing the present.

            <h4>The Allure of Basilinia:</h4>
            Basilinia holds a magnetic charm that draws in cocktail enthusiasts from all walks of life. Its carefully curated ingredients blend seamlessly to offer a drink that's not just a beverage, but an experience. The aromatic basil leaves infuse the cocktail with a fresh, herbaceous aroma, inviting you to take that first sip and immerse yourself in its complex profile. Whether you're a seasoned cocktail connoisseur or a curious newcomer, Basilinia promises to captivate your senses.

            <h4>Serving and Enjoying:</h4>
            Savoring a Basilinia cocktail is an experience to relish. The presentation itself is a work of art, with the vibrant green of the basil leaves contrasting beautifully against the pale gold of the cocktail. Served over ice in an elegant glass, the Basilinia is best enjoyed with a leisurely pace, allowing the flavors to evolve as the ice melts and mingles with the liquid. The garnish of a basil sprig adds a final touch of sophistication.

            <h4>For Whom is Basilinia:</h4>
            Basilinia is the embodiment of versatility, making it an ideal choice for a diverse range of individuals. Its well-rounded flavor profile appeals to those who appreciate the complexity of taste, while its refreshing nature makes it an excellent option for those seeking a light and invigorating beverage. Whether you're celebrating a special occasion, engaging in a casual evening with friends, or simply looking to elevate your palate, Basilinia is the perfect companion.

            <h3>Conclusion:</h3>
            Embark on a sensory journey with Basilinia, where the rich history of mixology meets contemporary innovation. Indulge in its harmonious flavors, appreciate its aromatic allure, and savor every moment as you explore the world of this exceptional cocktail. Join us at our bar to witness the artistry of crafting a Basilinia cocktail and treat yourself to an unforgettable experience that tantalizes your taste buds and delights your senses.
        `
    },
    {
        title: 'Jäger Bomb - The Explosive Fusion of Energy and Spirits',
        taste: 'Jägermeister is a mix of sweet and slightly bitter tastes with a strong flavor',
        ingredients: 'Jaggermeister, Redbull',
        number: 'jagger-bomb-cocktail',
        description: 'The Jäger Bomb is a party favorite, beloved for its ability to keep the night going and the energy flowing',
        body: `
            <h3>Introduction:</h3>
            Step into a world of electrifying flavors with our signature cocktail, the "Jäger Bomb." Crafted to invigorate your senses, this dynamic concoction combines the herbal intensity of Jägermeister with the energizing kick of Red Bull. Join us at our bar to experience the pulsating synergy of taste and energy that the Jäger Bomb offers.

            <h3>1. The Distinctive Flavors of Jäger Bomb:</h3>
            Prepare to be electrified by the Jäger Bomb's intense profile. A harmonious collision of flavors awaits as the bold and herbal notes of Jägermeister blend seamlessly with the vibrant energy of Red Bull. The bitterness of Jägermeister intertwines with the sweet and tangy undertones of Red Bull, creating a captivating fusion that's simultaneously bitter, sweet, and invigorating.

            <h3>2. Exploring the Jäger Bomb:</h3>
            <h4>History and Origin:</h4>
            The Jäger Bomb, a popular and energetic cocktail, emerged from the late-night scenes of modern bars and clubs. Born from the desire to infuse a new level of excitement into the world of cocktails, the Jäger Bomb marries the renowned herbal liqueur Jägermeister with the energy-boosting Red Bull. The result is a synergy that ignites both the taste buds and the spirits of those who indulge in it.

            <h4>The Allure of Jäger Bomb:</h4>
            The Jäger Bomb's allure lies in its dual nature – it's not just a cocktail, but a ritualistic experience. The careful pouring of Jägermeister into a shot glass, followed by the dramatic drop of the shot glass into a glass of Red Bull, creates a visual spectacle that draws attention. The cocktail's unique blend of flavors and the immediate burst of energy from the caffeine-infused Red Bull make it a favorite choice among those seeking a dynamic and invigorating drink.

            <h4>Serving and Enjoying:</h4>
            Serving and enjoying a Jäger Bomb is an art in itself. The ritualistic preparation adds an element of excitement and anticipation to the experience. To savor a Jäger Bomb, simply drop the shot glass of Jägermeister into a glass of Red Bull and drink the mixture immediately, allowing the flavors to blend harmoniously on your palate. The effervescence of Red Bull enhances the herbal complexity of Jägermeister, creating a truly unique and memorable taste.

            <h4>For Whom is Jäger Bomb:</h4>
            The Jäger Bomb caters to those seeking an energetic and bold experience. It's a favorite among partygoers, night owls, and those looking for a burst of energy along with their cocktail. The combination of Jägermeister and Red Bull offers a well-balanced blend that appeals to those who appreciate a hint of bitterness along with the invigorating effects of caffeine.

            <h3>Conclusion:</h3>
            Elevate your cocktail experience with the Jäger Bomb, a captivating fusion of flavors and energy that's sure to invigorate your senses. Join us at our bar to witness the mesmerizing ritual of creating and savoring a Jäger Bomb, and indulge in the dynamic symphony of tastes that this cocktail has to offer. Whether you're looking to ignite your night out or simply want to explore a thrilling new beverage, the Jäger Bomb awaits, promising an electrifying journey for your taste buds and spirit.
        `
    },
    {
        title: `Culture - A Captivating Blend of Vodka and Exotic Flavors`,
        taste: 'Cocktail Culture was inspired by the yeasty, vanilla notes, creamy yoghurt',
        ingredients: 'Vodka, Yoghurt , Passionfruit Puree, Vanilla',
        number: 'culture-cocktail',
        description: 'The heart of the Culture lies in its innovative combination of vodka, yogurt, passionfruit puree, and vanilla...',
        body: `
            <h3>Introduction:</h3>
            Welcome to a world of refined taste and sophistication with our exquisite cocktail creation, the "Culture." Crafted to tantalize your senses, this exceptional blend harmonizes the smoothness of vodka, the creaminess of yogurt, the exotic allure of passionfruit puree, and the warmth of vanilla. Join us at our bar to experience the symphony of flavors that "Culture" has to offer.

            <h3>1. The Distinctive Flavors of Culture:</h3>
            Embark on a sensory journey with "Culture" cocktail. The delicate balance of flavors unfolds as the creamy yogurt embraces the vodka's subtle bite. The tangy notes of passionfruit puree dance alongside the mellow sweetness of vanilla, resulting in a harmonious composition that is simultaneously tangy, creamy, and subtly sweet.

            <h3>2. Exploring the Culture Cocktail:</h3>
            <h4>History and Origin:</h4>
            The "Culture" cocktail is a testament to the art of mixology, blending unique ingredients to create a masterpiece. Inspired by the idea of marrying diverse flavors, this cocktail represents the fusion of modern mixology and culinary innovation. The juxtaposition of ingredients reflects a world of cultural exchange, where each element contributes to the symphony of taste.

            <h4>The Allure of Culture:</h4>
            "Culture" cocktail exudes an irresistible allure that captures the essence of sophistication and novelty. The tantalizing aroma of vanilla and passionfruit, combined with the velvety texture of yogurt, is a sensory invitation. The cocktail's appeal lies not only in its taste but also in the stories it tells through its ingredients, offering a journey of flavors that traverse continents.

            <h4>Serving and Enjoying:</h4>
            Presenting the "Culture" cocktail is an experience in itself. Served in an elegant glass, the cocktail's presentation is a reflection of the care put into crafting it. The frothy top and the soft hue of the mixture create an inviting visual. To enjoy, take measured sips to fully appreciate the layers of flavors. Allow the drink to evolve as it warms slightly, revealing new facets of its profile.

            <h4>For Whom is Culture:</h4>
            The "Culture" cocktail is a tribute to those who relish the fusion of diverse tastes and a refined palate. It appeals to connoisseurs seeking a drink that marries elegance and innovation. Whether you're a seasoned cocktail enthusiast or a curious newcomer, "Culture" promises an adventure that engages your senses and leaves a lasting impression.

            <h3>Conclusion:</h3>
            Elevate your cocktail experience with the "Culture" cocktail, a masterpiece that harmonizes flavors from around the world. Join us at our bar to indulge in this symphony of taste, where vodka, yogurt, passionfruit puree, and vanilla unite to create an exquisite blend that transcends boundaries. Whether you seek an evening of refined conversation or a journey of palate exploration, "Culture" invites you to savor a drink that celebrates the artistry of mixology and the richness of flavor diversity.
        `
    },
    {
        title: `Moscow Mule - A Refreshing Classic`,
        taste: 'A zesty and refreshing delight. Tangy lime, smooth vodka, and spicy ginger beer harmonize for a perfectly balanced, invigorating taste',
        ingredients: 'Vodka, Lime juice, Ginger beer',
        number: `moscow-mule-cocktail`,
        description: `The Moscow Mule is a timeless cocktail that has stood the test of time. This iconic drink is renowned for its refreshing taste and unique presentation in a copper mug`,
        body: `
            <h3>Introduction:</h3>
            Step into an era of classic elegance with our iconic cocktail, the "Moscow Mule." Crafted to perfection, this timeless concoction unites the boldness of vodka, the zing of lime juice, and the effervescence of ginger beer. Join us at our bar to experience the allure of the Moscow Mule, where tradition meets innovation in a single glass.

            <h3>1. The Distinctive Flavors of Moscow Mule:</h3>
            Experience the Moscow Mule's distinctive flavor profile. The initial bite of vodka is softened by the tangy notes of fresh lime juice. The ginger beer adds a delightful effervescence, creating a dance of textures on the palate. The cocktail strikes a harmonious balance, offering a refreshing and slightly spicy sensation with a hint of sweetness from the ginger beer.

            <h3>2. Exploring the Moscow Mule Cocktail:</h3>
            <h4>History and Origin:</h4>
            The Moscow Mule, a legendary cocktail, traces its origins back to the 1940s. Created as a collaborative effort between a vodka distributor, a ginger beer maker, and a copper mug manufacturer, this cocktail was conceived as a way to introduce vodka to the American market. The iconic copper mug quickly became synonymous with the Moscow Mule's presentation.

            <h4>The Allure of Moscow Mule:</h4>
            The Moscow Mule's charm lies in its simplicity and timeless appeal. The copper mug not only adds visual allure but also enhances the drink's temperature, making it the perfect choice for cooling down on a warm day. The cocktail's history and the refreshing blend of ingredients make it a symbol of both nostalgia and sophistication.

            <h4>Serving and Enjoying:</h4>
            Serving a Moscow Mule is a visual delight. The copper mug, filled with ice and garnished with a lime wedge, exudes a sense of authenticity. The cocktail is best enjoyed straight from the copper vessel, allowing the metal to chill the drink to perfection. Sip slowly to appreciate the gradual melding of flavors as the ice melts.

            <h4>For Whom is Moscow Mule:</h4>
            The Moscow Mule is a versatile drink that appeals to a wide audience. Its balanced flavor profile and refreshing nature make it a favorite choice for those seeking a lighter yet flavorful cocktail. It's perfect for casual gatherings, relaxed evenings, or celebratory toasts, catering to both seasoned cocktail enthusiasts and newcomers alike.

            <h3>Conclusion:</h3>
            Embark on a journey through time with the Moscow Mule cocktail, an emblem of tradition and elegance. Join us at our bar to savor this timeless blend of vodka, lime juice, and ginger beer, served in the iconic copper mug. Whether you're a history enthusiast, a cocktail aficionado, or simply looking to experience a classic with a modern twist, the Moscow Mule promises a refined and refreshing taste that captures the spirit of a bygone era.
        `,
    },
    {
        title: `Wasabi Martini: A Fiery Fusion of Flavors`,
        taste: 'A bold and spicy cocktail with a fiery kick of wasabi, balanced by a tangy hint of lime and a touch of sweetness',
        ingredients: 'Vodka, Wasabi, Lime, Sugar',
        number: `wasabi-martini-cocktail`,
        description: `The key components of the Wasabi Martini are vodka, wasabi, lime, and sugar. Each ingredient plays a crucial role in shaping the overall flavor profile of this cocktail.`,
        body: `
            The Wasabi Martini is a bold and adventurous cocktail that packs a punch of heat and excitement. This unique concoction combines the smoothness of vodka, the kick of wasabi, the tanginess of lime, and a touch of sweetness from sugar, creating an unforgettable taste experience.

            <h3>Origins and Inspiration</h3>
            The Wasabi Martini is a modern twist on the classic martini, infused with the vibrant and spicy flavors of wasabi. The inspiration behind this innovative drink comes from the desire to explore new taste sensations and push the boundaries of mixology. The combination of traditional ingredients with the unexpected addition of wasabi makes for a captivating and memorable cocktail.

            <h3>The Ingredients</h3>
            The key components of the Wasabi Martini are vodka, wasabi, lime, and sugar. Each ingredient plays a crucial role in shaping the overall flavor profile of this cocktail.

            Vodka: As the base spirit, vodka provides a smooth and neutral foundation for the other ingredients. Its subtle taste allows the bold flavors of wasabi and lime to shine through.

            Wasabi: The star of the show, wasabi brings a powerful and pungent heat to the cocktail. Derived from the Japanese horseradish plant, wasabi adds an electrifying kick that sets this martini apart from its traditional counterparts.

            Lime: The tartness of lime complements the spiciness of the wasabi, creating a harmonious balance of flavors. The citrusy notes add a refreshing twist to the cocktail, enhancing its overall complexity.

            Sugar: A touch of sweetness is added to counterbalance the intense heat of the wasabi. The sugar not only rounds out the flavors but also provides a pleasant contrast to the spiciness, making the cocktail more approachable.

            <h3>Mixing the Perfect Wasabi Martini</h3>
            Crafting the perfect Wasabi Martini requires precision and finesse. Here's a step-by-step guide to concocting this tantalizing drink:

            Chill a martini glass by filling it with ice and cold water while preparing the cocktail.

            In a cocktail shaker filled with ice, combine 2 oz of vodka, 1/2 teaspoon of fresh grated wasabi (adjust to taste), the juice of half a lime, and 1/2 oz of simple syrup (made by dissolving sugar in equal parts hot water).

            Shake the mixture vigorously to ensure all ingredients are well combined and the drink is ice-cold.

            Discard the ice and water from the martini glass, then strain the cocktail into the chilled glass.

            Garnish with a lime twist or a small dollop of wasabi on a cocktail pick for an extra touch of elegance and spiciness.

            <h3>The Taste Experience</h3>
            The first sip of the Wasabi Martini is a revelation for the taste buds. The initial coolness of the vodka is quickly overtaken by the fiery explosion of wasabi, leaving a tingling sensation on the palate. The lime's citrusy brightness adds a refreshing contrast, while the subtle sweetness from the sugar provides a smooth finish. The result is a complex and invigorating cocktail that is sure to leave a lasting impression.

            <h3>Perfect Pairings</h3>
            The Wasabi Martini pairs exceptionally well with a variety of dishes, especially those with Asian or fusion influences. Its bold flavors make it an excellent accompaniment to sushi, sashimi, tempura, or any seafood-based dish. The spiciness of the wasabi complements the richness of meat dishes, such as grilled steak or teriyaki chicken. For a lighter option, the cocktail pairs beautifully with salads and vegetable-based dishes, enhancing their flavors with its vibrant taste.

            <h3>Conclusion</h3>
            The Wasabi Martini is a thrilling and adventurous cocktail that takes the classic martini to new heights. With its daring combination of vodka, wasabi, lime, and sugar, this drink offers a tantalizing taste experience that will captivate and excite the senses. Whether you're a seasoned cocktail enthusiast or a curious novice, the Wasabi Martini is a must-try for those seeking an unforgettable journey into the world of mixology. Cheers to the fiery fusion of flavors!
        `,
    },
    {
        title: `Sea Breeze Cocktail - A Refreshing Ocean Breeze in Your Glass`,
        taste: 'A tangy and refreshing mix of vodka, cranberry juice, and grapefruit juice. Balanced sweet-tart flavors with a touch of citrusy zest, no spiciness',
        ingredients: 'Vodka, Cranberry juice, Grapefruit juice',
        number: `sea-breeze-cocktail`,
        description: `Originating in the 1920s, the Sea Breeze has since become a classic cocktail enjoyed by many around the world. Its simple yet flavorful ingredients make it a popular choice a...`,
        body: `
        The Sea Breeze cocktail is a delightful and refreshing drink that evokes the feeling of a gentle ocean breeze on a hot summer day. With its combination of vodka, cranberry juice, and grapefruit juice, this cocktail offers a perfect balance of sweet and tart flavors that tantalize the taste buds.

        Originating in the 1920s, the Sea Breeze has since become a classic cocktail enjoyed by many around the world. Its simple yet flavorful ingredients make it a popular choice at beachside bars, pool parties, and cocktail lounges. Whether you're sipping it by the shore or at home, the Sea Breeze is sure to transport you to a tropical paradise with every sip.

        The key to a perfect Sea Breeze lies in the quality of its ingredients. Start with a smooth and clean vodka as the base, and add in freshly squeezed cranberry juice for a natural sweetness. To balance the sweetness, the addition of grapefruit juice adds a tangy twist that complements the overall taste.

        The Sea Breeze cocktail is incredibly versatile, allowing you to customize the flavors to suit your preferences. You can experiment with different ratios of the three ingredients to find the perfect balance that suits your taste buds. Some prefer a sweeter version, while others enjoy a more tart and tangy profile.

        To make this delightful concoction, simply fill a shaker with ice and add the desired amount of vodka, cranberry juice, and grapefruit juice. Shake well until chilled, then strain the mixture into a chilled glass filled with fresh ice. Garnish with a slice of lime or a wedge of grapefruit to enhance the visual appeal of the cocktail.

        One of the most appealing aspects of the Sea Breeze cocktail is its vibrant and inviting appearance. The blend of crimson cranberry juice and pale pink grapefruit juice creates a stunning ombre effect that is sure to catch the eye of anyone nearby. This makes it a perfect choice for cocktail parties and gatherings, where presentation plays a significant role in enhancing the overall experience.

        Beyond its delightful taste and alluring appearance, the Sea Breeze cocktail offers a light and refreshing option for those seeking a less potent drink. With its relatively low alcohol content, it allows you to enjoy the flavors without feeling overwhelmed. It's the ideal choice for those who wish to savor a cocktail without the heavy effects of stronger spirits.

        The Sea Breeze cocktail is not only a treat for the taste buds but also a healthier choice compared to many other alcoholic beverages. Cranberry juice is known for its potential health benefits, as it is a good source of antioxidants and may help support urinary tract health. When combined with grapefruit juice, which is rich in vitamins and minerals, this cocktail becomes a delightful and nutritious choice.

        In conclusion, the Sea Breeze cocktail is a refreshing and delightful drink that embodies the essence of a cool ocean breeze. Its simplicity in preparation and balance of flavors make it a favorite for many cocktail enthusiasts. So, the next time you're in the mood for a beach-inspired libation, reach for a Sea Breeze, sit back, and enjoy the taste of paradise in your glass. Cheers!
        `,
    },
    {
        title: `Vodkatini: The Perfect Martini with a Vodka Twist`,
        taste: `A smooth and sophisticated blend of vodka and dry vermouth, delivering a crisp and slightly dry taste, with a subtle hint of bitterness.`,
        ingredients: `Vodka, Dry vermouth`,
        number: 'vodkatini-cocktail',
        description: 'The foundation of a perfect Vodkatini lies in the choice of ingredients. Quality vodka and dry vermouth are essential for achieving the ideal balance of flavors',
        body: `
        <h3>Introduction:</h3>
        The Vodkatini, also known as the Vodka Martini, is a classic and elegant cocktail that has stood the test of time. Its simplicity lies in the two key ingredients: vodka and dry vermouth. This cocktail exudes sophistication and appeals to both vodka enthusiasts and martini lovers. In this article, we will delve into the history, ingredients, preparation, and variations of the Vodkatini, inviting readers to our bar to experience this iconic drink firsthand.

        <h3>A Brief History:</h3>
        The origin of the Vodkatini can be traced back to the early 20th century when the Martini, made with gin, was reigning supreme. As vodka gained popularity in the United States during the 1950s and 1960s, bartenders began to experiment with vodka-based variations of the classic Martini. The Vodkatini quickly became a hit, especially among those who preferred the smoother and more neutral taste of vodka.

        <h3>The Ingredients:</h3>
        The foundation of a perfect Vodkatini lies in the choice of ingredients. Quality vodka and dry vermouth are essential for achieving the ideal balance of flavors. While some prefer a 2:1 ratio of vodka to vermouth, others opt for a drier martini with a splash of vermouth. The choice is ultimately a matter of personal taste, allowing bartenders to cater to the preferences of discerning patrons.

        <h3>The Art of Preparation:</h3>
        Creating a Vodkatini is an art form that demands precision and attention to detail. Begin by chilling the martini glass, either by filling it with ice or placing it in the freezer. In a mixing glass filled with ice, combine the desired amount of vodka and dry vermouth. Stir gently, avoiding over-dilution, until the mixture is perfectly chilled. Strain the cocktail into the prepared martini glass and garnish with a lemon twist or olives, depending on the drinker's preference.

        <h3>Variations and Customizations:</h3>
        While the classic Vodkatini is a timeless favorite, mixologists often experiment with a range of exciting variations. From adding a splash of olive brine for a Dirty Vodkatini to infusing the vodka with unique flavors like cucumber or basil, the possibilities are endless. This flexibility allows our bartenders to craft personalized Vodkatinis, tailored to suit the diverse palates of our esteemed guests.

        <h3>Join Us for an Unforgettable Vodkatini Experience:</h3>
        At our bar, we take pride in crafting Vodkatinis that exemplify the perfect balance of taste and refinement. We invite you to step into our sophisticated setting and indulge in the art of savoring a Vodkatini. Our expert bartenders are eager to take you on a journey through the world of exquisite cocktails, offering a memorable and delightful experience that will leave you yearning for more.

        <h3>Conclusion:</h3>
        The Vodkatini is a cocktail that embodies elegance and simplicity, making it a timeless favorite among cocktail enthusiasts. Its smooth and clean taste, combined with a hint of dry vermouth, creates a harmonious flavor profile that is adored by many. We invite you to visit our bar and allow us to serve you an exceptional Vodkatini experience, crafted with passion and attention to detail. Cheers to indulgence and sophistication!
        `
    },
    {
        title: `Vesper: The Iconic Bond Martini`,
        taste: 'A sophisticated balance of flavors. Smooth, bold, and subtly sweet with herbal notes. No spiciness or bitterness',
        ingredients: 'Vodka, Gin, Bianco Vermouth',
        number: 'vesper-cocktail',
        description: `The Vesper, famously known as "The Vesper Martini," is an iconic cocktail that gained popularity after its mention in Ian Fleming's first James Bond novel`,
        body: `
            The Vesper, famously known as "The Vesper Martini," is an iconic cocktail that gained popularity after its mention in Ian Fleming's first James Bond novel, "Casino Royale." Named after the character Vesper Lynd, this cocktail has become a symbol of sophistication and mystery, much like the renowned secret agent himself.

            <h3>A Perfect Balance of Spirits</h3>
            
            The Vesper is a unique blend of three powerful spirits - vodka, gin, and bianco vermouth, resulting in a cocktail that perfectly balances strength and elegance. The combination of vodka and gin brings a smooth and complex taste to the drink, while the bianco vermouth adds a subtle touch of herbal sweetness.
            
            <h3>Sophisticated, Yet Bold</h3>
            The Vesper's taste can be described as a harmonious fusion of the three spirits. The vodka provides a clean and neutral base, allowing the botanicals of the gin and the herbal notes of the bianco vermouth to shine through. The result is a drink that is both bold and sophisticated, with a delightful interplay of flavors.
            
            <h3>Visit our Bar for an Authentic Vesper Experience</h3>
            If you're a fan of James Bond or simply looking for a remarkable cocktail experience, we invite you to our bar to indulge in the legendary Vesper. Our skilled bartenders take great care in crafting each Vesper to perfection, ensuring you savor every sip of this timeless classic.
            
            <h3>Discover the Magic of the Vesper</h3>
            Whether you're seeking an elegant cocktail to impress your friends or an adventure into the world of secret agents, the Vesper promises an unforgettable journey of flavors. Come join us and immerse yourself in the charm and allure of this legendary cocktail at our bar. Our team looks forward to serving you an authentic Vesper experience, leaving you both stirred and satisfied.
        `
    },
    //new form
    {
        title: "Cosmopolitan: Unraveling the Iconic Martini of Elegance and Glamour",
        ingredients: "Vodka, Cranberry juice, Cointreau, Lime juice",
        number: "cosmopolitan-cocktail",
        taste: `a delightful balance of tartness and sweetness, with a zesty lime twist. A tantalizing blend of Vodka, Cranberry juice, Cointreau, and Lime juice`,
        description: `A delightful balance of tartness and sweetness, with a zesty lime twist. A tantalizing blend of Vodka, Cranberry juice, Cointreau, and Lime juice`,
        body: `
            <h3>Introduction:</h3>

            The Cosmopolitan, often referred to as "Cosmo," is a classic cocktail that exudes elegance and sophistication. With its vibrant pink hue and refreshing taste, it has become a symbol of modern cocktail culture. This iconic drink is believed to have originated in the 1970s or 1980s, with disputed claims of its creation between Miami and New York City.
            
            <h3>History and Origin:</h3>
            
            The Cosmopolitan's exact origin remains shrouded in mystery, with several popular stories attributing its creation to different bartenders and locations. The most widely recognized story credits bartender Toby Cecchini of The Odeon restaurant in Manhattan for introducing the Cosmo in the late 1980s, making it an instant hit among the city's social elite.
            
            <h3>Allure and Appeal:</h3>
            
            The Cosmopolitan's allure lies in its striking appearance, striking a perfect balance between tartness and sweetness. The blend of Vodka's smoothness with the tangy notes of Cranberry juice, the orange-flavored sweetness of Cointreau, and the zesty Lime juice creates a mesmerizing symphony of flavors.
            
            <h3>Serving and Savoring:</h3>
            
            Cosmopolitans are traditionally served in a chilled martini glass, garnished with a twist of lime or a flamed orange peel for added flair. To savor this iconic cocktail fully, take a slow sip and let its well-balanced flavors dance on your palate. The cosmopolitan is best enjoyed in the company of friends or as a celebratory drink for special occasions.
            
            <h3>Perfect Pairing:</h3>
            
            The Cosmopolitan's versatility makes it an ideal companion for various occasions and palates. Its bright and refreshing taste complements seafood dishes, especially sushi and shrimp cocktails. Additionally, it pairs remarkably well with light salads, mild cheeses, and desserts with fruity accents.
            
            <h3>Who Will Love It:</h3>
            
            The Cosmopolitan appeals to those seeking a sophisticated and visually appealing cocktail experience. Its allure transcends generations, making it a popular choice among millennials and Gen Z, as well as those who appreciate timeless classics. Whether you're exploring the vibrant nightlife scene or enjoying a chic soirée, the Cosmopolitan remains a staple for those with refined tastes.
            
            Embrace the cosmopolitan lifestyle at our bar, where our talented mixologists craft each cocktail with meticulous attention to detail. Join us for an evening of elegance and indulgence, and let the Cosmopolitan whisk you away on a journey of delightful flavors. Cheers to unforgettable moments! 
        `,
    },
    {
        "title": "Black / White Russian: A Duality of Delight",
        "ingredients": "Vodka, Coffee liqueur, Heavy cream or Milk (optional)",
        "number": "black-white-russian-cocktail",
        "taste": "bold and bitter with Vodka and Coffee liqueur, or opt for the creamy twist of White Russian with Heavy cream or Milk",
        "description": "The Black / White Russian, an iconic duo of cocktails, presents a fascinating duality of flavors to suit different palates",
        "body": `
        
            <h3>Introduction:</h3>

            The Black / White Russian, an iconic duo of cocktails, presents a fascinating duality of flavors to suit different palates. With their simple yet exquisite ingredients, these drinks have captivated cocktail enthusiasts worldwide.

            <h3>History and Origin:</h3>

            The Black Russian is believed to have originated in the late 1940s or early 1950s, attributed to the creation of Gustave Tops, a bartender in Brussels, Belgium. It was later popularized in the United States by a U.S. ambassador who fell in love with the concoction during his visit to Brussels.

            The White Russian, on the other hand, emerged in the 1960s, gaining immense popularity due to its association with the cult classic movie "The Big Lebowski."

            <h3>The Black Russian:</h3>

            The Black Russian boasts a bold and intense flavor profile, combining the smoothness of Vodka with the rich, coffee-infused taste of Coffee liqueur. Its dark allure makes it a favorite among those who prefer stronger and bolder cocktails.

            <h3>The White Russian:</h3>

            The White Russian presents a delightful twist, adding a velvety touch of Heavy cream or Milk to the classic Black Russian. This creamy variation imparts a luscious texture, balancing the intensity of the Vodka and Coffee liqueur.

            <h3>Serving and Savoring:</h3>

            Both cocktails are typically served over ice in an Old Fashioned glass, and can be garnished with a maraschino cherry or a coffee bean for a touch of elegance. The Black Russian is best enjoyed by those who appreciate the essence of strong coffee and vodka, while the White Russian appeals to those who prefer a creamier and smoother experience.

            <h3>Perfect Pairing:</h3>

            The Black / White Russian pairs well with desserts such as chocolate cake, tiramisu, or crème brûlée. For a savory pairing, it complements dishes like grilled meats, smoked salmon, and cheese platters.

            <h3>Who Will Love It:</h3>

            The Black / White Russian caters to diverse tastes, attracting those who cherish the richness of coffee-flavored cocktails and the velvety texture of cream-based drinks. Whether you're a connoisseur of classics or a fan of modern indulgence, these cocktails cater to all, making them a timeless choice for any gathering or celebration.

            Visit our bar, and let our skilled mixologists craft these delightful Russian cocktails, transporting you to a realm of contrasting flavors. Elevate your drinking experience with the allure of the Black / White Russian and embrace the dual delight. Cheers to indulgence!
        `,
    },
    {
        title: "Bee's Knees: A Sweet and Citrusy Elixir",
        ingredients: "Gin, Lemon juice, Honey syrup",
        number: "bees-knees-cocktail",
        taste: "A harmonious blend of Gin, zesty Lemon juice, and luscious Honey syrup, offering a sweet and citrusy elixir",
        description: `The Bee's Knees is all about balance. The botanical notes of Gin intertwine seamlessly with the tartness of freshly squeezed Lemon juice,..`,
        body: `
            <h3>Introduction:</h3>

            Indulge in the Bee's Knees, a classic cocktail that weaves the essence of honey and citrus into a delightful concoction. Crafted with Gin, Lemon juice, and Honey syrup, this cocktail buzzes with flavors.

            <h3>History and Origin:</h3>

            The Bee's Knees emerged during the Prohibition era in the 1920s. It was crafted ingeniously to mask the taste of low-quality spirits during a time when obtaining quality ingredients was challenging. The use of honey as a sweetener elevated the flavor, earning it a spot among the timeless classics.

            <h3>The Perfect Balance:</h3>

            The Bee's Knees is all about balance. The botanical notes of Gin intertwine seamlessly with the tartness of freshly squeezed Lemon juice, while the natural sweetness of Honey syrup complements and softens the overall profile, leaving a lingering, satisfying taste.

            <h3>Crafting the Elixir:</h3>

            The cocktail is skillfully prepared by shaking Gin, Lemon juice, and Honey syrup together with ice until perfectly chilled. The result is a golden-hued elixir that embodies the spirit of the bee's hard work and dedication.

            <h3>Serving and Savoring:</h3>

            Served in a chilled coupe or martini glass, the Bee's Knees is often garnished with a twist of Lemon peel or a Lemon wheel. The enchanting aroma and vibrant hue make it visually enticing, making the drinking experience all the more delightful.

            <h3>A Toast to Nature's Bounty:</h3>

            The Bee's Knees celebrates the harmony between man and nature. By incorporating honey as a key ingredient, this cocktail pays homage to the indispensable role of bees in pollination, fostering a sense of appreciation for the environment.

            <h3>Ideal Occasions:</h3>

            The Bee's Knees is versatile and suits a range of occasions, from intimate gatherings to lively celebrations. Its delightful sweetness and invigorating citrus notes make it an ideal choice for daytime enjoyment, brunches, or to accompany light, fresh dishes.

            <h3>Perfect Pairing:</h3>

            Pair the Bee's Knees with appetizers like shrimp cocktail, bruschetta, or goat cheese crostini. For a delightful twist, pair it with desserts like lemon tart, honey cakes, or fruit salads.

            <h3>Join the Buzz:</h3>

            Visit our bar to experience the enchanting Bee's Knees cocktail, lovingly crafted by our talented mixologists. Let the sweet and citrusy elixir transport your taste buds to a world of blissful harmony. Cheers to the bee's golden gift of nature!
        
        `,
    },
    {
        title: "Corpse Reviver #2: A Revitalizing Elixir",
        ingredients: "Gin, Lemon juice, Cointreau, Lillet Blanc, Absinthe",
        number: "corpse-reviver-2-cocktail",
        taste: `A captivating blend of Gin, tangy Lemon juice, Cointreau, Lillet Blanc, and a hint of Absinthe, reviving the spirits with each sip.`,
        description: `Originally crafted as a "hair of the dog" hangover cure in the early 20th century, the Corpse Reviver #2 quickly transcended its medicinal roots to become a beloved cocktail among mixologists and enthusiasts alike...`,
        body: `
        <h3>Introduction:</h3>

        Embark on a journey of rejuvenation with the Corpse Reviver #2, a classic cocktail that boasts a perfect balance of flavors. This concoction of Gin, Lemon juice, Cointreau, Lillet Blanc, and a touch of Absinthe is an invigorating elixir.
        
        <h3>History and Origin:</h3>
        
        Originally crafted as a "hair of the dog" hangover cure in the early 20th century, the Corpse Reviver #2 quickly transcended its medicinal roots to become a beloved cocktail among mixologists and enthusiasts alike.
        
        <h3>Reviving the Senses:</h3>
        
        The Corpse Reviver #2 delivers an enticing blend of botanical Gin, tangy Lemon juice, and the subtle sweetness of Cointreau, beautifully complemented by the herbal notes of Lillet Blanc and the whisper of Absinthe.
        
        <h3>Crafting the Elixir:</h3>
        
        A meticulous dance of ingredients brings the Corpse Reviver #2 to life. The Gin, Lemon juice, Cointreau, and Lillet Blanc unite in perfect harmony, while a hint of Absinthe imparts an intriguing touch, captivating the palate.
        
        <h3>Serving and Savoring:</h3>
        
        Presented in an elegant coupe or martini glass, the Corpse Reviver #2 is garnished with a twist of Lemon peel. Its inviting aroma and exquisite appearance make it a visually enticing choice, inviting you to indulge.
        
        <h3>The Art of Balance:</h3>
        
        The Corpse Reviver #2 is a testament to the art of mixology. Each element complements the other, creating a seamless symphony of flavors that evokes a sense of revival with every sip.
        
        <h3>Perfect Pairing:</h3>
        
        Pair the Corpse Reviver #2 with light appetizers like smoked salmon, oysters, or shrimp cocktail. Its complex profile also pairs wonderfully with rich, creamy dishes or spiced cuisines.
        
        <h3>An Alluring Elixir:</h3>
        
        As its name suggests, the Corpse Reviver #2 carries a captivating mystique. Sip this enchanting elixir, and let its revitalizing charm sweep you away, inspiring a renewed zest for life.
        
        <h3>Ideal Occasions:</h3>
        
        An ideal choice for brunch gatherings, afternoon soirées, or as a pre-dinner libation, the Corpse Reviver #2 is a versatile cocktail that adds an element of sophistication to any occasion.
        
        <h3>Join the Revival:</h3>
        
        Indulge in the Corpse Reviver #2 at our bar, where our talented mixologists craft this enchanting concoction to perfection. Embrace the revitalizing spirits of this classic cocktail and celebrate life's vibrant moments. Cheers to the revival! 
        `,
    },
    {
        "title": "Bramble: A Captivating Journey Through the Wilderness",
        "ingredients": "Gin, Blackberry liqueur, Simple syrup, Lemon juice",
        "number": "bramble-cocktail",
        "taste": "Blending Gin, luscious Blackberry liqueur, refreshing Simple syrup, and tangy Lemon juice for an enchanting, fruity experience.",
        "description": `Born in the mid-1980s in London, the Bramble quickly gained fame for its vibrant taste and eye-catching presentation. The cocktail embodies the essence of wild blackberries and captures `,
        "body": `
            <h3>Introduction:</h3>

            Step into the enchanting wilderness of flavors with the Bramble cocktail, an alluring concoction that artfully blends Gin, Blackberry liqueur, Simple syrup, and Lemon juice.

            <h3>Exploring the Bramble:</h3>

            Born in the mid-1980s in London, the Bramble quickly gained fame for its vibrant taste and eye-catching presentation. The cocktail embodies the essence of wild blackberries and captures the spirit of adventure.

            <h3>A Symphony of Flavors:</h3>

            The Bramble delights the senses with the delightful fusion of botanical Gin, the rich sweetness of Blackberry liqueur, the subtle sweetness of Simple syrup, and the zesty zing of Lemon juice.

            <h3>Crafting the Wilderness:</h3>

            In a glass over crushed ice, the Bramble is carefully built, allowing the flavors to intertwine gracefully. The vibrant hues of blackberry and lemon evoke a sense of nature's untamed beauty.

            <h3>Aesthetic Garnish:</h3>

            The Bramble is adorned with fresh blackberries and a lemon wheel, presenting a visually stunning drink that beckons you to savor its lusciousness.

            <h3>Sipping the Wild:</h3>

            Take a sip, and you'll be transported to a lush bramble where the tartness of blackberries blends harmoniously with the gin's botanicals, leaving a refreshing, lingering sweetness on your palate.

            <h3>Adventure Awaits:</h3>

            The Bramble is not just a cocktail; it's an exploration of flavors, an escapade into the world of taste that leaves you wanting more.

            <h3>Pairing Possibilities:</h3>

            The Bramble's sweet and tart balance complements savory dishes like roasted pork, grilled chicken, or earthy mushroom risotto, creating a delightful dining experience.

            <h3>The Bramble Soirée:</h3>

            Hosting a soirée? Offer your guests the Bramble, and let them be captivated by its allure. Its versatility and tantalizing taste will make it the star of any gathering.

            <h3>Embrace the Wild Side:</h3>

            Unleash your adventurous spirit and savor the Bramble at our bar. Our skilled mixologists craft this enchanting cocktail with precision, inviting you to embrace the untamed allure of the Bramble. Cheers to the wild journey!
        `,
    },
    {
        "title": "Southside: A Refreshing Urban Escape",
        "ingredients": "Gin, Lime juice, Simple syrup, Mint leaves, Angostura bitter",
        "number": "southside-cocktail",
        "taste": "Blending Gin, zesty Lime juice, refreshing Simple syrup, aromatic Mint leaves, and a dash of Angostura bitter for a revitalizing experience",
        "description": `This classic cocktail has stood the test of time, captivating cocktail enthusiasts with its vibrant flavors.`,
        "body": `
        
            <h3>Introduction:</h3>

            Indulge in a refreshing urban escape with the Southside cocktail, a delightful blend of Gin, zesty Lime juice, Simple syrup, aromatic Mint leaves, and a dash of Angostura bitter.

            <h3>Discovering the Southside:</h3>

            Originally concocted in the prohibition era, the Southside hails from the Southside of Chicago. This classic cocktail has stood the test of time, captivating cocktail enthusiasts with its vibrant flavors.

            <h3>A Symphony of Urban Flavors:</h3>

            The Southside harmoniously blends the juniper notes of Gin, the zing of freshly squeezed Lime juice, the delicate sweetness of Simple syrup, the refreshing burst of Mint leaves, and the subtle depth of Angostura bitter.

            <h3>Mixing Urban Elegance:</h3>

            Crafted in a shaker with ice, the Southside is elegantly strained into a chilled glass, exuding an air of sophistication that perfectly complements its bright, invigorating taste.

            <h3>Garnish with Urban Finesse:</h3>

            A sprig of fresh mint gracefully adorns the Southside, inviting you to savor its aroma and dive into a revitalizing sip.

            <h3>Sip into Urban Bliss:</h3>

            Take a sip, and the Southside will transport you to the bustling streets of the city on a warm summer evening, as the vibrant flavors tantalize your taste buds.

            <h3>Perfect for Urban Soirées:</h3>

            Hosting a chic rooftop soirée? Offer the Southside to your guests, and let them indulge in a taste of urban elegance, mingling and celebrating under the stars.

            <h3>The Urban Pairing:</h3>

            The Southside pairs wonderfully with light seafood dishes, grilled chicken salads, or spicy street tacos, creating an urban dining experience like no other.

            <h3>Crafting Urban Memories:</h3>

            At our bar, our mixologists skillfully prepare the Southside, capturing the essence of the city in a glass. Come, escape to an urban oasis, and savor the Southside cocktail. Cheers to city life!

            <h3>The Urban Affair:</h3>

            For those seeking a refreshing, vibrant, and timeless cocktail, the Southside beckons. Its urban allure and invigorating taste ensure a memorable affair with every sip. Embrace the urban spirit today!
        `,
    },
    {
        "title": "Clover Club: A Whimsical Raspberry Dream",
        video: 'zu0Ot1dMHLc',
        "ingredients": "Gin, Raspberry syrup, Lemon juice, White foam, Dry vermouth (optional)",
        "number": "clover-club-cocktail",
        "taste": "Delightful fusion of Gin, luscious Raspberry syrup, tangy Lemon juice, white foam, and optional Dry vermouth for a captivating experience.",
        "description": "The Clover Club performs a delightful dance of taste, where the botanical notes of Gin are perfectly harmonized with the sweetness of Raspberry syrup and the zing of freshly squeezed Lemon juice",
        "body": `
            <h3>Introduction:</h3>

            Step into a world of whimsy with the Clover Club cocktail, a delightful concoction featuring Gin, luscious Raspberry syrup, tangy Lemon juice, airy white foam, and optional Dry vermouth.

            <h3>Discovering the Clover Club:</h3>

            Dating back to the early 1900s, the Clover Club cocktail emerged as a symbol of camaraderie at the eponymous Philadelphia men's club. Today, it delights cocktail enthusiasts with its charming blend of flavors.

            <h3>A Dance of Flavors:</h3>

            The Clover Club performs a delightful dance of taste, where the botanical notes of Gin are perfectly harmonized with the sweetness of Raspberry syrup and the zing of freshly squeezed Lemon juice.

            <h3>Captivating Aesthetics:</h3>

            Topped with a velvety white foam, the Clover Club showcases a touch of artistry and elegance, inviting you to indulge your senses.

            <h3>Optional Dry Vermouth Twist:</h3>

            For an added layer of complexity, a splash of Dry vermouth is optional, enhancing the cocktail's herbal undertones and elevating the overall experience.

            <h3>The Whimsy in a Glass:</h3>

            Served in a chilled coupe, the Clover Club sparkles with a vibrant pink hue, evoking a sense of playfulness and charm.

            <h3>Sip into Imagination:</h3>

            With each sip, the Clover Club transports you to a fanciful world, where raspberry fields meet citrus groves in a dance of flavors.

            <h3>Perfect for Whimsical Gatherings:</h3>

            Bring a touch of enchantment to your gatherings by serving the Clover Club, a cocktail that embodies the spirit of camaraderie and joy.

            <h3>The Whimsical Pairing:</h3>

            Pair the Clover Club with creamy cheeses, delicate smoked salmon canapés, or raspberry-infused desserts for a delightful culinary journey.

            <h3>Crafting Whimsical Moments:</h3>

            Our skilled mixologists craft the Clover Club with utmost care, ensuring each glass captures the essence of whimsy and delights your palate. Come, embrace the magic of the Clover Club cocktail. Cheers to whimsy!

            <h3>The Whimsical Affair:</h3>

            For those seeking a charming and playful cocktail, the Clover Club promises a whimsical affair. Let its vibrant flavors and dreamy aesthetics transport you to a world of imagination and delight. Unleash the whimsy within!
        `,
    },
    {
        "title": "Gimlet: A Timeless Elixir of Citrus and Gin",
        "ingredients": "Gin, Lime juice, Simple syrup",
        "number": "gimlet-cocktail",
        "taste": "Harmonious blend of Gin, zesty Lime juice, and a touch of Simple syrup, delivering an unforgettable taste experience.",
        "description": `Served in a chilled martini glass, the Gimlet exudes an air of sophistication, making it the perfect choice for refined gatherings`,
        "body": `
        <h3>Introduction:</h3>

            Transport yourself to a bygone era with the Gimlet cocktail, a classic elixir that artfully marries the boldness of Gin with the zesty tang of Lime juice.

            <h3>A Timeless Classic:</h3>

            Originating in the British Navy as a remedy for scurvy, the Gimlet has stood the test of time, captivating cocktail enthusiasts with its simple yet captivating flavor profile.

            <h3>A Perfect Balance:</h3>

            The Gimlet's allure lies in its harmonious balance of botanical Gin notes with the refreshing citrus essence of freshly squeezed Lime juice.

            <h3>An Elixir of Elegance:</h3>

            Served in a chilled martini glass, the Gimlet exudes an air of sophistication, making it the perfect choice for refined gatherings.

            <h3>The Versatility of Simple Syrup:</h3>

            A dash of Simple syrup adds a touch of sweetness, enhancing the cocktail's smoothness and creating a harmonious flavor symphony.

            <h3>An Experience Worth Savoring:</h3>

            The Gimlet tantalizes the taste buds with its invigorating burst of citrus and the soothing embrace of Gin, creating a cocktail experience worth savoring.

            <h3>The Gin Lover's Delight:</h3>

            For Gin enthusiasts, the Gimlet is an essential journey through the heart of this beloved spirit, making it a timeless choice.

            <h3>From Classic to Contemporary:</h3>

            While the classic Gimlet remains timeless, mixologists love to experiment, infusing modern twists with various flavored gins and citrus variations.

            <h3>Crafted to Perfection:</h3>

            At our bar, the Gimlet is crafted with precision, ensuring every drop captures the essence of elegance and unrivaled taste.

            <h3>Raise a Toast to Tradition:</h3>

            Join us and raise a toast to the enduring legacy of the Gimlet, a cocktail that transcends time and brings timeless elegance to your glass. Cheers!
        `,
    },
    {
        "title": "Vieux Mot: An Symphony of Gin and Elderflower",
        "ingredients": "Gin, Elderflower liquor, Lime, Sugar",
        "number": "vieux-mot-cocktail",
        "taste": `Embark on a journey of enchantment with the Vieux Mot cocktail, where Gin intertwines gracefully with Elderflower liquor, Lime, and Sugar for a harmonious and unforgettable experience.`,
        "description": `The Vieux Mot cocktail is a delightful symphony of carefully selected ingredients that come together to create an enchanting and unforgettable taste experience`,
        "body": `
            <h3>Introduction:</h3>

            Discover the alluring harmony of flavors in the Vieux Mot cocktail, where the botanical essence of Gin entwines gracefully with the delicate sweetness of Elderflower liquor.

            <h3>A Captivating Melody:</h3>

            The Vieux Mot cocktail is a delightful symphony of carefully selected ingredients that come together to create an enchanting and unforgettable taste experience.

            <h3>The Elegance of Gin:</h3>

            Gin, the backbone of this concoction, brings its distinctive botanical notes, lending depth and character to every sip.

            <h3>The Allure of Elderflower:</h3>

            The velvety sweetness of Elderflower liquor provides a soft and fragrant touch, beautifully complementing the Gin's boldness.

            <h3>A Touch of Citrus:</h3>

            Freshly squeezed Lime adds a zingy brightness, elevating the cocktail with a burst of citrusy freshness.

            <h3>A Hint of Sweetness:</h3>

            Just a touch of Sugar is artfully added to balance the flavors, creating a well-rounded and harmonious profile.

            <h3>An Unforgettable Experience:</h3>

            Each sip of the Vieux Mot offers a delightful journey through the enchanting flavors, leaving a lasting impression.

            <h3>The Ideal Occasion:</h3>

            Perfect for an elegant evening out or a sophisticated celebration, the Vieux Mot adds a touch of class to any occasion.

            <h3>Expertly Crafted:</h3>

            At our bar, the Vieux Mot is meticulously crafted by skilled mixologists, ensuring a perfect balance of flavors with every pour.

            <h3>Indulge in Elegance:</h3>

            Indulge in the enchanting allure of the Vieux Mot, where the marriage of Gin, Elderflower, Lime, and Sugar creates an exquisite elixir that will captivate your senses. Cheers!
        `,
    },
    {
        "title": "Tuxedo: A Timeless Elixir of Elegance and Intrigue",
        "ingredients": "Gin, Dry vermouth, Maraschino liqueur, Orange bitters, Absinthe rinse",
        "number": "tuxedo-cocktail",
        "taste": "Timeless elixir of elegance and intrigue.",
        "description": "At the heart of the Tuxedo lies Gin, bestowing its botanical essence and lending a dignified character to this classic concoction.",
        "body": `
            <h3>Introduction:</h3>

            Unveil the allure of the Tuxedo cocktail, an exquisite blend that exudes elegance and captivates with its intriguing combination of flavors.

            <h3>The Essence of Gin:</h3>

            At the heart of the Tuxedo lies Gin, bestowing its botanical essence and lending a dignified character to this classic concoction.

            <h3>A Whisper of Dry Vermouth:</h3>

            Dry vermouth contributes a subtle herbal nuance, enhancing the complexity of the cocktail without overpowering its charm.

            <h3>The Sweet Caress of Maraschino:</h3>

            Maraschino liqueur provides a delicate touch of sweetness, adding depth and balance to the Tuxedo's taste.

            <h3>A Burst of Citrus:</h3>

            With just a few drops of Orange bitters, the cocktail is adorned with a delightful burst of citrusy notes.

            <h3>The Enigmatic Absinthe Rinse:</h3>

            The glass is tantalizingly rinsed with Absinthe, leaving a faint allure that piques curiosity with every sip.

            <h3>A Timeless Classic:</h3>

            The Tuxedo is a testament to the elegance of classic cocktails, with a perfect balance of flavors that never goes out of style.

            <h3>An Elixir of Refinement:</h3>

            Crafted to perfection by our skilled mixologists, the Tuxedo is an elixir that encapsulates the essence of refinement.

            <h3>The Epitome of Sophistication:</h3>

            Savor the Tuxedo on your most special occasions, as it epitomizes sophistication and elevates any celebration.

            <h3>An Ode to Timeless Glamour:</h3>

            Embrace the allure of the Tuxedo, an ode to timeless glamour, and immerse yourself in the world of refined taste. Cheers!
        `,
    },
    {
        "title": "Martinez: Unveiling the Essence of Elegance and Richness",
        "ingredients": "Gin, Sweet vermouth, Maraschino liqueur, Orange bitters",
        "number": "martinez-cocktail",
        "taste": "Essence of elegance and richness.",
        "description": "The Martinez embodies timeless elegance, delivering a sophisticated taste that transcends generations.",
        "body": `
            <h3>Introduction:</h3>

            Indulge in the allure of the Martinez cocktail, a timeless blend that encapsulates the very essence of elegance and richness.

            <h3>The Allure of Gin:</h3>

            Gin takes center stage, infusing the Martinez with its botanical allure, setting the tone for an exceptional experience.

            <h3>The Richness of Sweet Vermouth:</h3>

            Sweet vermouth adds a velvety richness, balancing the cocktail with its luscious and aromatic character.

            <h3>Maraschino Liqueur's Subtle Sweetness:</h3>

            Maraschino liqueur brings a touch of subtle sweetness, enhancing the complexity of the Martinez with a delightful twist.

            <h3>A Dash of Orange Bitters:</h3>

            Orange bitters impart a zesty kick, infusing the cocktail with a burst of citrusy notes that dance on the palate.

            <h3>A Sip of Timeless Elegance:</h3>

            The Martinez embodies timeless elegance, delivering a sophisticated taste that transcends generations.

            <h3>A Fusion of Flavors:</h3>

            Marrying the finest ingredients, the Martinez showcases a fusion of flavors that is truly remarkable.

            <h3>Crafted with Precision:</h3>

            Meticulously crafted by our skilled mixologists, the Martinez is a testament to our commitment to excellence.

            <h3>An Icon of Refinement:</h3>

            Embrace the Martinez as an iconic symbol of refinement, perfect for moments of celebration and indulgence.

            <h3>Elevating the Cocktail Experience:</h3>

            Savor the Martinez and elevate your cocktail experience to new heights, sipping on a legacy of taste and sophistication.

            <h3>A Toast to Elegance:</h3>

            Raise your glass to the Martinez, a toast to the epitome of elegance, and savor every exquisite sip. Cheers!
        `,
    },
    {
        "title": "Gin Martini: The Epitome of Timeless Sophistication",
        "ingredients": "Gin, Dry vermouth",
        "number": "gin-martini-cocktail",
        "taste": "Gin and Dry vermouth unite, creating a classic cocktail that defines elegance and refinement",
        "description": "The Gin Martini's refreshing nature makes it a classic aperitif, perfect for stimulating the palate before a memorable meal...",
        "body": `
            <h3>Introduction:</h3>
            Discover the allure of the Gin Martini, an iconic cocktail that has epitomized sophistication for generations.

            <h3>The Finest Gin:</h3>

            Gin, the heart of this classic libation, brings its distinct botanical flavors, lending character to the Gin Martini.

            <h3>Dry Vermouth's Subtle Influence:</h3>

            Dry vermouth adds a touch of elegance, subtly enhancing the Gin Martini with its refined notes.

            <h3>Stirred to Perfection:</h3>

            Masterfully stirred, our Gin Martini embodies a perfect balance of flavors and a silky-smooth texture.

            <h3>A Classic Garnish:</h3>

            An olive or lemon twist serves as the perfect garnish, enhancing the cocktail's visual appeal and taste.

            <h3>The Art of Serving:</h3>

            Served in a chilled martini glass, the Gin Martini exudes an air of sophistication and elegance.

            <h3>The Timeless Ritual:</h3>

            Embrace the timeless ritual of sipping a Gin Martini, a symbol of refinement and appreciation for the finer things.

            <h3>An Iconic Aperitif:</h3>

            The Gin Martini's refreshing nature makes it a classic aperitif, perfect for stimulating the palate before a memorable meal.

            <h3>A Toast to Traditions:</h3>

            Raise your glass to the Gin Martini, a toast to the enduring traditions of the cocktail world.

            <h3>Savoring Elegance:</h3>

            Indulge in the art of savoring elegance with every sip of the Gin Martini, a libation steeped in history and charm. Cheers!
        `,
    },
    {
        "title": "Caipirinha: Embrace the Vibrant Spirit of Brazil",
        "ingredients": "Cachaça, Lime, Sugar",
        "number": "caipirinha-cocktail",
        "taste": "Cachaça, zesty lime, and sugar into a refreshing concoction that captures the essence of a tropical paradise",
        "description": `Embark on a journey to the sun-kissed beaches of Brazil with Caipirinha, a beloved cocktail that encapsulates the nation's zest for life`,
        "body": `
            <h3>Introduction:</h3>

            Embark on a journey to the sun-kissed beaches of Brazil with Caipirinha, a beloved cocktail that encapsulates the nation's zest for life.

            <h3>Cachaça: The Soul of Brazil:</h3>

            Cachaça, the heart of Caipirinha, infuses the cocktail with its unique Brazilian charm and irresistible vibrancy.

            <h3>Zesty Lime Infusion:</h3>

            Freshly squeezed lime brings a burst of citrusy goodness, adding brightness and an invigorating twist to Caipirinha.

            <h3>Sweetened to Perfection:</h3>

            The addition of sugar balances the tartness, creating a harmonious blend of flavors that dance on your taste buds.

            <h3>Muddled Magic:</h3>

            Muddled with care, the lime and sugar unite with Cachaça to release the essence of Brazil's tropical allure.

            <h3>The Traditional Ritual:</h3>

            Crafted with respect to tradition, Caipirinha is a cherished Brazilian ritual, symbolizing unity and camaraderie.

            <h3>A Sip of Paradise:</h3>

            Sip on Caipirinha and be transported to the lush landscapes of Brazil, where the sun kisses the horizon.

            <h3>The Carnival Spirit:</h3>

            Embrace the joyful spirit of Carnival with every sip of Caipirinha, a celebration of life's exuberance.

            <h3>The Perfect Summer Refresher:</h3>

            Caipirinha's cool and refreshing nature makes it a perfect companion for lazy summer afternoons or lively gatherings.

            <h3>Savoring the Brazilian Joie de Vivre:</h3>

            Indulge in the Brazilian joie de vivre as you savor the spirited flavors of Caipirinha, a tribute to Brazil's zest for life. Cheers!
        `,
    },
    {
        "title": "Dark and Stormy: A Bold and Invigorating Journey",
        "ingredients": "Dark rum, Ginger beer, Lime",
        "number": "dark-and-stormy-cocktail",
        "taste": "Bold dark rum meets spicy ginger beer and zesty lime",
        "description": "Dark and Stormy draws inspiration from the tempestuous seas, where every sip reflects the spirit of adventure.",
        "body": `
        <h3>Introduction:</h3>
            Prepare to be whisked away on an exciting adventure as you set sail with the captivating Dark and Stormy cocktail.

            <h3>Bold Dark Rum:</h3>

            Dark and Stormy's heart is dark rum, adding a rich depth and complexity that ignites the senses.

            <h3>Spicy Ginger Beer:</h3>

            The fiery kick of ginger beer electrifies the palate, infusing the cocktail with a unique spiciness.

            <h3>Zesty Lime Zing:</h3>

            A splash of fresh lime juice adds a tangy and invigorating twist to the Dark and Stormy journey.

            <h3>Stormy Yet Harmonious:</h3>

            The intriguing marriage of dark rum, ginger beer, and lime creates a stormy yet harmonious symphony of flavors.

            <h3>Inspired by the High Seas:</h3>

            Dark and Stormy draws inspiration from the tempestuous seas, where every sip reflects the spirit of adventure.

            <h3>The Perfect Storm:</h3>

            Dark and Stormy is a perfect storm of tantalizing tastes, satisfying both the adventurous and the refined palates.

            <h3>Sailing into the Night:</h3>

            Sip on Dark and Stormy as you sail into the night, carried away by the allure of its intriguing flavors.

            <h3>A Riveting Experience:</h3>

            Allow yourself to be immersed in a riveting experience, where each sip takes you on a thrilling journey.

            <h3>The Allure of Darkness:</h3>

            The allure of the darkness in Dark and Stormy invites you to embrace its enigmatic and bold character.

            <h3>An Exciting Escape:</h3>

            Unleash your spirit of adventure and escape into the thrilling world of Dark and Stormy, where the possibilities are boundless. Cheers!
        `,
    },
    {
        "title": "Hemingway Daiquiri: A Citrusy Delight Inspired by the Iconic Writer",
        "ingredients": "White rum, Grapefruit juice, Maraschino liqueur, Lime juice",
        "number": "hemingway-daiquiri-cocktail",
        "taste": "Zesty white rum, tangy grapefruit juice, and maraschino liqueur craft a sophisticated and refreshing libation",
        "description": "Hemingway Daiquiri, a cocktail that pays homage to the legendary writer, Ernest Hemingway.",
        "body": `
        Introduction:
            Step into the vibrant world of literature with the Hemingway Daiquiri, a cocktail that pays homage to the legendary writer, Ernest Hemingway.

            <h3>Zesty White Rum:</h3>

            Hemingway Daiquiri features the spirited presence of white rum, delivering a crisp and refreshing base for this classic drink.

            <h3>Tangy Grapefruit Juice:</h3>

            The tangy allure of grapefruit juice adds a citrusy brightness, infusing the cocktail with a refreshing twist.

            <h3>Maraschino Liqueur Magic:</h3>

            A touch of maraschino liqueur works its magic, offering a delightful sweetness that balances the citrus notes.

            <h3>Lime Juice Elevation:</h3>

            A splash of lime juice elevates the cocktail to new heights, lending a perfect tartness to the mix.

            <h3>The Hemingway Connection:</h3>

            Inspired by the literary legacy of Ernest Hemingway, this daiquiri embodies the spirit of adventure and taste for the extraordinary.

            <h3>A Sophisticated Refresher:</h3>

            Hemingway Daiquiri is a sophisticated refresher, perfect for sipping on warm afternoons or as an accompaniment to great tales.

            <h3>Papa's Favorite:</h3>

            It's said that Hemingway himself loved this unique concoction, making it a drink with a story of its own.

            <h3>Escape with Every Sip:</h3>

            Each sip of Hemingway Daiquiri whisks you away to an exotic destination, where the sun, sea, and creativity blend seamlessly.

            <h3>Raise a Toast to Literature:</h3>

            Celebrate the power of words and flavor with Hemingway Daiquiri, a cocktail that brings literature and libation together in perfect harmony. Cheers!
        
        `,
    },
    {
        "title": "Mai Tai: A Tropical Escape with a Fusion of Rums and Exotic Flavors",
        video: '2Nhg2HdtT5A',
        "ingredients": "Dark rum, White rum, Cointreau, Orgeat syrup, Lime juice",
        "number": "maitai-cocktail",
        "taste": " the fusion of dark and white rum, Cointreau, orgeat syrup, and zesty lime juice creates an unforgettable island escape",
        "description": "This legendary cocktail was born in the tiki culture of the 1940s, a time when exotic libations became all the rage...",
        "body": `
            <h3>Introduction:</h3>

            Experience the allure of a faraway island with Mai Tai, a delightful cocktail that brings together the essence of the tropics in a single glass.

            <h3>The Perfect Balance of Rums:</h3>

            Mai Tai blends the richness of dark rum and the smoothness of white rum, striking a perfect harmony of flavors.

            <h3>Exotic Cointreau Twist:</h3>

            Adding a touch of elegance, Cointreau enhances the cocktail with its bright and citrusy essence.

            <h3>Enchanting Orgeat Syrup:</h3>

            The enchanting orgeat syrup infuses the drink with nutty undertones, creating a luscious and intriguing taste.

            <h3>Zesty Lime Juice:</h3>

            A burst of zesty lime juice adds a refreshing tang, transporting your senses to a sunny beachside escape.

            <h3>A Tropical Oasis in a Glass:</h3>

            Sipping Mai Tai is like experiencing an instant vacation, where the worries of everyday life dissolve into tranquility.

            <h3>The Origins of Mai Tai:</h3>

            This legendary cocktail was born in the tiki culture of the 1940s, a time when exotic libations became all the rage.

            <h3>Tiki Culture Resurgence:</h3>

            Today, Mai Tai continues to captivate cocktail enthusiasts, sparking a resurgence in the tiki bar trend.

            <h3>Garnished for Paradise:</h3>

            A colorful umbrella and a slice of fresh fruit complete the presentation, evoking the allure of a tropical paradise.

            <h3>Indulge in the Mai Tai Magic:</h3>

            Let the Mai Tai enchant you with its tropical magic, enticing you to unwind and embrace the spirit of the islands.

            <h3>Toast to the Tropics:</h3>

            Raise your glass to Mai Tai, the quintessential tropical cocktail that celebrates the spirit of adventure and the allure of distant shores. Cheers!
        `,
    },
    {
        "title": "Zombie: A Potent Tropical Elixir with a Spirited Kick",
        "ingredients": "Dark rum, Light rum, Lime juice, Pineapple juice, Pink Grapefruit, Absinthe",
        "number": "zombie-cocktail",
        "taste": "A daring adventure with Zombie, a potent elixir that fuses dark and light rum, zesty lime juice, pineapple and pink grapefruit, and a touch of absinthe",
        "description": "With its strong and alluring flavors, Zombie should be savored slowly and with respect for its potency.",
        "body": `
            <h3>Introduction:</h3>

            Prepare yourself for an exhilarating experience as you enter the world of Zombie, a cocktail that dares to push the boundaries of flavor and strength.

            <h3>A Fiery Fusion of Rums:</h3>

            Zombie sets the stage with a fiery fusion of dark and light rum, creating a complex and robust base.

            <h3>The Zing of Lime Juice:</h3>

            Zesty lime juice awakens the senses, adding a burst of tangy freshness to balance the intensity.

            <h3>Tropical Symphony of Pineapple and Pink Grapefruit:</h3>

            The tropical symphony of pineapple and pink grapefruit juices infuse the drink with a luscious and sweet allure.

            <h3>A Dash of Enigmatic Absinthe:</h3>

            A hint of absinthe adds an enigmatic touch, bestowing Zombie with a captivating mystique.

            <h3>A Timeless Legend:</h3>

            First concocted in the 1930s by legendary bartender Donn Beach, Zombie has become an enduring tiki classic.

            <h3>The Rise of the Zombie Culture:</h3>

            As the tiki culture boomed, the allure of Zombie spread, and its reputation as a potent, exotic cocktail grew.

            <h3>Indulge with Caution:</h3>

            With its strong and alluring flavors, Zombie should be savored slowly and with respect for its potency.

            <h3>Tiki Icon:</h3>

            Zombie is an iconic symbol of tiki culture, a testament to the creativity and imagination of mixologists.

            <h3>The Ultimate Tiki Experience:</h3>

            A single sip of Zombie transports you to a tropical oasis, where adventure and excitement await.

            <h3>Embrace the Zombie Spirit:</h3>

            Let the spirit of Zombie awaken your senses, and embark on a journey to a world of tropical bliss and enchantment. Cheers!
        
        `,
    },
    {
        "title": "Paloma: A Zesty Tequila Delight with a Touch of Raspberry",
        "ingredients": "Paloma: A Zesty Tequila Delight with a Touch of Raspberry",
        "number": "paloma-cocktail",
        "taste": "Paloma enchants with tequila's boldness, tangy grapefruit soda, and zesty lime juice, while a raspberry twist adds a delightful surprise.",
        "description": `Paloma's tantalizing medley of flavors invites you to sip, savor, and repeat, indulging in its refreshing allure.`,
        "body": `
            <h3>Introduction:</h3>

            Meet Paloma, a vibrant and refreshing tequila-based cocktail that embodies the spirit of Mexico's zestful fiestas.

            <h3>Tequila's Bold Dance:</h3>

            Paloma's heart is tequila, infusing every sip with the bold and spirited essence of Mexico.

            <h3>Tangy Grapefruit Soda:</h3>

            The invigorating tang of grapefruit soda awakens the palate, balancing the tequila's strength with a zesty twist.

            <h3>Zesty Lime Juice:</h3>

            A generous squeeze of fresh lime juice adds a zingy brightness, elevating the flavors to new heights.

            <h3>A Raspberry Delight:</h3>

            Paloma surprises with a delightful twist of raspberry, infusing the drink with a burst of fruity sweetness.

            <h3>The Salted Rim Option:</h3>

            For the adventurous, a salted rim complements the tangy and sweet notes, enhancing the overall experience.

            <h3>A Taste of Mexican Fiesta:</h3>

            Paloma captures the vibrant spirit of Mexico's lively fiestas, making it a must-have for any celebration.

            <h3>A Timeless Classic:</h3>

            Dating back to the 1950s, Paloma has become an enduring classic, cherished by cocktail enthusiasts worldwide.

            <h3>Sip, Savor, Repeat:</h3>

            Paloma's tantalizing medley of flavors invites you to sip, savor, and repeat, indulging in its refreshing allure.

            <h3>Summer in a Glass:</h3>

            With its invigorating flavors, Paloma is the epitome of summer, offering a delightful respite from the heat.

            <h3>Raise Your Glass to Paloma:</h3>

            Whether enjoyed by the beach or at a lively gathering, Paloma is the toast to unforgettable moments. Salud!
        `,
    },
    {
        "title": "Tequila Sour: A Perfect Balance of Zing and Sweetness",
        "ingredients": "Tequila, Lemon juice, Simple syrup, White foam (optional)",
        "number": "tequila-sour-cocktail",
        "taste": "Tequila Sour delights with the spirited essence of tequila, zesty lemon juice, and a touch of sweetness from simple syrup. Optional white foam for a velvety finish",
        "description": "With its refreshing profile, Tequila Sour is the perfect companion for warm summer days or vibrant evenings....",
        "body": `
            <h3>Introduction:</h3>

            Indulge in the bold and zesty charm of Tequila Sour, a delightful cocktail that captures the essence of tequila with a perfect balance of citrus and sweetness.

            <h3>Tequila's Spirited Kick:</h3>

            Tequila Sour's foundation is tequila, infusing each sip with the robust and spirited character of the agave-based spirit.

            <h3>Zesty Lemon Zing:</h3>

            A generous squeeze of fresh lemon juice adds a lively zing, complementing the tequila's intensity with citrus brightness.

            <h3>A Touch of Sweetness:</h3>

            A dash of simple syrup provides a gentle touch of sweetness, harmonizing the flavors and softening the zesty notes.

            <h3>Velvety White Foam (Optional):</h3>

            For a velvety finish, indulge in the optional white foam, elevating the cocktail's texture and visual appeal.

            <h3>A Timeless Classic:</h3>

            Tequila Sour stands as a timeless classic, treasured by both seasoned cocktail enthusiasts and newcomers alike.

            <h3>Versatile and Refreshing:</h3>

            With its refreshing profile, Tequila Sour is the perfect companion for warm summer days or vibrant evenings.

            <h3>Raise Your Glass to Tequila Sour:</h3>

            Toast to unforgettable moments with Tequila Sour, a cocktail that celebrates the spirited essence of tequila in every sip.

            <h3>Mixing Tradition and Innovation:</h3>

            Tequila Sour masterfully combines traditional elements with innovative touches, making it a favorite among modern mixologists.

            <h3>Crafted to Perfection:</h3>

            Every Tequila Sour is crafted to perfection, ensuring a harmonious balance of flavors that tantalize the taste buds.

            <h3>The Perfect Sip:</h3>

            Whether sipped alone or paired with delectable dishes, Tequila Sour promises a perfect balance of zing and sweetness in every delightful sip.
        `,
    },
    {
        "title": "Bermondsey Minute: A Sublime Blend of Tequila and Elegance",
        "ingredients": "Tequila, Sweet vermouth, Luxardo Maraschino, Lavender bitter",
        "number": "bermondsey-Minute-cocktail",
        "taste": "A refined mix of tequila, sweet vermouth, Luxardo Maraschino, and lavender bitter",
        "description": `Each sip of Bermondsey Minute promises a moment of pure sophistication, perfect for those seeking an elevated cocktail experience.`,
        "body": `
            <h3>Introduction:</h3>

            Discover the exquisite allure of Bermondsey Minute, a cocktail that artfully blends tequila with elegant notes of sweet vermouth, Luxardo Maraschino, and lavender bitter.

            <h3>Tequila's Bold Spirit:</h3>

            Bermondsey Minute begins with tequila, infusing the cocktail with its bold and captivating character.

            <h3>Sweet Vermouth's Elegance:</h3>

            The addition of sweet vermouth brings an air of sophistication, elevating the cocktail with its rich and elegant undertones.

            <h3>Luxardo Maraschino's Sublime Touch:</h3>

            Luxardo Maraschino imparts a sublime touch, adding depth and complexity to the concoction.

            <h3>Lavender Bitter's Fragrant Symphony:</h3>

            The delicate presence of lavender bitter weaves a fragrant symphony, enriching the drink with floral notes and a hint of bitterness.

            <h3>A Moment of Pure Sophistication:</h3>

            Each sip of Bermondsey Minute promises a moment of pure sophistication, perfect for those seeking an elevated cocktail experience.

            <h3>A Tantalizing Fusion:</h3>

            Bermondsey Minute flawlessly fuses diverse elements, crafting a unique and memorable cocktail for discerning palates.

            <h3>The Art of Balance:</h3>

            Every component in Bermondsey Minute is skillfully balanced, ensuring a seamless and harmonious taste.

            <h3>For the Connoisseurs:</h3>

            This captivating cocktail is a true delight for connoisseurs, offering a journey of flavors and aromas to be savored.

            <h3>Elevating Your Experience:</h3>

            Bermondsey Minute elevates the cocktail experience, leaving a lasting impression on those who relish its sophisticated allure.

            <h3>Toast to Refinement:</h3>

            Raise your glass to Bermondsey Minute, a refined and captivating libation that encapsulates the essence of artful mixology.
        `,
    },
    {
        "title": "El Diablo: A Fiery Tequila Delight",
        "ingredients": "Tequila, Ginger beer, Lime juice, Crème de cassis",
        "number": "el-diablo-cocktail",
        "taste": "A fiery mix of tequila, zesty lime juice, spicy ginger beer, and the enchanting touch of crème de cassis",
        "description": `El Diablo is a must-try for the adventurous palate, offering a unique and invigorating cocktail experience.`,
        "body": `
            <h3>Introduction:</h3>
            Unleash the captivating spirit of El Diablo, a tantalizing cocktail that combines the boldness of tequila with the zest of lime juice, the kick of ginger beer, and the allure of crème de cassis.

            <h3>Tequila's Allure:</h3>

            El Diablo's heart lies in tequila, infusing the drink with its distinctive and captivating allure.

            <h3>Zesty Lime Juice:</h3>

            The addition of zesty lime juice provides a burst of freshness, enlivening the cocktail with its vibrant tang.

            <h3>Spicy Ginger Beer:</h3>

            Spicy ginger beer brings a fiery kick, adding depth and excitement to every sip.

            <h3>Enchanting Crème de Cassis:</h3>

            The enchanting touch of crème de cassis weaves a captivating spell, infusing the cocktail with dark berry flavors and a hint of sweetness.

            <h3>A Devilishly Delicious Experience:</h3>

            El Diablo promises a devilishly delicious experience, igniting the taste buds with a delightful blend of flavors.

            <h3>An Adventure in Every Sip:</h3>

            Each sip of El Diablo is an adventure, combining tequila's boldness with a tantalizing symphony of complementary tastes.

            <h3>Dance of Flavors:</h3>

            El Diablo orchestrates a dance of flavors, where tequila, lime juice, ginger beer, and crème de cassis intertwine in perfect harmony.

            <h3>For the Adventurous Palate:</h3>

            El Diablo is a must-try for the adventurous palate, offering a unique and invigorating cocktail experience.

            <h3>Raise Your Glass to the Devil:</h3>

            Raise your glass to El Diablo, the devilishly delightful cocktail that celebrates the spirited essence of tequila and the magic of mixology.

            <h3>A Toast to Boldness:</h3>

            El Diablo is a toast to the bold, the daring, and those who seek excitement in every sip—a libation that leaves a memorable impression.
        `,
    },
    {
        "title": "Margarita: The Iconic Tequila Classic",
        "ingredients": "Tequila, Lime juice, Cointreau",
        "number": "margarita-cocktail",
        "taste": "Marries the boldness of tequila with the tang of lime juice and the smoothness of Cointreau, delivering a timeless and refreshing cocktail.",
        "description": `Time has only heightened Margarita's charm, making it an enduring classic in the world of mixology.`,
        "body": `
            <h3>Introduction:</h3>

            Indulge in the timeless allure of Margarita, a classic tequila cocktail celebrated worldwide for its simplicity and invigorating taste.

            <h3>Bold Tequila Backbone:</h3>

            Margarita's backbone is formed by the bold and spirited essence of tequila, infusing the drink with its distinctive character.

            <h3>Tangy Lime Juice:</h3>

            The tangy lime juice adds a burst of freshness, striking the perfect balance with the tequila's richness.

            <h3>Smooth Cointreau Elegance:</h3>

            The smooth and refined touch of Cointreau brings elegance to the mix, enhancing the cocktail's overall harmony.

            <h3>A Refreshing Blend:</h3>

            Margarita offers a refreshing blend of flavors that dance on the palate with every sip.

            <h3>A Sip of Sunshine:</h3>

            With its vibrant citrus notes, Margarita captures the essence of a sunny day in every delightful sip.

            <h3>Salted Rim Delight:</h3>

            For a delightful twist, a salted rim elevates Margarita, adding a savory contrast to the drink's bright flavors.

            <h3>The Quintessential Cocktail:</h3>

            Margarita stands as the quintessential cocktail, admired for its simplicity and universal appeal.

            <h3>A Timeless Classic:</h3>

            Time has only heightened Margarita's charm, making it an enduring classic in the world of mixology.

            <h3>Sip, Savor, Repeat:</h3>

            Sip, savor, and repeat the pleasure of Margarita—a libation that invites you to enjoy every moment.

            <h3>Craftsmanship in a Glass:</h3>

            Margarita represents the artistry of mixology, where tequila, lime juice, and Cointreau blend seamlessly into perfection.
        `,
    },
    {
        "title": "Siesta: An Exquisite Tequila Escape",
        "ingredients": "Tequila, Campari, Grapefruit juice, Lime juice, Simple syrup",
        "number": "siesta-cocktail",
        "taste": "Boldness of tequila, the bittersweet allure of Campari, and the zesty notes of grapefruit and lime juice",
        "description": "Siesta presents an oasis of pleasure, offering a delightful respite from the ordinary",
        "body": `
        
            <h3>Introduction:</h3>

            Embark on an exquisite tequila escape with Siesta, a captivating cocktail that marries the spirited essence of tequila with a bittersweet twist.

            <h3>Bold Tequila Infusion:</h3>

            Siesta derives its spirited core from the bold infusion of tequila, adding depth and character to the libation.

            <h3>Bittersweet Campari Elegance:</h3>

            Campari's bittersweet allure gracefully complements the tequila, contributing to the cocktail's enchanting elegance.

            <h3>Zesty Grapefruit and Lime:</h3>

            Siesta dances on the palate with the zesty interplay of grapefruit and lime juices, creating a symphony of flavors.

            <h3>Balanced Sweetness:</h3>

            A touch of simple syrup lends balanced sweetness, harmonizing the drink's various elements.

            <h3>An Oasis of Pleasure:</h3>

            Siesta presents an oasis of pleasure, offering a delightful respite from the ordinary.

            <h3>Savor the Sunset:</h3>

            Savor Siesta during sunset hours, as the cocktail captures the essence of a tranquil evening.

            <h3>A Vibrant Sensation:</h3>

            Siesta's vibrant flavors awaken the senses, making it a cocktail sensation worth savoring.

            <h3>Crafted with Precision:</h3>

            Every ingredient in Siesta is meticulously blended to perfection, ensuring a cocktail that delights with every sip.

            <h3>A Cocktail Retreat:</h3>

            Indulge in Siesta, a cocktail retreat that transports you to a world of sophistication and indulgence.

            <h3>The Art of Imagination:</h3>

            Siesta represents the artful imagination of mixology, where tequila, Campari, and citrus intertwine in a symphony of taste.
        `,
    },
    {
        "title": "Whiskey Smash: A Refreshing Bourbon Elixir",
        "ingredients": "Bourbon, Lemon juice, Simple syrup, Mint leaves",
        "number": "whiskey-smash-cocktail",
        "taste": "Robustness of bourbon with zesty lemon, cooling mint, and a touch of sweetness, crafting a revitalizing libation.",
        "description": "Whiskey Smash stands as a contemporary classic, reinventing the art of sipping bourbon with a twist of refreshing flavors",
        "body": `
            <h3>Introduction:</h3>

            Discover the invigorating world of Whiskey Smash, a delightful bourbon elixir that combines the robustness of bourbon with bright citrus and refreshing mint.
            
            <h3>Robust Bourbon Essence:</h3>
            
            Whiskey Smash draws its essence from the robust flavor profile of bourbon, creating a strong foundation for the cocktail.
            
            <h3>Zesty Lemon Zing:</h3>
            
            The zesty notes of lemon juice enliven the libation, infusing it with a burst of invigorating citrus.
            
            <h3>Cooling Mint Infusion:</h3>
            
            The refreshing presence of mint leaves adds a cooling touch, elevating the drink's overall freshness.
            
            <h3>Balanced Sweetness:</h3>
            
            A touch of simple syrup provides balanced sweetness, harmonizing the flavors and enhancing the sipping experience.
            
            <h3>A Revitalizing Libation:</h3>
            
            Whiskey Smash is a revitalizing libation, perfect for lifting spirits and enlivening the senses.
            
            <h3>Savor the Moment:</h3>
            
            Savor the moment with Whiskey Smash, an enchanting elixir crafted for moments of relaxation and enjoyment.
            
            <h3>Craftsmanship in Every Sip:</h3>
            
            Every sip of Whiskey Smash reflects the craftsmanship and precision that goes into creating this exceptional cocktail.
            
            <h3>A Whiskey Connoisseur's Delight:</h3>
            
            Whiskey connoisseurs will revel in the delightful combination of bourbon, lemon, and mint in this sophisticated libation.
            
            <h3>A Contemporary Classic:</h3>
            
            Whiskey Smash stands as a contemporary classic, reinventing the art of sipping bourbon with a twist of refreshing flavors.
            
            <h3>The Ultimate Refresher:</h3>
            
            Whiskey Smash is the ultimate refresher, making it a must-try for those seeking a revitalizing and flavorful cocktail experience. 
        `,
    },
    {
        "title": "Boulevardier: A Sophisticated Bourbon Classic",
        "ingredients": "Bourbon, Sweet vermouth, Campari",
        "number": "boulevardier-cocktail",
        "taste": "Marries the smoothness of bourbon with the richness of sweet vermouth and the bitterness of Campari, culminating in a harmonious and captivating libation",
        "description": `Boulevardier shines as an alluring aperitif, perfect for setting the stage for an unforgettable dining experience`,
        "body": `
            <h3>Introduction:</h3>

            Indulge in the timeless allure of Boulevardier, a sophisticated bourbon classic that brings together the finest ingredients in perfect harmony.

            <h3>Smooth Bourbon Elegance:</h3>

            Boulevardier exudes smooth elegance with its carefully selected bourbon base, providing a delightful foundation for the cocktail.

            <h3>Rich Sweet Vermouth Nuances:</h3>

            The richness of sweet vermouth adds layers of complexity, enhancing the libation with its deep and flavorful nuances.

            <h3>Bitter Campari Contrast:</h3>

            The addition of Campari infuses the drink with a captivating bitterness, creating a harmonious contrast to the sweetness of the vermouth.

            <h3>A Captivating Sip:</h3>

            Boulevardier captivates the palate with its well-balanced blend of bourbon, vermouth, and Campari.

            <h3>A Toast to Refinement:</h3>

            Raise a glass to refinement with Boulevardier, a cocktail that embodies the essence of sophistication and elegance.

            <h3>The Art of Mixology:</h3>

            Boulevardier exemplifies the artistry of mixology, showcasing how a thoughtful combination of ingredients can create a timeless libation.

            <h3>A Connoisseur's Delight:</h3>

            Boulevardier delights whiskey connoisseurs, offering a delightful journey through the nuanced flavors of bourbon and the bittersweet complexity of Campari.

            <h3>An Alluring Aperitif:</h3>

            Boulevardier shines as an alluring aperitif, perfect for setting the stage for an unforgettable dining experience.

            <h3>An Iconic Classic:</h3>

            Boulevardier stands as an iconic classic, making it a staple in the repertoire of any discerning cocktail enthusiast.

            <h3>Unveiling the Legend:</h3>

            Unveil the legend of Boulevardier, a cocktail celebrated for its timeless appeal and unforgettable taste.
        `,
    },
    {
        "title": "Godfather: Embrace the Legendary Scotch and Amaretto Blend",
        video: 'RW0UjNZqiKI',
        "ingredients": "Scotch, Amaretto",
        "number": "god-father-cocktail",
        "taste": "Unites the rich smokiness of Scotch with the sweet almond notes of Amaretto",
        "description": "The Godfather, a legendary classic cocktail that encapsulates the essence of two exceptional spirits",
        "body": `
            <h3>Introduction:</h3>

            Step into the world of legends with the Godfather, a classic cocktail that marries the distinct flavors of Scotch and Amaretto in perfect harmony.

            <h3>Rich and Smoky Scotch:</h3>

            The Godfather captivates the senses with the rich and smoky essence of Scotch, providing a robust and flavorful base.

            <h3>Sweet Almond Notes of Amaretto:</h3>

            Amaretto adds a touch of sweetness with its delightful almond notes, complementing the Scotch and enhancing the overall taste.

            <h3>An Enchanting Sip:</h3>

            The Godfather bewitches the palate with its enchanting blend of Scotch and Amaretto, creating a truly memorable sipping experience.

            <h3>The Elegance of Simplicity:</h3>

            The Godfather showcases the elegance of simplicity, as two distinct spirits intertwine to create a classic masterpiece.

            <h3>A Timeless Classic:</h3>

            The Godfather stands the test of time as a timeless classic, cherished by whiskey enthusiasts and cocktail aficionados alike.

            <h3>Savor the Legacy:</h3>

            Savor the legacy of the Godfather, an illustrious cocktail that continues to captivate the hearts of discerning drinkers.

            <h3>A Toast to Greatness:</h3>

            Raise a toast to greatness with the Godfather, a cocktail that encapsulates the essence of two exceptional spirits.

            <h3>A Captivating Fusion:</h3>

            The Godfather's fusion of Scotch and Amaretto results in a captivating and harmonious union of flavors.

            <h3>A Cocktail Icon:</h3>

            Embrace the legacy of the Godfather, a cocktail that has earned its place as an icon in the world of mixology.
        `,
    },
    {
        "title": "Mint Julep: Sip the Timeless Elegance of Bourbon with Refreshing Mint",
        "ingredients": "Bourbon, Simple syrup, Mint leaves",
        "number": "mint-julep-cocktail",
        "taste": "Combines the timeless elegance of Bourbon with the refreshing essence of mint",
        "description": `Mint Julep showcases the art of muddling, as fresh mint is gently crushed to release its aromatic oils.`,
        "body": `
            <h3>Introduction:</h3>

            Indulge in the timeless allure of Mint Julep, a classic cocktail that brings together the finest Bourbon and invigorating mint for a truly refreshing treat.

            <h3>The Richness of Bourbon:</h3>

            Mint Julep delights the palate with the rich and velvety essence of Bourbon, providing a warm and comforting base.

            <h3>Refreshing Mint Leaves:</h3>

            Fresh mint leaves infuse the cocktail with their invigorating aroma and flavor, lending a cool and revitalizing twist.

            <h3>A Symphony of Flavors:</h3>

            Mint Julep harmonizes the sweet undertones of simple syrup, the boldness of Bourbon, and the rejuvenating burst of mint.

            <h3>A Time-Honored Tradition:</h3>

            Mint Julep stands as a time-honored tradition, cherished for generations for its simple yet exquisite taste.

            <h3>The Quintessential Southern Charm:</h3>

            Embrace the quintessential charm of the South with each sip of this iconic cocktail, reminiscent of lazy summer afternoons.

            <h3>An Icon at the Kentucky Derby:</h3>

            Mint Julep has long been an iconic drink at the Kentucky Derby, symbolizing elegance and tradition.

            <h3>An Oasis of Refreshment:</h3>

            Sip the Mint Julep and find yourself transported to an oasis of refreshment, perfect for warm summer days.

            <h3>The Art of Muddling:</h3>

            Mint Julep showcases the art of muddling, as fresh mint is gently crushed to release its aromatic oils.

            <h3>A Delightful Sipping Experience:</h3>

            Savor the delightful sipping experience of Mint Julep, a classic cocktail that captures the essence of relaxation and sophistication.
        `,
    },
    {
        "title": "Manhattan: A Timeless Symphony of Rye Whiskey, Sweet Vermouth, and Bitters",
        "ingredients": "Rye whiskey, Sweet vermouth, Angostura bitters",
        "number": "manhattan-cocktail",
        "taste": "Sweet vermouth, and Angostura bitters, delivering a sophisticated and bold sipping experience.",
        "description": `This cocktail's name is an ode to the iconic Manhattan skyline, evoking the same sense of sophistication and grandeur.`,
        "body": `
            <h3>Introduction:</h3>

            Step into the world of refined taste with Manhattan, a classic cocktail that seamlessly blends the richness of Rye Whiskey, the allure of sweet vermouth, and the complexity of Angostura bitters.

            <h3>Rye Whiskey's Bold Character:</h3>

            Manhattan boasts the bold and robust character of Rye Whiskey, imparting a warm and inviting foundation to the drink.

            <h3>Sweet Vermouth's Elegance:</h3>

            The addition of sweet vermouth lends Manhattan its elegant and subtly sweet undertones, balancing the cocktail to perfection.

            <h3>Angostura Bitters' Complexity:</h3>

            The touch of Angostura bitters adds depth and complexity, creating an intriguing interplay of flavors that captivates the palate.

            <h3>The Art of Stirring:</h3>

            Manhattan's excellence lies in the art of stirring, skillfully blending the ingredients to achieve a harmonious unity.

            <h3>The Classic Cherry Garnish:</h3>

            A maraschino cherry garnish provides a delightful finishing touch, adding a pop of color and a hint of sweetness.

            <h3>The Perfect Aperitif:</h3>

            Manhattan stands as the perfect aperitif, stimulating the appetite and setting the stage for an exquisite dining experience.

            <h3>An Iconic Manhattan Skyline:</h3>

            This cocktail's name is an ode to the iconic Manhattan skyline, evoking the same sense of sophistication and grandeur.

            <h3>A Timeless Favorite:</h3>

            Manhattan has remained a timeless favorite among cocktail enthusiasts, exuding elegance and refinement.

            <h3>Cheers to Manhattan:</h3>

            Raise your glass to Manhattan, a classic cocktail that epitomizes the art of mixology and the allure of old-world charm.
        `,
    },
    {
        "title": "New York Sour: A Timeless Fusion of Bourbon and Wine with a Citrus Twist",
        "ingredients": "Bourbon, Lime juice, Simple syrup, Red wine float, White foam (optional)",
        "number": "new-york-sour-cocktail",
        "taste": "Blends rich Bourbon and zesty lime juice, garnished with a vibrant red wine float, creating a visually stunning and delectably balanced classic cocktail",
        "description": "As you take a sip, the New York Sour unveils a symphony of flavors, offering a delightful journey from start to finish.",
        "body": `
                <h3>Introduction:</h3>

                Step into the cosmopolitan spirit of the Big Apple with the New York Sour, a timeless classic that combines the richness of Bourbon, the tang of lime, and the allure of red wine.

                <h3>Bourbon's Smooth Depth:</h3>

                Bourbon takes center stage, infusing the New York Sour with its smooth, caramel-laden depth, evoking the warmth of a bustling city.

                <h3>Citrus Zest with Lime:</h3>

                The tangy burst of lime juice adds a refreshing twist to the New York Sour, elevating its flavor profile to new heights.

                <h3>Simple Syrup for Sweet Harmony:</h3>

                Simple syrup bridges the flavors, balancing the richness of Bourbon with the lime's zesty kick, creating a harmonious blend.

                <h3>A Wine-Inspired Finale:</h3>

                A vibrant red wine float crowns the New York Sour, adding a delightful touch of complexity and elegance to this classic concoction.

                <h3>Visually Stunning Delight:</h3>

                The red wine float creates a mesmerizing visual spectacle, adding an artistic flair that sets the New York Sour apart.

                <h3>Optional White Foam:</h3>

                For an extra touch of elegance, the New York Sour can be crowned with a delicate white foam, accentuating its allure.

                <h3>Sip and Savor:</h3>

                As you take a sip, the New York Sour unveils a symphony of flavors, offering a delightful journey from start to finish.

                <h3>An Iconic City in a Glass:</h3>

                Like the city it's named after, the New York Sour exudes sophistication and charm, capturing the essence of iconic Manhattan nights.

                <h3>The Perfect Cocktail for All Occasions:</h3>

                Whether sipped at a trendy rooftop bar or during an intimate gathering, the New York Sour adds a touch of urban chic to any occasion.

                <h3>Raise a Glass to the Classics:</h3>

                With the New York Sour, you can celebrate the enduring charm of classic cocktails while toasting to the vibrant spirit of New York City. Cheers!
        `,

    },
    {
        "title": "Old Pal: A Bold Fusion of Bourbon, Dry Vermouth, and Campari",
        "ingredients": "Bourbon, Dry vermouth, Campari",
        "number": "old-pal-cocktail",
        "taste": "Melding robust Bourbon, dry Vermouth, and bittersweet Campari",
        "description": "The Old Pal celebrates camaraderie and companionship, reminding us of the joys of shared moments over a delightful cocktail.",
        "body": `
            <h3>Introduction:</h3>
            Step into the realm of bold flavors with the Old Pal cocktail, a daring fusion of Bourbon's richness, dry Vermouth's subtlety, and Campari's bittersweet allure.

            <h3>Bourbon's Robust Elegance:</h3>

            Bourbon takes center stage, gracing the Old Pal with its rich, caramel notes and a touch of oak-inspired sophistication.

            <h3>Dry Vermouth's Subtle Twist:</h3>

            Dry Vermouth adds a nuanced twist to the Old Pal, complementing the Bourbon's intensity with its delicate herbal essence.

            <h3>Campari's Bittersweet Symphony:</h3>

            Campari, the protagonist of bittersweet liqueurs, harmonizes the Old Pal with its vibrant crimson hue and herbal bitterness.

            <h3>A Pal for Adventurous Souls:</h3>

            Designed for the adventurous at heart, the Old Pal beckons you to explore the world of intense flavors and daring combinations.

            <h3>Classic Mixology, Modern Impact:</h3>

            With a nod to classic mixology, the Old Pal's contemporary allure appeals to the most discerning palates.

            <h3>A Toast to Friendship:</h3>

            The Old Pal celebrates camaraderie and companionship, reminding us of the joys of shared moments over a delightful cocktail.

            <h3>Served in Sophistication:</h3>

            Enjoy the Old Pal in a chilled glass, garnished with a twist of orange or lemon, elevating its appearance with a touch of sophistication.

            <h3>Timeless Elegance in a Glass:</h3>

            Embodying timeless elegance, the Old Pal brings the charm of classic cocktails to life, epitomizing an era of refined tastes.

            <h3>Connoisseur's Delight:</h3>

            For the connoisseur seeking a remarkable journey of flavors, the Old Pal is an irresistible choice, promising an unforgettable experience.

            <h3>Bold and Unforgettable:</h3>

            The Old Pal leaves an indelible impression, ensuring each sip unveils a symphony of taste that lingers on the palate. Cheers to adventure!
        
        `,
    },
    {
        "title": "Penicillin: A Medicinal Fusion of Scotch, Zesty Lime, and Honey-Ginger Elixir",
        "ingredients": "Scotch, Lime juice, Honey syrup, Ginger syrup, Smoky Scotch",
        "number": "penicillin-cocktail",
        "taste": "A medicinal marvel blending Scotch, zesty Lime, and a comforting Honey-Ginger elixir, crowned with a hint of smoky Scotch",
        "description": "Named after the iconic medicine, the Penicillin cocktail brings revitalizing qualities to your indulgent moments.",
        "body": `
                <h3>Introduction:</h3>

                Discover the Penicillin cocktail, a masterful fusion of Scotch, invigorating Lime juice, and a soothing elixir of Honey and Ginger, culminating in a smoky sensation.

                <h3>Scotch's Soothing Base:</h3>

                At the heart of the Penicillin lies the warmth of Scotch, comforting and harmonizing the cocktail's diverse flavors.

                <h3>Zesty Tang of Lime:</h3>

                Zesty Lime juice invigorates the Penicillin, imparting a vibrant tang that perfectly balances the drink.

                <h3>Honey-Ginger Elixir:</h3>

                The Honey-Ginger elixir tenderly embraces the cocktail, lending a soothing touch that delights the senses.

                <h3>A Whiff of Smoky Scotch:</h3>

                A subtle dash of smoky Scotch adds intrigue, reminiscent of a fragrant campfire, elevating the Penicillin's complexity.

                <h3>Inspired by a Healing Elixir:</h3>

                Named after the iconic medicine, the Penicillin cocktail brings revitalizing qualities to your indulgent moments.

                <h3>A Remedy for the Soul:</h3>

                The Penicillin offers a remedy for the soul, soothing and uplifting with every sip.

                <h3>Crafted for Tranquil Moments:</h3>

                Savor the Penicillin during moments of relaxation, enjoying its nurturing embrace and invigorating essence.

                <h3>Garnished with Elegance:</h3>

                Adorn your Penicillin with a lime wheel or candied ginger, adding an elegant touch to its presentation.

                <h3>Culinary Artistry in a Glass:</h3>

                The Penicillin exemplifies the artistry of mixology, where flavors unite to create a delightful culinary experience.

                <h3>An Intoxicating Prescription:</h3>

                Indulge in the Penicillin, an intoxicating prescription for those seeking a harmonious blend of Scotch and revitalizing flavors. Cheers to rejuvenation!
        `,
    },
    {
        "title": "Rob Roy: A Timeless Symphony of Scotch, Sweet Vermouth, and Angostura Bitters",
        video: 'yISDDOdoYCk',
        "ingredients": "Scotch, Sweet vermouth, Angostura bitters,",
        "number": "rob-roy-cocktail",
        "taste": "A classic blend of Scotch, Sweet Vermouth, and aromatic Angostura Bitters",
        "description": "The Rob Roy stands the test of time, delighting cocktail enthusiasts with its enduring elegance and refined taste.",
        "body": `
            <h3>Introduction:</h3>

            Step into the world of classic mixology with the Rob Roy cocktail, a harmonious combination of Scotch, Sweet Vermouth, and the aromatic touch of Angostura Bitters.

            <h3>Scotch's Noble Spirit:</h3>

            Rob Roy's heart lies in the noble essence of Scotch, infusing the cocktail with an undeniable sense of grandeur.

            <h3>Sweet Vermouth's Alluring Sweetness:</h3>

            The captivating sweetness of Sweet Vermouth intertwines flawlessly with Scotch, enhancing its character and creating a smooth profile.

            <h3>Aromatic Angostura Bitters:</h3>

            Angostura Bitters adds a touch of spice and depth, elevating the Rob Roy's flavor with its aromatic charm.

            <h3>A Tribute to Rob Roy MacGregor:</h3>

            Named after the Scottish folk hero, the Rob Roy cocktail pays homage to the bravery and charisma that inspired its creation.

            <h3>A Classic with Timeless Appeal:</h3>

            The Rob Roy stands the test of time, delighting cocktail enthusiasts with its enduring elegance and refined taste.

            <h3>A Perfect Evening Sipper:</h3>

            Enjoy the Rob Roy as a sophisticated evening sipper, perfect for winding down in the company of friends or alone in quiet contemplation.

            <h3>A Symphony of Flavors:</h3>

            Rob Roy's delightful blend of flavors forms a symphony on the palate, leaving a lasting impression of balance and complexity.

            <h3>Garnished to Perfection:</h3>

            Enhance the Rob Roy's presentation with a cherry or lemon twist, adding a final touch of visual elegance to the cocktail.

            <h3>Rediscovering Cocktail Classics:</h3>

            Indulge in the nostalgic charm of the Rob Roy, rediscovering the allure of classic cocktails and the artistry of mixologists from yesteryears.

            <h3>Raise a Glass to Timeless Sophistication:</h3>

            Celebrate the essence of sophistication with the Rob Roy cocktail, a true classic that embodies the elegance of Scotch and the charm of timeless libations. Cheers!
        `,
    },
    {
        "title": "Churchill: A Toast to the Bold Fusion of Scotch, Vermouth, Lime Juice, and Cointreau",
        "ingredients": "Scotch, Sweet vermouth, Lime juice, Cointreau",
        "number": "churchill-cocktail",
        "taste": "Audacious blend of Scotch, Sweet Vermouth, tangy Lime Juice, and vibrant Cointreau",
        "description": "Named after the iconic statesman, the Churchill cocktail is a nod to his taste for bold and refined libations.",
        "body": `
            <h3>Introduction:</h3>

            Step into the world of sophistication with the Churchill cocktail, a captivating blend that harmonizes the distinguished notes of Scotch, Sweet Vermouth, tangy Lime Juice, and lively Cointreau.

            <h3>The Essence of Scotch:</h3>

            Churchill's soul lies in the rich, smoky essence of Scotch, bestowing a sense of refinement upon each sip.

            <h3>Sweet Vermouth's Allure:</h3>

            Sweet Vermouth imparts an enchanting sweetness that complements the Scotch's complexity, adding depth to the overall flavor.

            <h3>Tangy Lime Juice Twist:</h3>

            The tangy touch of Lime Juice adds a vibrant twist, creating a refreshing balance to the cocktail's composition.

            <h3>The Vibrancy of Cointreau:</h3>

            Cointreau's zesty orange flavor infuses a burst of liveliness, elevating the Churchill cocktail to new heights.

            <h3>A Tribute to Winston Churchill:</h3>

            Named after the iconic statesman, the Churchill cocktail is a nod to his taste for bold and refined libations.

            <h3>Elevating the Scotch Experience:</h3>

            The Churchill cocktail brings a contemporary twist to Scotch, appealing to both connoisseurs and adventurous drinkers.

            <h3>A Delight for the Senses:</h3>

            The cocktail's visually captivating presentation and enticing aroma set the stage for a delightful sensory experience.

            <h3>The Perfect Aperitif:</h3>

            Savor the Churchill as a prelude to a memorable dining experience, stimulating the appetite with its harmonious blend.

            <h3>A Timeless Elixir:</h3>

            The Churchill cocktail embodies timeless elegance, standing as a testament to the enduring allure of classic mixology.

            <h3>Raise a Glass to Refinement:</h3>

            Toast to sophistication and pay homage to history with the Churchill cocktail, a refined libation that celebrates the art of cocktail craftsmanship. Cheers!
        `,
    },
    {
        "title": "Sazerac: The Timeless Elixir of Rye Whiskey, Bitters, and Absinthe",
        "ingredients": "Rye whiskey, Sugar, Peychaud's bitters, Absinthe rinse, ",
        "number": "sazerac-cocktail",
        "taste": "Robust flavors of Rye Whiskey with aromatic Bitters and an Absinthe rinse, delivering a sophisticated and unforgettable libation",
        "description": "Savor the spirit of New Orleans through the Sazerac, a libation that encapsulates the city's vibrant culture and rich culinary traditions...",
        "body": `
            <h3>Introduction:</h3>

            Indulge in the timeless allure of the Sazerac, a classic cocktail with a storied past that combines the boldness of Rye Whiskey, aromatic Bitters, and a touch of Absinthe.
            
            <h3>Rye Whiskey's Robust Character:</h3>
            
            Sazerac's heart lies in the bold and robust character of Rye Whiskey, infusing each sip with a captivating depth of flavor.
            
            <h3>Aromatic Symphony of Bitters:</h3>
            
            The aromatic Peychaud's Bitters creates a harmonious symphony, elevating the Rye Whiskey's profile with its intriguing spice and herbal notes.
            
            <h3>The Alluring Absinthe Rinse:</h3>
            
            An Absinthe rinse enhances the experience, imparting a subtle touch of anise and contributing to the cocktail's mystique.
            
            <h3>The Whiskey Ritual:</h3>
            
            The preparation of a Sazerac involves a captivating ritual, where an Absinthe-coated glass is swirled to coat the interior with the iconic green fairy's essence.
            
            <h3>A Nod to History:</h3>
            
            The Sazerac is an homage to the historical heritage of New Orleans, regarded as one of the world's first cocktails.
            
            <h3>An Old-Fashioned Elegance:</h3>
            
            Sazerac exudes an old-fashioned elegance, transporting aficionados to a bygone era with every sip.
            
            <h3>A Complex and Layered Libation:</h3>
            
            The Sazerac's complex profile offers a layered taste experience that evolves on the palate with each component unveiling its distinctive personality.
            
            <h3>The Soul of New Orleans:</h3>
            
            Savor the spirit of New Orleans through the Sazerac, a libation that encapsulates the city's vibrant culture and rich culinary traditions.
            
            <h3>An Unforgettable Sip:</h3>
            
            Indulge in the Sazerac's refined flavors, a timeless libation that lingers on the palate, leaving an indelible mark on your taste buds.
            
            <h3>Raise a Glass to Tradition:</h3>
            
            Embrace the tradition of the Sazerac, a classic cocktail that continues to captivate discerning drinkers with its timeless allure. Cheers!
        `,
    },
    {
        "title": "Absinthe Sour: An Enchanting Fusion of Absinthe, Vodka, and Zesty Lemon",
        "ingredients": "Absinthe, Vodka, lemon juice, Sugar, White foam (optional)",
        "number": "absinthe-sour-cocktail",
        "taste": "Entices with the mystical allure of Absinthe and the smoothness of Vodka, combined with tangy Lemon, a hint of Sugar, and optional velvety",
        "description": "Absinthe Sour beckons the adventurous spirit, enticing those who dare to embrace the unique and intriguing flavors.",
        "body": `
            <h3>Introduction:</h3>

            Step into the mesmerizing world of Absinthe Sour, a classic cocktail that weaves the enchanting essence of Absinthe and Vodka with the vibrant tang of Lemon.

            <h3>The Mystical Allure of Absinthe:</h3>

            Absinthe's enigmatic reputation adds an intoxicating allure to Absinthe Sour, captivating with its signature herbal notes and anise-flavored charm.

            <h3>Smoothness of Vodka:</h3>

            Vodka's clean and smooth character balances the boldness of Absinthe, creating a harmonious foundation for this intriguing concoction.

            <h3>Zesty Tang of Lemon:</h3>

            A burst of zesty Lemon juice adds a refreshing twist, infusing Absinthe Sour with a lively citrusy punch.

            <h3>A Hint of Sweetness:</h3>

            A touch of Sugar delicately sweetens the libation, enhancing the overall flavor profile without overpowering the herbal notes.

            <h3>Optional Velvety White Foam:</h3>

            For an exquisite touch, the optional velvety White Foam crowns the cocktail, offering a visually appealing and luxurious texture.

            <h3>An Experience of a Bygone Era:</h3>

            Absinthe Sour transports connoisseurs back to the allure of a bygone era, reminiscent of the Belle Époque and bohemian Paris.

            <h3>The Ritual of Louche:</h3>

            Witness the mesmerizing "louche" effect as ice-cold water is dripped over the Absinthe, an artful spectacle that transforms the drink.

            <h3>A Drink for the Bold:</h3>

            Absinthe Sour beckons the adventurous spirit, enticing those who dare to embrace the unique and intriguing flavors.

            <h3>Sip into Enchantment:</h3>

            As the Absinthe Sour dances on the palate, it weaves an enchanting tapestry of flavors, leaving an indelible mark on the senses.

            <h3>Celebrate the Green Fairy:</h3>

            Toast to the "Green Fairy" with Absinthe Sour, a classic cocktail that captures the essence of this iconic spirit with a modern twist. Cheers!
        `,
    },
    {
        "title": "Arago: An Exquisite Symphony of Cognac, Banana Liqueur, and Cream",
        "ingredients": "Cognac, Banana liquor, Cream",
        "number": "arago-cocktail",
        "taste": "Marries the rich sophistication of Cognac with the luscious allure of Banana Liqueur and velvety Cream",
        "description": "Arago transports the senses to exotic destinations, embracing the essence of far-off lands with its tropical flavors...",
        "body": `
            <h3>Introduction:</h3>

            Experience the luxurious and indulgent blend of Arago, a classic cocktail that tantalizes the taste buds with its exquisite fusion of Cognac, Banana Liqueur, and Cream.

            <h3>The Richness of Cognac:</h3>

            Savor the velvety warmth and complex flavors of Cognac, a premium spirit that adds a touch of refinement to this timeless libation.

            <h3>Banana's Luscious Temptation:</h3>

            The allure of Banana Liqueur adds a luscious and tropical twist to Arago, imparting a delightful sweetness and inviting aroma.

            <h3>Velvety Smooth Cream:</h3>

            The addition of smooth Cream creates a luxurious texture, balancing the richness of Cognac and the sweetness of Banana Liqueur.

            <h3>An Exotic Journey:</h3>

            Arago transports the senses to exotic destinations, embracing the essence of far-off lands with its tropical flavors.

            <h3>A Taste of Timelessness:</h3>

            The Arago cocktail pays homage to the classic era of mixology, carrying the legacy of timeless libations into the present.

            <h3>A Dessert in a Glass:</h3>

            Sip on Arago as a delectable dessert cocktail, the creamy and sweet combination making it a delightful after-dinner treat.

            <h3>Indulgence Personified:</h3>

            With its decadent ingredients, Arago is an indulgence personified, elevating any occasion with its lavish profile.

            <h3>Meticulous Craftsmanship:</h3>

            The art of creating Arago lies in the meticulous blending of Cognac, Banana Liqueur, and Cream, achieving a perfect harmony of flavors.

            <h3>A Captivating Presentation:</h3>

            Adorned with a delicate banana slice or a dusting of cocoa powder, Arago is a sight to behold, offering a treat for the eyes and palate.

            <h3>A Toast to Sophistication:</h3>

            Raise a glass of Arago to celebrate the marriage of elegance and indulgence, a classic cocktail that stands the test of time with flair. Cheers!
        `,
    },
    {
        "title": "Sidecar: A Timeless Elixir of Cognac, Cointreau, and Lemon",
        video: 'DdUPNCybbA4',
        "ingredients": "Cognac, Cointreau, Lemon juice",
        "number": "sidecar-cocktail",
        "taste": "Harmoniously blends the richness of Cognac with the zesty essence of Cointreau and fresh Lemon juice",
        "description": "With just three ingredients, the Sidecar showcases the beauty of simplicity, allowing each element to shine in perfect harmony.",
        "body": `
            <h3>Introduction:</h3>

            Indulge in the allure of the Sidecar, a classic cocktail that captures the essence of sophistication and indulgence with its impeccable combination of Cognac, Cointreau, and Lemon.

            <h3>The Opulence of Cognac:</h3>

            Embark on a sensory journey with the opulent notes of Cognac, its velvety warmth and intricate flavors captivating the palate.

            <h3>A Burst of Citrus:</h3>

            The vibrant essence of Cointreau infuses the drink with a burst of citrus, adding a zesty and refreshing twist to the classic blend.

            <h3>Tangy Elegance:</h3>

            The tangy allure of fresh Lemon juice perfectly balances the richness of Cognac and Cointreau, creating a harmonious and elegant elixir.

            <h3>A Toast to History:</h3>

            The Sidecar boasts a history steeped in sophistication, harkening back to the golden age of classic cocktails, making it a true connoisseur's choice.

            <h3>A Timeless Classic:</h3>

            The enduring charm of the Sidecar lies in its timeless appeal, a libation that has stood the test of time and remains beloved by cocktail enthusiasts.

            <h3>An Artful Presentation:</h3>

            Served in a chilled cocktail glass with a sugared rim, the Sidecar is a visual delight that sets the stage for an exceptional drinking experience.

            <h3>The Perfect Aperitif:</h3>

            Enjoy the Sidecar as a pre-dinner aperitif, stimulating the appetite and setting the tone for an evening of refinement.

            <h3>Crafted to Perfection:</h3>

            The art of crafting a flawless Sidecar lies in the delicate balance of its ingredients, a testament to the skill of the mixologist.

            <h3>Sophistication in Simplicity:</h3>

            With just three ingredients, the Sidecar showcases the beauty of simplicity, allowing each element to shine in perfect harmony.

            <h3>Cheers to Elegance:</h3>

            Raise a glass of Sidecar to celebrate the elegance and timelessness of this classic cocktail, an embodiment of refined taste and pleasure. Cheers!
        `,
    },
    {
        "title": "Stinger: An Elegant Blend of Brandy and Crème de Menthe",
        "ingredients": "Brandy, Crème de menthe",
        "number": "stinger-cocktail",
        "taste": "Epitomizes sophistication with its smooth fusion of Brandy and cool Crème de Menthe, delivering a refreshing and timeless libation.",
        "description": "Stinger showcases the art of simplicity, relying on just two ingredients to create a truly captivating drink.",
        "body": `
            <h3>Introduction:</h3>

            Step into the world of elegance and refinement with Stinger, a classic cocktail that artfully marries the boldness of Brandy with the refreshing coolness of Crème de Menthe.

            <h3>A Symphony of Brandy:</h3>

            Savor the rich and complex notes of Brandy, its velvety warmth caressing the senses with every sip.

            <h3>Refreshing Coolness:</h3>

            The addition of Crème de Menthe introduces a refreshing coolness that elevates the entire experience, making it perfect for any occasion.

            <h3>A Dance of Flavors:</h3>

            Stinger delights the palate with a delightful dance of flavors, where the richness of Brandy meets the invigorating touch of mint.

            <h3>A Cocktail with History:</h3>

            Stinger boasts a storied past, dating back to a bygone era of classic cocktails, adding an air of nostalgia to the drink.

            <h3>A Symbol of Timelessness:</h3>

            The enduring appeal of Stinger lies in its timelessness, a libation that has charmed generations and remains as captivating today.

            <h3>A Subtle Sweetness:</h3>

            The subtle sweetness of Crème de Menthe beautifully complements the depth of Brandy, striking a harmonious balance.

            <h3>An After-Dinner Delight:</h3>

            Sip on Stinger as a delightful after-dinner treat, providing a moment of relaxation and indulgence.

            <h3>The Art of Simplicity:</h3>

            Stinger showcases the art of simplicity, relying on just two ingredients to create a truly captivating drink.

            <h3>A Sophisticated Toast:</h3>

            Raise a Stinger to toast to the classic elegance it embodies, celebrating moments of refinement and sophistication. Cheers!
        `,
    },
    {
        "title": "Grasshopper: A Creamy Delight Infused with Crème de Menthe and Crème de Cacao",
        "ingredients": "Crème de menthe, Crème de cacao, Cream",
        "number": "grasshopper-cocktail",
        "taste": "Blend of Crème de Menthe, Crème de Cacao, and cream, offering a refreshing mint-chocolate indulgence",
        "description": "Grasshopper holds a nostalgic charm, reminiscent of the elegant cocktail era that continues to captivate modern enthusiasts.",
        "body": `
            <h3>Introduction:</h3>

            Indulge in the luscious world of Grasshopper, a classic cocktail that harmoniously combines the invigorating flavors of Crème de Menthe and the rich sweetness of Crème de Cacao, all enveloped in a creamy embrace.

            <h3>Minty Freshness:</h3>

            The striking essence of Crème de Menthe brings a burst of minty freshness, creating a delightful and invigorating sensation.

            <h3>Chocolate Decadence:</h3>

            Savor the velvety allure of Crème de Cacao, infusing the cocktail with rich chocolate decadence that tantalizes the taste buds.

            <h3>Creamy Embrace:</h3>

            The addition of cream gives Grasshopper its creamy texture, embracing the palate with a smooth and luxurious mouthfeel.

            <h3>A Sensational Dessert in a Glass:</h3>

            Grasshopper presents itself as a sensational dessert in a glass, satisfying sweet cravings with every delectable sip.

            <h3>A Green Indulgence:</h3>

            Its vibrant green hue adds a touch of elegance, making Grasshopper visually appealing and inviting.

            <h3>An After-Dinner Delight:</h3>

            Grasshopper serves as a perfect after-dinner treat, providing a refreshing conclusion to a memorable meal.

            <h3>The Art of Balancing Flavors:</h3>

            Mixologists skillfully balance the trio of ingredients, ensuring each sip delivers a harmonious blend of mint and chocolate.

            <h3>A Nostalgic Classic:</h3>

            Grasshopper holds a nostalgic charm, reminiscent of the elegant cocktail era that continues to captivate modern enthusiasts.

            <h3>Cheers to Grasshopper:</h3>

            Raise your glass to Grasshopper, a timeless classic that embodies the delightful union of minty freshness, chocolate allure, and creamy goodness.
        `,
    },
    {
        title: 'Nectar Hideaway - Unravel the Enchanting World of Affordable Cocktails',
        description: 'At Nectar Hideaway, we take pride in crafting a hidden gem that offers an unmatched combination of sophistication and affordability',
        number: "nectar-hideaway-enchanting-world-of-affordable-cocktails",
        image: '/2023/07/268407058_3402822683332337_1029323957192658955_n.jpg',
        body: `
        <h3>Introduction:</h3>

        Welcome to Nectar Hideaway, your ultimate destination for an unforgettable cocktail experience that won't break the bank. Nestled atop the iconic 20 Tràng Tiền, our speakeasy-style rooftop bar offers a captivating ambiance and a unique selection of affordable cocktails. Embrace the allure of Nectar Hideaway as we invite you to indulge in our delightful concoctions during our exclusive 30% off Happy Hour from 5 pm to 9:00 pm.

        <h3>Introducing Nectar Hideaway:</h3>
        At Nectar Hideaway, we take pride in crafting a hidden gem that offers an unmatched combination of sophistication and affordability. Step into a world of vintage charm and intimate gatherings, where every sip of our carefully curated cocktails tells a story of passion and craftsmanship.

        <h3>Speakeasy Vibes:</h3>
        Our bar channels the spirit of the Prohibition era, with speakeasy vibes that transport you back to the glamorous and mysterious days of the 1920s. Unwind in a stylish setting reminiscent of secret hideaways where hidden pleasures are savored.

        <h3>Rooftop Elegance:</h3>
        Located on the rooftop of the prestigious 20 Tràng Tiền, Nectar Hideaway boasts breathtaking views of the city. Immerse yourself in the lively ambiance of Hanoi while sipping on your favorite cocktail against a backdrop of dazzling city lights.

        <h3>A Journey of Flavors:</h3>
        Discover a diverse menu of affordable cocktails meticulously handcrafted by our talented mixologists. From timeless classics to innovative concoctions, each drink is an exquisite blend of flavors that promises to tantalize your taste buds.

        <h3>Happy Hour Extravaganza:</h3>
        Indulge in an incredible offer during our Happy Hour, where all drinks are at an incredible 50% off from 5 pm to 9:00 pm. It's the perfect opportunity to savor our signature cocktails without breaking the bank.

        <h3>Our Cocktail Recommendations:</h3>

        "Starry Night" - Crafting a cocktail as exquisite as Starry Night requires precision and artistry
        "Silence is Golden" - Inspired by the essence of Zen philosophy, Silence is Golden encourages you to immerse yourself in the present moment
        "Whispering of the woods" - Unravel the mysteries of this exceptional cocktail as you savor the beauty of its flavors
        "L'essential" - A Symphony of Exquisite Flavors

        <h3>Unmatched Service:</h3>
        At Nectar Hideaway, we prioritize the comfort and satisfaction of our valued guests. Our warm and attentive staff is always ready to guide you through our menu and assist you in choosing the perfect cocktail to suit your preferences.

        <h3>The Perfect Gathering Spot:</h3>
        Whether you're planning a relaxed evening with friends or a romantic date, Nectar Hideaway offers the ideal ambiance for your special moments. Enjoy lively conversations or bask in the serene atmosphere while sipping on our affordable and delectable cocktails.

        <h3>Conclusion:</h3>

        Embark on an extraordinary journey of taste and ambiance at Nectar Hideaway, where affordable cocktails and speakeasy vibes come together to create an unparalleled experience. We invite you to join us for our exclusive Happy Hour from 5 pm to 9:00 pm and explore the enchanting world of Nectar Hideaway. Come and let the magic of our affordable cocktails elevate your evenings to new heights.

        Visit us at Nectar Hideaway, 20 Tràng Tiền, Hanoi, and savor the art of mixology at its finest. Cheers to unforgettable moments, delightful flavors, and memories that will linger for a lifetime.

        `
    },
    {
        title: 'Nectar Hideaway: A Charming Cocktail Bar in the Heart of Hanoi',
        description: 'At Nectar Hideaway, mixology is an art form. Our bartenders are passionate about their craft, creating masterpieces in every glass they serve...',
        number: 'nectar-hideaway-a-charming-cocktail-bar-in-the-heart-of-hanoi',
        // image: '',
        body: `
            <h3>Introduction</h3>

            Welcome to Nectar Hideaway, a delightful cocktail bar nestled discreetly in the heart of Hanoi. If you're seeking an unforgettable experience, exquisite cocktails, and an ambiance that whispers charm, look no further. Let us take you on a journey through our speakeasy-style establishment, where every detail has been meticulously crafted to ensure your utmost satisfaction.

            <h3>Prime Location and Stunning Views</h3>
            Nectar Hideaway boasts a prime location, conveniently situated on 20 Trang Tien Street, right across from the luxurious Hotel de l'Opera Hanoi and in close proximity to the iconic Hanoi Opera House. Our strategic location offers not only easy access but also breathtaking views. Two open balconies allow you to immerse yourself in the majestic sight of the Hanoi Opera House and the vibrant Trang Tien Street, while a cozy private outdoor balcony caters to those seeking tranquility and privacy.

            <h3>Embrace the Speakeasy Vibe</h3>
            Step into a world of exclusivity and embrace the unique ambiance of a speakeasy bar. Nectar Hideaway offers an intimate and private setting, ensuring an unparalleled experience for our discerning customers. The mysterious allure of our speakeasy-style décor combined with impeccable service guarantees a memorable evening.

            <h3>Exquisite Cocktails and Wine Selection</h3>
            Our experienced and talented bartenders take great pride in creating a wide range of delectable cocktails that cater to every palate. From classic favorites like "Sex on the Beach," "Mojito," and "Negroni" to our signature creations, such as "Trang Tien Elixir" and "Opera Sunset," each sip will transport you to a world of flavors.

            For wine enthusiasts, our selection of premium wines is bound to impress. Whether you prefer a crisp Sauvignon Blanc or a full-bodied Cabernet Sauvignon, our extensive wine list has something to please every connoisseur.

            <h3>The Art of Mixology</h3>
            At Nectar Hideaway, mixology is an art form. Our bartenders are passionate about their craft, creating masterpieces in every glass they serve. We take pride in offering a diverse menu of 8 signature cocktails, 2 signature mocktails, and over 60 classic cocktails. If you have a personal favorite that's not on the menu, don't hesitate to request it, and our talented bartenders will gladly prepare it for you. For those seeking an extra touch of luxury, indulge in our specially curated bespoke cocktails, tailored to your preferences.

            <h3>Happy Hour Delights</h3>
            We understand the value of a great deal, and that's why we offer an incredible 30% discount on all drinks during our Happy Hour from 5:30 PM to 9:00 PM. It's the perfect opportunity to explore our extensive cocktail menu without breaking the bank.

            <h3>Warm and Attentive Service</h3>
            At Nectar Hideaway, hospitality is at the core of our values. Our friendly and attentive staff are always eager to assist, whether it's recommending a cocktail, sharing local insights, or ensuring your visit exceeds expectations. Fluent in English, our team is well-equipped to cater to international guests, providing a seamless and enjoyable experience for all.

            <h3>A Cozy Haven for All Occasions</h3>
            Our bar exudes an atmosphere of sophistication and warmth, making it an ideal setting for various occasions. Whether you're looking for a romantic date night, a casual catch-up with friends, or a cozy spot to unwind after a day of exploring Hanoi, Nectar Hideaway is the perfect destination.

            <h3>Early Birds Welcome</h3>
            For the early risers, Nectar Hideaway opens its doors at 5:15 PM, allowing you to start your evening with the perfect cocktail or glass of wine. Enjoy a tranquil setting and kickstart your night on a high note.

            <h3>The Story Behind Nectar Hideaway</h3>
            The journey to our bar begins with a charming secret - a hidden alley leading to a staircase that takes you to the fourth floor. Though initially challenging to find, the discovery is well worth the effort. Once inside, you'll realize you've stumbled upon a haven, where every detail, from the décor to the drinks, has been carefully curated to offer an unforgettable experience.

            <h3>Conclusion</h3>

            Nectar Hideaway stands out as a hidden gem amidst the vibrant city of Hanoi. Our prime location, captivating views, speakeasy ambiance, exquisite cocktails, and warm hospitality combine to create a truly exceptional experience for our valued guests.

            So, whether you're a cocktail enthusiast, a wine lover, a couple seeking a romantic evening, or a group of friends ready to unwind, we invite you to discover the magic of Nectar Hideaway. Step into our world, and let us create a memorable night for you.

            Visit us today and elevate your cocktail experience at Nectar Hideaway - where unforgettable moments are waiting to be savored.
        
        `
    }

];

export const Signatures = [
    {
        title: `Starry Night Unveiling the Enchantment of Cocktail: A Painterly Delight`,
        taste: 'Fresh basil adds a herbal twist, while thyme and oregano infuse the cocktail with aromatic undertones that tantalize the senses',
        ingredients: 'Gin, Basil, Thyme, Oregano, Creme de Violet, Lemon bitter, Lime cordial',
        image: '/2023/08/starryNight-1-scaled.jpg',
        number: `starry-night-cocktail`,
        description: `This cocktail invites you to explore the rich harmony of gin infused with fragrant basil, thyme, and oregano`,
        body: `
        <h3>Introduction:</h3>
        Step into a world of artistic inspiration and exquisite flavors with our signature cocktail, the "Starry Night." Crafted to capture the essence of the famous painting by Van Gogh, this masterpiece in a glass combines gin, aromatic herbs like basil, thyme, and oregano, along with the delicate notes of Creme de Violet, lemon bitters, and lime cordial. Join us at our bar to experience the enchantment of the "Starry Night" cocktail, where artistry meets mixology.
        
        <h3>1. The Distinctive Flavors of Starry Night:</h3>
        Embark on a sensory journey with the "Starry Night" cocktail. The carefully curated herbal blend, enriched by the fragrant basil, thyme, and oregano, offers a symphony of flavors. Delicate violet aromas from Creme de Violet dance elegantly alongside the citrus candy undertones of lemon bitters and lime cordial. This Gin-based masterpiece is a harmonious blend of herbal, floral, and citrus notes.
        
        <h3>2. Exploring the Starry Night Cocktail:</h3>
        <h4>History and Inspiration:</h4>
        The "Starry Night" cocktail is a true fusion of art and mixology. Inspired by Van Gogh's iconic painting, it was conceived as a way to translate the swirling beauty of the night sky into a sensory experience. The cocktail's creation mirrors the artist's palette, blending ingredients with precision to evoke the emotions of a starlit night.
        
        <h4>The Allure of Starry Night:</h4>
        The "Starry Night" cocktail is not merely a drink; it's a piece of art that engages all the senses. The vibrant purple hue, reminiscent of the night sky, is adorned with golden flecks that shimmer like stars. The cocktail encapsulates the whimsy and magic of the painting, drawing cocktail enthusiasts into a realm of enchantment.
        
        <h4>Serving and Enjoying:</h4>
        Serving the "Starry Night" cocktail is an experience in itself. Presented in an elegant glass, its deep purple hue and golden sparkles evoke the image of a celestial canvas. Sip slowly to appreciate the layers of flavors unfolding on your palate. The cocktail is best enjoyed in a relaxed setting, where you can savor its complexity and allow the flavors to linger.
        
        <h4>For Whom is Starry Night:</h4>
        The "Starry Night" cocktail appeals to those with an appreciation for both art and flavor exploration. It's perfect for those who seek a multisensory experience and are intrigued by the intersection of aesthetics and mixology. Whether you're a connoisseur of fine art, a cocktail aficionado, or someone looking for a unique and captivating beverage, the "Starry Night" cocktail offers a taste of both worlds.
        
        <h3>Conclusion:</h3>
        Elevate your senses with the "Starry Night" cocktail, a drink that transports you to the enchanting world of Van Gogh's masterpiece. Join us at our bar to immerse yourself in the artistry of flavor and visual aesthetics. Whether you're drawn by the allure of art, the intrigue of inventive mixology, or simply a desire to experience a truly unique libation, the "Starry Night" cocktail invites you to indulge in a sensory masterpiece that captures the essence of an iconic painting.
        `,
    },

    {
        title: `Lullaby Martini: Experience Tranquility `,
        taste: '',
        ingredients: 'Gin, Bianco vermouth, Chamomile, Licorice, Goji berries',
        number: `lullaby-martini-cocktail`,
        image: '/2023/08/lullaby-1-scaled.jpg',
        description: `The cocktail's allure lies in its ability to transport guests to a serene oasis. It provides a unique escape through taste, offering a moment `,
        body: `
            <h3>Introduction:</h3>
            Embark on a journey of relaxation and refined flavors with our signature creation, the "Lullaby Martini." Crafted to evoke a sense of calm, this exquisite libation features gin, bianco vermouth, chamomile, licorice, and goji berries. Join us at our bar to immerse yourself in the soothing symphony of tastes that the "Lullaby Martini" offers.

            <h3>1. The Distinctive Flavors of Lullaby Martini:</h3>
            Indulge in a gentle sip of the "Lullaby Martini." Its delicate flavor profile is a blend of sweetness and herbal elegance. Chamomile infuses a calming essence, while licorice adds a touch of enchanting depth. Goji berries provide a subtle tartness, and the gin's botanical notes harmonize perfectly with the bianco vermouth. The result is a harmonious symphony that's simultaneously sweet, herbal, and invigorating.

            <h3>2. Exploring the Lullaby Martini Cocktail:</h3>
            <h4>History and Origin:</h4>
            The "Lullaby Martini" was crafted as a response to the hustle and bustle of modern life, offering a moment of tranquility in a glass. Each ingredient was meticulously chosen to create a sensory experience that's both calming and indulgent, allowing guests to unwind and savor the moment.

            <h4>The Allure of Lullaby Martini:</h4>
            The cocktail's allure lies in its ability to transport guests to a serene oasis. It provides a unique escape through taste, offering a moment of reprieve from the demands of the day. The blend of flavors not only delights the palate but also invites a sense of well-being and relaxation, making it a perfect choice for those seeking respite.

            <h4>Serving and Enjoying:</h4>
            Serving the "Lullaby Martini" is an art form in itself. Presented in an elegant martini glass, its subtle hues and garnishes evoke a sense of tranquility. Sip slowly to fully appreciate the interplay of flavors, allowing the chamomile's soothing notes to take center stage. The cocktail is best enjoyed in a calm setting, where you can fully embrace the experience of serenity.

            <h4>For Whom is Lullaby Martini:</h4>
            The "Lullaby Martini" cocktail is a perfect choice for those who appreciate both sophistication and tranquility. It's an ideal option for individuals seeking a refined cocktail experience that offers more than just taste. Whether you're a connoisseur of fine spirits, a lover of herbal blends, or someone who seeks solace in elegant indulgence, this cocktail invites you to relish a moment of serenity.

            <h3>Conclusion:</h3>
            Elevate your senses with the "Lullaby Martini" cocktail, a drink that offers a retreat from the chaos of everyday life. Join us at our bar to immerse yourself in the serene flavors and indulge in a moment of tranquility. Whether you're drawn by the allure of sophisticated flavors, the promise of relaxation, or simply the desire to experience a libation that soothes the senses, the "Lullaby Martini" cocktail invites you to savor the art of balance and elegance in mixology.
        `,
    },

    {
        title: `Silence is Golden: Unveiling the Zen of Mixology`,
        taste: 'A harmonious concoction with a touch of spice and floral elegance, creating a serene and meditative experience.',
        image: '/2023/08/silenceGolden-1-scaled.jpg',
        ingredients: 'Tequila, Thai matcha, Wasabi, Orange flower water',
        number: `silence-is-golden-cocktail`,
        description: `Inspired by the essence of Zen philosophy, Silence is Golden encourages you to immerse yourself in the present moment. Just like the stillness of a peaceful morning...`,
        body: `
        
        <h3>Introduction:</h3>

        In the heart of our mixology sanctuary, we present to you the extraordinary concoction known as Silence is Golden. This exquisite libation is a tribute to the art of mindfulness and the serene beauty of the Far East. Step into a realm of tranquility as we reveal the secret behind the harmonious blend of Tequila, Thai matcha, wasabi, and orange flower water, creating a cocktail that will elevate your senses and soothe your soul.

        <h3>Embracing Zen in a Glass:</h3>

        Inspired by the essence of Zen philosophy, Silence is Golden encourages you to immerse yourself in the present moment. Just like the stillness of a peaceful morning, this cocktail invites you to savor each sip and let the worries of the world dissolve into the abyss. Close your eyes and let your senses guide you on a journey of self-discovery, where time slows down and the chaos of life fades away.

        <h3>The Ingredients:</h3>

        At the heart of Silence is Golden lies the bold and distinct flavor of tequila, the foundation upon which this masterpiece is built. The delicate infusion of Thai matcha adds an earthy depth, while the gentle hint of wasabi adds a touch of spice, awakening the senses with every sip. The ethereal essence of orange flower water completes the symphony, bringing an air of floral elegance that lingers on the palate.

        <h3>The Harmony of Preparation:</h3>

        Preparing Silence is Golden is an art in itself, requiring patience and finesse. Our mixologists meticulously measure each ingredient, ensuring that the flavors blend harmoniously to create a balanced and soothing elixir. The Thai matcha is gently brewed, allowing its vibrant green color and earthy essence to infuse the cocktail. The wasabi is carefully added, enough to tantalize the taste buds without overpowering the delicate balance. The cocktail is then lovingly stirred, encouraging the flavors to dance together like a meditative ritual.

        <h3>The Experience:</h3>

        As you take your first sip of Silence is Golden, a sense of calm washes over you. The velvety smoothness of tequila caresses your palate, while the Thai matcha adds a subtle layer of complexity. The gentle warmth of wasabi slowly unfurls, awakening your senses with a gentle embrace. The delicate essence of orange flower water lingers, a reminder of the tranquility that lies within. Each sip is a journey into mindfulness, a celebration of the present moment.

        <h3>Discover the Serenity:</h3>

        We invite you to join us at our bar and experience the serenity of Silence is Golden. Let this enchanting elixir transport you to a world of peace and harmony, where time stands still, and the noise of the world fades away. Indulge in this transcendent cocktail, crafted with care and reverence for the art of mixology and the beauty of the Far East.

        Join us at our bar, where we celebrate the wisdom of Zen philosophy through the art of mixology. Let Silence is Golden be your guide to mindfulness and the discovery of inner peace. Step into a world of serenity, where each sip is a moment of Zen. Embrace the silence and unlock the golden elixir of your soul. Cheers to the beauty of stillness and the bliss of Silence is Golden!
        `,
    },
    {
        title: `Whispering of the woods`,
        taste: 'Sweet and woody aroma, balanced with a hint of tangy lemon bitterness',
        image: '/2023/08/whispering-1-scaled.jpg',
        ingredients: 'Bourbon, Sandalwood, Lemon bitter, Kaffir lime cordial',
        number: `whispering-of-the-woods-cocktail`,
        description: `Crafted with utmost finesse, this exquisite libation offers a captivating blend of flavors that will transport you to the heart of a serene forest...`,
        body: `
        Whispering of the Woods" - Embrace the Enchantment of this Exquisite Cocktail

        Step into a world of mystique and enchantment with our signature cocktail, "Whispering of the Woods." Crafted with utmost finesse, this exquisite libation offers a captivating blend of flavors that will transport you to the heart of a serene forest.

        At the core of this delightful concoction lies the richness of bourbon, adding a warm and comforting essence that embraces your senses. Complementing the bourbon is the unique touch of sandalwood, infusing the drink with an alluring aroma that lingers on the palate.

        But it doesn't stop there – a splash of lemon bitter awakens your taste buds with a tangy twist, harmonizing perfectly with the subtle notes of kaffir lime cordial, which adds a refreshing zing to each sip.

        Whisked together with precision and served over the finest ice, "Whispering of the Woods" takes you on an unparalleled journey of flavors, capturing the essence of nature's tranquil embrace.

        Join us to experience this enchanting elixir firsthand. Our skilled bartenders take pride in creating each "Whispering of the Woods" with love and artistry, ensuring a remarkable and unforgettable cocktail experience for every guest.

        Indulge in the magic of "Whispering of the Woods" at Nectar Hideaway, where each sip reveals a story of nature's whispers and the charm of a forest retreat. Unravel the mysteries of this exceptional cocktail as you savor the beauty of its flavors, surrounded by the welcoming ambiance of our bar.

        Come, let the woods speak to you, one sip at a time. We eagerly await your visit to embark on this extraordinary journey with us. Cheers to unforgettable moments and exceptional cocktails!
        `,
    },

    {
        title: `L'essential: A Symphony of Exquisite Flavors`,
        taste: `Jasmine cordial's floral essence, complemented by cardamom's warmth, fennel's earthiness, and a hint of tangy sour apple. Balanced, not overly sweet, with a captivating touch of spice`,
        image: '/2023/08/lessential-1-scaled.jpg',
        ingredients: 'Vodka, Jasmine cordial, Cardamom, Fennel, Sour apple',
        number: `lessential-cocktail`,
        description: `The journey of indulgence begins with the velvety smoothness of vodka, setting the stage for the blossoming floral notes of jasmine that dance on your palate`,
        body: `
            <h3>Introduction:</h3>
            Indulge in a harmonious fusion of flavors with our signature cocktail, "L'essential." Crafted to embody the essence of Antoine de Saint-Exupéry's quote from "Le Petit Prince," this exquisite concoction blends the finesse of vodka, the delicacy of jasmine cordial, the warmth of cardamom and fennel, and the zing of sour apple. Join us at our bar to experience the captivating orchestration of taste that "L'essential" brings to your glass.

            <h3>1. The Distinctive Flavors of L'essential:</h3>
            Embark on a journey through taste with the "L'essential" cocktail. A symphony of flavors awaits - the vodka's smoothness harmonizes with the gentle tartness of sour apple. The cardamom and fennel lend a subtle hint of warmth and spice, while the jasmine cordial introduces a floral elegance that encapsulates the essence of Antoine de Saint-Exupéry's words.

            <h3>2. Exploring the L'essential Cocktail:</h3>
            <h4>History and Inspiration:</h4>
            The "L'essential" cocktail is an ode to literature and mixology, drawing inspiration from the profound words of "Le Petit Prince." Just as the book's themes encompass life's essentials, this cocktail represents a fusion of flavors that capture the essence of life's diverse experiences.

            <h4>The Allure of L'essential:</h4>
            The allure of "L'essential" lies in its ability to evoke emotions through taste. The transparency of the cocktail, akin to crystal-clear water, mirrors the purity of the book's message. Each sip is a journey through layers of taste, inviting guests to contemplate the profound beauty of simplicity and complexity in a single drink.

            <h4>Serving and Enjoying:</h4>
            Serving the "L'essential" cocktail is a reflection of its elegance. Presented in a clear glass to showcase its transparent hue, the cocktail invites you to appreciate its visual simplicity. Sip slowly to let the flavors unfold on your palate. Allow yourself to be immersed in the experience, much like delving into the pages of a cherished book.

            <h4>For Whom is L'essential:</h4>
            The "L'essential" cocktail is perfect for those who seek more than just a drink; it's for those who desire an experience that resonates on a deeper level. Whether you're a lover of literature, an admirer of intricate flavors, or simply someone looking to enjoy a thoughtfully crafted libation, "L'essential" invites you to savor life's essentials in both taste and spirit.

            <h3>Conclusion:</h3>
            Elevate your senses with the "L'essential" cocktail, a drink that encapsulates the essence of literary wisdom and flavor innovation. Join us at our bar to indulge in this symphony of taste, where each ingredient plays a unique note to create a harmonious blend. Whether you're seeking an intellectually stimulating experience or a journey through intricate flavors, "L'essential" promises an unforgettable sip that celebrates the artistry of mixology and the beauty of literary inspiration.
        `,
    },

    {
        title: 'Garden of Words A Garden of Flavors',
        taste: 'Spicy osmanthus, mild bitterness, and sweet-sour harmony with a hint of floral bouquet',
        image: '/2023/08/garden-1-scaled.jpg',
        ingredients:'Rum, Hibiscus, Osmanthus, Coconut Liquor, Longan Cordial, Florals Bun',
        number: 'garden-of-words-cocktail',
        description: `Drawing from the anime film's captivating narrative, this cocktail captures the spirit of emotions that unfold in the garden. Each ingredient is carefully chosen to weave a tapestry of flavors that reflect the depth and nuances of the film.`,
        body: `
            <h3>Introduction:</h3>
            Step into a world of sensory delight with our signature cocktail, the "Garden of Words." Crafted to capture the essence of the anime film "The Garden of Words," this masterpiece in a glass combines the richness of rum, the allure of hibiscus and osmanthus, the sweetness of coconut liquor, the tang of longan cordial, and the delicate touch of floral buns. Join us at our bar to experience the enchantment of the "Garden of Words" cocktail, where taste meets artistic inspiration.

            <h3>1. The Distinctive Flavors of Garden of Words:</h3>
            Embark on a journey through taste with the "Garden of Words" cocktail. The interplay of flavors is exquisite - the spicy notes of osmanthus intermingle with a mild bitterness from the hibiscus. The harmonious sweet-sour blend of coconut liquor and longan cordial adds depth, while the floral bouquet from the osmanthus and florals bun provides a delightful finish.

            <h4>2. Exploring the Garden of Words Cocktail:</h4>
            History and Inspiration:
            The "Garden of Words" cocktail is a testament to the fusion of cinematic inspiration and mixology. Drawing from the anime film's captivating narrative, this cocktail captures the spirit of emotions that unfold in the garden. Each ingredient is carefully chosen to weave a tapestry of flavors that reflect the depth and nuances of the film.

            <h4>The Allure of Garden of Words:</h4>
            This cocktail's allure is in its storytelling. Just as the film transports you to a serene garden, the "Garden of Words" cocktail transports your palate to a realm of taste and sensations. The combination of ingredients evokes a sense of connection with the film's themes, inviting guests to immerse themselves in an experience that's both artistic and flavorful.

            <h4>Serving and Enjoying:</h4>
            Serving the "Garden of Words" cocktail is a presentation of artistry. The elegant glass, garnished with a delicate floral bun, sets the stage for the sensory journey ahead. Sip slowly to savor the layers of flavors that unfold on your palate. Allow the taste to transport you to the garden's ambiance and emotions.

            <h4>For Whom is Garden of Words:</h4>
            The "Garden of Words" cocktail appeals to those who appreciate the fusion of art, cinema, and mixology. It's perfect for enthusiasts seeking an immersive experience that stimulates both taste and emotion. Whether you're a lover of anime, a cinephile, a cocktail aficionado, or someone intrigued by innovative flavor profiles, the "Garden of Words" cocktail offers a unique and evocative choice.

            <h3>Conclusion:</h3>
            Elevate your senses with the "Garden of Words" cocktail, a drink that bridges the gap between art and flavor. Join us at our bar to immerse yourself in the narrative-inspired mixology. Whether you're drawn by the allure of cinematic storytelling, the intrigue of inventive flavors, or a desire to experience something truly unique, the "Garden of Words" cocktail invites you to indulge in a sensory masterpiece that pays homage to the spirit of the film.
        `
    },
    {
        title: `Boulevard Of Broken Dreams: Experience the Melancholic Elegance of "Boulevard Of Broken Dreams" Cocktail`,
        taste: 'Slightly sweet, gentle bitterness, with a touch of spiced floral aroma from Ylang Ylang tea',
        ingredients: 'Gin, Bianco vermouth, Chamomile, Licorice, Goji berries',
        number: `boulevard-of-broken-dreams-cocktail`,
        image: `/2023/08/brokenheart-2-scaled.jpg`,
        description: `The "Boulevard Of Broken Dreams" cocktail draws inspiration from Green Day's poignant song, capturing its essence in a blend of flavors`,
        body: `
            <h3>Introduction:</h3>
            Indulge in a sensory journey with our signature cocktail, the "Boulevard Of Broken Dreams." Crafted to resonate with the emotive lyrics of Green Day's iconic song, this exquisite blend features vodka, jasmine cordial, cardamom, fennel, and sour apple. Join us at our bar to savor the poignant symphony of flavors that the "Boulevard Of Broken Dreams" cocktail offers, as it pays homage to both music and mixology.

            <h4>1. The Distinctive Flavors of Boulevard Of Broken Dreams:</h4>
            Embark on a taste exploration with the "Boulevard Of Broken Dreams" cocktail. Its flavor profile is a delicate dance – a gentle sweetness mingles with subtle bitterness. A touch of spiced floral aroma from Ylang Ylang tea elevates the experience, reminiscent of the wistful emotions conveyed by the song.

            <h4>2. Exploring the Boulevard Of Broken Dreams Cocktail:</h4>
            History and Inspiration:
            The "Boulevard Of Broken Dreams" cocktail draws inspiration from Green Day's poignant song, capturing its essence in a blend of flavors. It's a tribute to the introspective emotions conveyed by the lyrics and serves as a reminder that beauty can be found even in melancholy.

            <h4>The Allure of Boulevard Of Broken Dreams:</h4>
            The cocktail's allure lies in its ability to evoke emotion through taste. It captures the essence of nostalgia and longing, much like the song that inspired it. The bitter-sweet symphony of flavors invites guests to reflect on their own journeys, much like walking down the boulevard of memories.

            <h4>Serving and Enjoying:</h4>
            Serving the "Boulevard Of Broken Dreams" cocktail is an experience in evoking emotions. Presented in a glass that reflects its moody elegance, the cocktail's hues mirror the introspective nature of the song. Sip slowly, allowing the flavors to unfold on your palate, and savor the bittersweet journey it takes you on.

            <h4>For Whom is Boulevard Of Broken Dreams:</h4>
            The "Boulevard Of Broken Dreams" cocktail appeals to those who appreciate the fusion of music, emotions, and mixology. It's perfect for those seeking a multi-layered experience that resonates on an emotional level. Whether you're a music enthusiast, an admirer of complex flavors, or simply someone drawn to profound experiences, this cocktail offers a unique and evocative choice.

            <h3>Conclusion:</h3>
            Elevate your senses with the "Boulevard Of Broken Dreams" cocktail, a libation that captures the essence of music and emotion in a single glass. Join us at our bar to immerse yourself in the melancholic elegance it brings. Whether you're drawn by the allure of musical storytelling, the intrigue of inventive flavors, or a desire to experience something deeply emotive, the "Boulevard Of Broken Dreams" cocktail invites you to savor the beauty that can be found even in moments of contemplation and reflection.
        `,
    },

    {
        title: `Nectar de Vie: Indulge in the Enigmatic Delights`,
        taste: 'Sweet, bitter, refreshing, aromatic, with a hint of spice',
        ingredients: 'Gin, Bianco vermouth, Chamomile, Licorice, Goji berries',
        image: '/2023/08/nectarDeVie-1-scaled.jpg',
        number: `nectar-de-vie-cocktail`,
        description: `The cocktail's allure lies in its ability to surprise and delight the senses. The intricate blend of ingredients awakens the palate with layers of taste`,
        body: `
            <h3>Introduction:</h3>
            Embark on a journey of sensory exploration with our exquisite creation, the "Nectar de Vie" cocktail. Crafted to tantalize the taste buds, this masterpiece combines tequila, coffee, sweet basil, orange bitters, and kaffir lime cordial. Join us at our bar to immerse yourself in the captivating symphony of flavors that "Nectar de Vie" offers.

            <h3>1. The Distinctive Flavors of Nectar de Vie:</h3>
            Experience a symphony of flavors with the "Nectar de Vie" cocktail. It's a harmonious blend of sweet and bitter, creating a refreshing palate sensation. The aromatic notes of sweet basil and kaffir lime cordial add depth, while the coffee essence infuses a hint of spice. Orange bitters provide a subtle twist, creating a complex and intriguing taste profile.

            <h3>2. Exploring the Nectar de Vie Cocktail:</h3>
            <h4>History and Origin:</h4>
            The "Nectar de Vie" cocktail emerges from a desire to create a libation that transcends traditional flavors. Its inception was a fusion of creativity and mixology, where each ingredient was carefully chosen to create a balance between sweet and bitter, providing a unique sensory experience.

            <h4>The Allure of Nectar de Vie:</h4>
            The cocktail's allure lies in its ability to surprise and delight the senses. The intricate blend of ingredients awakens the palate with layers of taste, and the aromatic notes invite guests to indulge in an experience that's both refreshing and intriguing. "Nectar de Vie" stands as an embodiment of modern mixology, redefining traditional cocktails.

            <h4>Serving and Enjoying:</h4>
            Serving the "Nectar de Vie" cocktail is an art form in itself. Presented in an elegant glass, its dark hue mirrors the richness of its flavors. Sip slowly to savor the interplay of sweet and bitter, and let the aromatic notes linger. The cocktail is best enjoyed in a relaxed setting, where you can fully appreciate its complexity.

            <h4>For Whom is Nectar de Vie:</h4>
            The "Nectar de Vie" cocktail appeals to those with a taste for innovation and an appreciation for multifaceted flavors. It's perfect for individuals seeking an avant-garde cocktail experience that marries tradition with modernity. Whether you're a seasoned mixology enthusiast, a connoisseur of diverse tastes, or simply someone intrigued by unique flavor combinations, this cocktail promises an exceptional journey.

            <h3>Conclusion:</h3>
            Elevate your senses with the "Nectar de Vie" cocktail, a drink that pushes the boundaries of mixology. Join us at our bar to embark on a sensory adventure where each sip reveals a new layer of complexity. Whether you're drawn by the allure of creative flavors, the intrigue of innovative mixology, or a desire to experience a libation that defies expectations, the "Nectar de Vie" cocktail invites you to indulge in a symphony of taste that redefines the art of cocktail creation.
        `,
    },

    {
        title: `Nothing to say Mocktail: Discover the Sublime Harmony`,
        taste: '',
        ingredients: 'Vegan foam, Raspberry, beetroot, lime',
        image: '/2023/08/nothing-1-scaled.jpg',
        number: `nothing-to-say-mocktail`,
        description: `The mocktail's allure lies in its ability to surprise and delight the palate. Its multi-layered flavors and textures invite guests to embark on a sensory`,
        body: `
            <h3>Introduction:</h3>
            Delight in a symphony of flavors with our exquisite mocktail creation, the "Nothing to Say." Crafted to tantalize the senses, this unique libation features a vegan foam, raspberry, beetroot, and lime. Join us at our bar to experience the captivating blend of taste and texture that "Nothing to Say" offers.

            <h3>1. The Distinctive Flavors of Nothing to Say:</h3>
            Indulge in the "Nothing to Say" mocktail, a delightful fusion of flavors. The sweetness of raspberry and the earthy undertones of beetroot create a harmonious balance. A zing of lime adds a citrusy brightness, while the vegan foam provides a luscious texture. The result is a symphony of taste that's both refreshing and satisfying.

            <h3>2. Exploring the Nothing to Say Mocktail:</h3>
            <h4>History and Origin:</h4>
            The "Nothing to Say" mocktail emerged as a response to the growing demand for unique and innovative non-alcoholic options. Its inception was a result of experimentation and creativity, with each ingredient carefully selected to create a mocktail that's both visually appealing and delightfully flavorful.

            <h4>The Allure of Nothing to Say:</h4>
            The mocktail's allure lies in its ability to surprise and delight the palate. Its multi-layered flavors and textures invite guests to embark on a sensory journey. The combination of sweet, earthy, and citrusy elements creates an experience that's not only enjoyable but also thought-provoking.

            <h4>Serving and Enjoying:</h4>
            Presented in an elegant glass, the "Nothing to Say" mocktail boasts vibrant colors and layers. The vegan foam adds a visual and textural dimension, enhancing the overall aesthetic. Sip slowly to fully appreciate the interplay of flavors, allowing each ingredient to unfold on your palate. The mocktail is best enjoyed in a relaxed setting, where you can fully embrace its complexity.

            <h4>For Whom is Nothing to Say:</h4>
            The "Nothing to Say" mocktail appeals to those seeking a non-alcoholic option that's both sophisticated and innovative. It's perfect for individuals looking to explore new flavor combinations and textures. Whether you're a teetotaler, a mocktail enthusiast, or simply someone curious about the world of non-alcoholic mixology, this mocktail invites you to savor a one-of-a-kind experience.

            <h3>Conclusion:</h3>
            Elevate your senses with the "Nothing to Say" mocktail, a drink that redefines non-alcoholic libations. Join us at our bar to experience the symphony of taste and texture that this mocktail brings. Whether you're drawn by the allure of creative flavors, the intrigue of innovative mixology, or a desire to enjoy a non-alcoholic drink that's far from ordinary, the "Nothing to Say" mocktail invites you to indulge in a symphony of taste that's as captivating as it is refreshing.
        `,
    },
    {
        title: `Swipe to match'a Mocktail: Dive into the Intriguing Blend`,
        taste: '',
        ingredients: 'Vegan Foam, Rich Cream, Matcha, Osmanthus, Orangeflower Water, Lime, Soda',
        image: '/2023/08/matcha-1-scaled.jpg',
        number: `swipe-to-matcha-mocktail`,
        description: ``,
        body: `
            <h3>Introduction:</h3>
            Embark on a flavor-filled journey with our exquisite mocktail creation, the "Swipe to Match'a." Crafted to tantalize taste buds, this unique libation features a blend of vegan foam, rich cream, matcha, osmanthus, orangeflower water, lime, and soda. Join us at our bar to indulge in the captivating symphony of flavors that "Swipe to Match'a" offers.

            <h3>1. The Distinctive Flavors of Swipe to Match'a:</h3>
            Delight in the "Swipe to Match'a" mocktail, a harmonious symphony of flavors. The creaminess of rich cream and the earthy undertones of matcha create a balanced base. The floral notes of osmanthus and the citrusy hint of lime add depth, while the orangeflower water infuses a delicate aroma. The soda provides effervescence, resulting in a tantalizing taste journey that's both refreshing and invigorating.

            <h3>2. Exploring the Swipe to Match'a Mocktail:</h3>
            <h4>History and Origin:</h4>
            The "Swipe to Match'a" mocktail was born from a desire to combine traditional and innovative elements in non-alcoholic mixology. Its creation was inspired by the growing interest in unique and multi-dimensional mocktail experiences. Each ingredient was carefully chosen to create a drink that not only excites the palate but also engages the senses.

            <h4>The Allure of Swipe to Match'a:</h4>
            The mocktail's allure lies in its ability to offer a multi-sensory experience. The interplay of flavors and textures invites guests to savor each layer of taste, creating a delightful and intriguing journey. The combination of earthy, floral, and citrusy elements adds complexity, making it an ideal choice for those seeking a unique and memorable mocktail experience.

            <h4>Serving and Enjoying:</h4>
            Presented in an elegant glass, the "Swipe to Match'a" mocktail is a visual delight. Its vibrant hues and layers offer a feast for the eyes. Sip slowly to fully appreciate the evolving flavors, allowing each ingredient to shine. The mocktail is best enjoyed in a relaxed setting, where you can fully immerse yourself in the array of sensations it offers.

            <h4>For Whom is Swipe to Match'a:</h4>
            The "Swipe to Match'a" mocktail is an ideal choice for individuals who seek an elevated non-alcoholic experience. It appeals to those who appreciate the artistry of mixology and enjoy exploring a diverse range of flavors. Whether you're a mocktail enthusiast, a lover of unique textures, or someone looking to experiment with innovative flavors, this mocktail invites you to swipe right on a journey of taste.

            <h3>Conclusion:</h3>
            Elevate your senses with the "Swipe to Match'a" mocktail, a drink that redefines non-alcoholic libations. Join us at our bar to immerse yourself in the captivating blend of flavors and textures that this mocktail brings. Whether you're drawn by the allure of creative mocktails, the intrigue of multi-dimensional taste experiences, or the desire to enjoy a drink that's both visually stunning and flavorful, the "Swipe to Match'a" mocktail invites you to savor a symphony of flavors that's as unique as it is delightful.
        `,
    },
];

export default BlogData;
