import React from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/bg-2.png'
import bgone from '../../assets/images/background/bg-1.png'
import five from '../../assets/images/resource/wine.png'

const Wine = [
    {
        name: 'Terre Forti Sangiovese Rubicone',
        price: '130.000 / 600.000',
        description: 'Red Wine',
    },
    {
        name: 'Terre Forti Trebbiano Chardonney Rubicone',
        price: '130.000 / 600.000',
        description: ' White Wine',
    },
    {
        name: 'Terre Forti Novebolle Sparkling',
        price: '170.000 / 800.000',
        description: 'Sparkling',
    },
    {
        name: 'DeBortoli Emeri\'s Garden',
        price: '170.000 / 800.000',
        description: 'Rose Wine',
    },
]

function MixDrinks() {
    const filterdMenu = Wine;

    return (
        <>
            <section className="menu-section">

                <div className="left-bg"><img src={bgone} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>

                <div className="right-bg"><img src={Bgtwo} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>

                <div className="auto-container">
                    <div className="title-box centered">
                        {/* <div className="subtitle"><span>Special selection</span></div> */}

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                        </div>
                        <h1>Wines</h1>
                    </div>

                    <div className="tabs-box menu-tabs">
                        <div className="tabs-content">
                            <div className={`tab active-tab`} id="tab-1">
                                <div className="row clearfix">
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index %2 !== 0) return null;
                                                return (
                                                    <div className="dish-block">
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}` }/></Link></div>
                                                            <div className="title clearfix">
                                                                <div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div>
                                                                <div className="price"><span>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                                        <div className="inner">
                                            {filterdMenu.map((item, index) => {
                                                if (index %2 === 0) return null;
                                                return (
                                                    <div className="dish-block">
                                                        <div className="inner-box">
                                                            <div className="dish-image"><Link to="#"><img src={five} alt={`Nectar Hideaway Speakeasy Rooftop Cocktails Bar, ${item.name}` }/></Link></div>
                                                            <div className="title clearfix">
                                                                <div className="ttl clearfix"><h5><Link to="#">{item.name}	</Link></h5></div>
                                                                <div className="price"><span>{item.price}</span></div>
                                                            </div>
                                                            <div className="text desc"><Link to="#">{item.description}</Link></div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default MixDrinks