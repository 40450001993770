import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation} from 'react-router-dom';


function Main() {

    const [active, setActive] = useState();
    const [lastscrool, setlastscrool] = useState(0);

    const [scrolled,setScrolled]=useState(false);
    const [Nav,setNav]=useState(true);

    const location = useLocation();
    const path = location.pathname

    const isHome = path === '/';
    
    const handleScroll=() => {
        
        const offset=window.scrollY;
        setlastscrool(offset);
        
        if(offset > 500 && offset < lastscrool){
            setNav(true);
            setScrolled(true);
        }

        else if(offset > 500 ){
          setNav(false)
        }

        else if(offset > 200){
            setNav(true);
            setScrolled(true);
        }
        
        else{
          setScrolled(false);
          setNav(true);
        }
      }
    
      useEffect(() => {
        window.addEventListener('scroll',handleScroll)
      })

      
    return (

        <>

        {active && 
            <div className="menu-backdrop" style={{ opacity: "1"  , visibility: "visible"}} onClick={() => setActive(false)}></div> }

                
            {Nav && 
                <header className={`main-header ${scrolled && "fixed-header"} header-down`}>
                    <div className="header-top">
                        <div className="auto-container">
                            <div className="inner clearfix">
                                <div className="top-left clearfix">
                                    <ul className="top-info clearfix">
                                        <li><i className="icon far fa-map-marker-alt"></i>4th Floor, 20 Trang Tien, Hoan Kiem, Ha Noi </li>
                                        <li><i className="icon far fa-clock"></i>Open : 05:00 pm - 00:30 am</li>
                                    </ul>
                                </div>
                                <div className="top-right clearfix">
                                    <ul className="top-info clearfix">
                                        {
                                            isHome ?
                                                <li><Link to="/blogs"> Blog </Link> </li>

                                                :

                                                <li><Link to="/"> Home </Link> </li>


                                        }
                                        <li><Link to="/about"> About us </Link> </li>
                                        {/* <li> <a href="https://www.facebook.com/nectar.hideaway" rel="noreferrer" target="_blank">facebook</a></li> */}
                                        <li> <a href="https://www.facebook.com/nectar.hideaway" rel="noreferrer" target="_blank">facebook</a></li>
                                        <li> <a href="https://www.instagram.com/nectar.hideaway/" rel="noreferrer" target="_blank">instagram</a></li>
                                        {/* <li> <a href="https://www.tiktok.com/@nectar_hideaway" rel="noreferrer" target="_blank">tiktok</a></li> */}
                                        {/* <li> <a href="https://goo.gl/maps/C9pqBKUDBcbhWJKn8" rel="noreferrer" target="_blank">Map</a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header> }
            
        </>

    )
}

export default Main