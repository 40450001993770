import React from 'react'
// import bg14 from '../../assets/images/background/bg-14.png'
// import bg15 from '../../assets/images/background/bg-15.png'
// import siconone from '../../assets/images/resource/s-icon-1.png'
// import sicontwo from '../../assets/images/resource/s-icon-2.png'
// import siconthree from '../../assets/images/resource/s-icon-3.png'
// import siconfour from '../../assets/images/resource/s-icon-4.png'
// import service from '../../assets/images/resource/service-image.png'
import logonew from '../../assets/images/resource/favicon.png'


function Aboutservice() {
    return (
        <>
        
            <section className="services-section">
                {/* <div className="left-bg"><img src={bg14} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}
                {/* <div className="right-bg"><img src={bg15} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div> */}
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>our services</span></div>

                        <div className="pattern-image">
                            <img src={require('../../assets/images/icons/separator.svg').default} alt='Nectar Hideaway Speakeasy Rooftop Cocktails Bar' />
                        </div>

                        <h2>Exquisite Restaurant in City</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="s-col col-lg-4 col-md-6 col-sm-12">
                            <div className="inner wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="s-block">
                                    <div className="inner">
                                        <div className="icon-box"><img src={logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                        <h4>Signatures Cocktails & Mocktails</h4>
                                        <div className="text">Unique and Unforgettable Creations</div>
                                    </div>
                                </div>
                                <div className="s-block alternate">
                                    <div className="inner">
                                        <div className="icon-box"><img src={logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                        <h4>Classic Cocktails</h4>
                                        <div className="text">Timeless Classics Collection </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="s-col last col-lg-4 col-md-6 col-sm-12">
                            <div className="inner wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="s-block alternate">
                                    <div className="inner">
                                        <div className="icon-box"><img src={logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                        <h4>Wines</h4>
                                        <div className="text">Exquisite Wine Selection with the Finest Vintages</div>
                                    </div>
                                </div>
                                <div className="s-block">
                                    <div className="inner">
                                        <div className="icon-box"><img src={logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                                        <h4>Spirits</h4>
                                        <div className="text">Exquisite Spirits Collection</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-col col-lg-4 col-md-12 col-sm-12">
                            <div className="inner wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="0ms">
                                <div className="about-service-image"><img src={logonew} alt="Nectar Hideaway Speakeasy Rooftop Cocktails Bar" title="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Aboutservice