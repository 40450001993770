import React, {  useState, useEffect} from 'react'
import { Route, Routes , useLocation} from 'react-router-dom';
import Header from '../components/Header/Main';
import Footer from '../components/Footer/Main';
import Home from '../components/Home/Main';
import Reserve from '../components/Menhome/Contact'
import About from '../components/About/Main';
import { Blogs } from '../components/Blogs';
import { PostDetail } from '../components/Blogs/PostDetail';
import NotFound from '../components/NotFound'
// import Cards from '../components/Cards'


function Index() {

  const [homepage, sethomepage] = useState(false)
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/hometwo"){
        sethomepage(false)
      }else{
        sethomepage(true)
      }
    }, [location])

  // const location = useLocation()
  const path = location.pathname
  useEffect(() => {
    window.scroll(0, 0)
}, [path]);

  return (

    <>
     
      {homepage && <Header/>}

      <Routes>
        {/* <Route path="/hometwo" element={<Hometwo />} /> */}
        {/* <Route path="/homethree" element={<Homethree />} /> */}
        <Route path="/" element={<Home />} />
        {/* <Route path="/menu" element={<Menu />} /> */}
        {/* <Route path="/menuone" element={<Menuone />} /> */}
        {/* <Route path="/menutwo" element={<Menutwo />} /> */}
        {/* <Route path="/menuthree" element={<Menuthree />} /> */}
        <Route path="/about" element={<About />} />
        <Route path="/reserve" element={<Reserve />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blogs/:id" element={<PostDetail />} />
        {/* <Route path="/swipes" element={<Cards />} /> */}
        <Route path="*" element={<NotFound />} status={404} />

        {/* <Route path="/reservation" element={<Book />} /> */}
      </Routes>

      <Footer />
    </>

  )
}

export default Index